import {useState, useEffect} from "react"

import { connect } from "react-redux";

import PostCardHeader from "../postcard/PostCardHeader";
import PostCardBody from "../postcard/PostCardBody";
import PostCardFooter from "../postcard/PostCardFooter";
import CommentView from "../commentview/CommentView";
import { getUser } from "../../../utils/UserDetails";
import { setModalData } from "../../../actions/dashboradCommon";

const QboardData = (props) => {

  const isFrom = 'qboard';
  const currentUser = getUser();
  const [post, setPost] = useState();
  
  useEffect(() => {
    setPost(props.post)
  }, [props.post])

  const openModal = (qboardObj = {}) => {
    let modalContent= {...props.modalContent} 
    props.setModalData({...modalContent, qboardModal:true, qboardObj:{...qboardObj} })
  }

  return (
    <>
      {(post) ?
      
      <>
        <div className={`position-relative `+ ((post.qboard_parent_id) ? 'mb-2' : '')}>	
          <div className={(post.qboard_parent_id) ? "post-answer" : ""}>
            <div className="card">
              
              <PostCardHeader
                post = {post}
                key = {props.index}
                index = {props.index}
                isFrom={isFrom}
                openModal = {openModal }
              />
              <PostCardBody
                post = {post}
                key = {props.index}
                index = {props.index}
                isFrom={isFrom}
              />
              {/* <PostCardFooter 
                post = {post}
                key = {props.index}
                index = {props.index}
                isFrom={isFrom}
              /> */}

            </div>
          </div>
        </div>

        { (post.qboard_parent_id === 0) && 
            <ul className="list-unstyled margin-bottom-25px">
              <li className="post-answer-queries d-flex">
                <img className="thumbnail"  src={ (props.activeProfile && props.activeProfile.user_profile_image_url) ? props.activeProfile.user_profile_image_url: process.env.PUBLIC_URL + 'images/icons/profile1.jpg' }  onError={(e)=>{e.target.onerror = null; e.target.src = process.env.PUBLIC_URL + '/images/icons/profile1.jpg'}} />
                <input type="text" className="form-control  primary-bg-color" placeholder="Answer the queries"  readOnly onClick={() => (currentUser) &&  openModal({qboard_parent_id:post.qboard_id})}/>
              </li>
            </ul>
          }

          {/* comment view  */}
          {
            (post.common_comments.length && post.showComment) ?
              <div className="post-comment-view mb-1">
                {post.common_comments.map((cmts, postKeys) => {
                    return (
                    <CommentView
                      post= {cmts}
                      key={postKeys}
                      />
                    )
                })
                }
                </div>
              : null
          }

         {/* Answer view */}
         
          {(post.parent.length && post.showAnswer) ?
              <div className="post-answer-view">
                  { post.parent.map((posts, postKeys) => {
                    return (
                        <QboardData
                          post= {posts}
                          key={postKeys}
                          index = {postKeys}
                          setModalData= {props.setModalData}
                      />
                    )
                  })
                }
              </div>
            : null
            }

          
      </>
      : null 
      }
      
    </>
  )
}

const mapStateToProps = state => ({
  darkTheme: state.activetheme.darkTheme,
  activeColor: state.activetheme.activeColor,
  activeProfile: state.profile.activeProfile,
  modalContent: state.dashboardCommon.modalContent
});

export default connect(mapStateToProps, {setModalData})(QboardData);
