import {  
  ACTIVE_KEY_TABLE,
  FILTER_DATA,
  RESET_FILTER_DATA,
  DASH_COMMON_ERROR,
  COMMENT_POST_DATA,
  MODAL_DATA,
  DELETE_POST_DATA,
} from '../actions/types';

const initialState = {
  keyTable: 1,
  commonError: '',
  mainFilter: { 
    searchTerm: '',
    star_filter: 0,
    tab_view: '',
    category_id: '',
  },
  postComment: {
    cmt_id: "",
    key_table: "",
    cmt_description: "",
    comments_parent_id: "",
    qboard_id: "",
    post_id: "",
    qboard_parent_id: "",
    requirements_job_id: "",
    requirements_training_id: "",
    isOpen: false,
  },
  modalContent: {
    qboardModal: false,
    requirementJobModal: false,
    requirementTrainingModal: false,
    qboardObj: {},
    jobObj: {},
    trainingObj: {},
  },
  deletePostData: {
    qboard_parent_id: "",
    requirements_job_id: "",
    requirements_training_id: "",
    comment_id: "",
    deleteModalOpen: false
  },
}
const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case ACTIVE_KEY_TABLE:
      return { ...state, keyTable: payload }

    case FILTER_DATA:
      return { ...state, mainFilter: {...state.mainFilter, ...payload }}

    case DASH_COMMON_ERROR:
      return { ...state, commonError: payload }
    
    case RESET_FILTER_DATA:
      return { ...state, mainFilter: initialState.mainFilter }

    case COMMENT_POST_DATA:
      return { ...state, postComment: payload }

    case MODAL_DATA:
      return {...state, modalContent: payload }

    case DELETE_POST_DATA:
      return {...state, deletePostData: payload}

    default:
      return state;
  }
}

export default reducer;
