import { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalFooter } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {  useHistory } from 'react-router-dom';

import ProfileService from '../../services/profile.service';

toast.configure();

const InterestModal = (props) => {

  const history = useHistory();
  const colorSet1 = [
    'type1-1',
    'type1-2',
    'type1-3',
    'type1-4',
    'type1-5',
    'type1-6',
    'type1-7',
    'type1-8',
    'type1-9',
    'type1-10',
    'type1-11',
    'type1-12'
  ];
  const colorSet2 = [
    'type2-1',
    'type2-2',
    'type2-3',
    'type2-4',
    'type2-5',
    'type2-6',
    'type2-7',
    'type2-8',
    'type2-9',
    'type2-10',
    'type2-11',
    'type2-12'
  ];

  const [interestModalData, setInterestModalData] = useState([]);
  const [showInterestsModal, setShowInterestsModal] = useState({ showModal: false });
  const [interestData, setInterestData] = useState([]);
  const [interests, setInterests] = useState([]);
  const [subCategoriesActive, setSubCategoriesActive] = useState(false);
  const [categoriesActive, setCategoriesActive] = useState(true);
  const [interestChildData, setInterestChildData] = useState([]);
  const [showInterestAlertModal, setShowInterestAlertModal] = useState({ showAlert: false });
  const [missedInterests, setMissedInterests] = useState([]);
  const [comparison, setComparison] = useState([]);
  const [showSpinner, setSpinner] = useState({ status: false, id: '' });

  useEffect(() => {
    showInterestModal('interest')
  },[])

  const closeInterestModal = () => {
    setShowInterestsModal({ ...showInterestsModal, showModal: false });
  }
  const closeInterestAlertModal = () => {
    setShowInterestAlertModal({ ...showInterestAlertModal, showAlert: false });
  }

  const addInterestArray = (e, obj, key) => {
    e.preventDefault();
    let newModal = [...interestModalData];
    newModal[key]['isActive'] = (obj.isActive) ? 0 : 1;
    setInterestModalData(newModal);

    let newInterests = [...interests];
    let id = obj.interests_id;

    if (!newInterests.includes(id)) {
      newInterests.push(id);
    } else {
      newInterests.splice(newInterests.indexOf(id), 1);
    }

    setInterests(newInterests);
  }

  const setModalStatus = () => {
    setSubCategoriesActive(!subCategoriesActive);
    setCategoriesActive(!categoriesActive);
  }

  const addSubCategories = (interestId, obj, key, pKey, parentId) => {
    let newComparison = [...comparison];

    if (!interestChildData.includes(interestId)) {
      interestChildData.push(interestId);
      newComparison.push(interestId + '_' + parentId);
    } else {
      interestChildData.splice(interestChildData.indexOf(interestId), 1);
      newComparison.splice(newComparison.indexOf(interestId + '_' + parentId), 1);
    }

    setComparison(newComparison);
    setInterestChildData(interestChildData);
  }

  
  const createInterest = async () => {
    let uniqueComparison = [];
    let uniqueComparison1 = [];

    uniqueComparison = comparison.map(function (item, pos) {
      return parseInt(item.split('_')[1]);
    })

    uniqueComparison1 = interests.filter(function (item, pos) {
      return !(uniqueComparison.indexOf(item) > -1);
    })

    if (uniqueComparison1.length) {
      // show popup
      let newMissed = [...missedInterests]
      let abc = [];
      interestModalData.map((prop) => {
        if (uniqueComparison1.includes(prop.interests_id)) {
          if (!abc.includes(prop.interests_name)) {
            abc.push(prop.interests_name);
          }
        }
      })
      newMissed = abc.reduce((text, value, i, array) => text + (i < array.length - 1 ? ', ' : ' and ') + value);

      setShowInterestAlertModal({ ...showInterestAlertModal, showAlert: true });
      setMissedInterests(newMissed);
    } else {
      // submit the form
      submitInterestForm();
    }
  }

  const submitInterestForm = async () => {
    let interestInfo = {
      interestId: interestChildData,
      profileId: props.profileDetails.user_profile_id
    }

    let resultData = await ProfileService.createInterest(interestInfo);

    if (resultData.status) {
      let newIntData = { ...interestData };
      newIntData = resultData.profileData.data.interest;

      if(props.fromSignIn) {
        history.push('/');
      }
      setInterestData(newIntData);
      setShowInterestAlertModal({ ...showInterestAlertModal, showAlert: false });
      setShowInterestsModal({ ...showInterestsModal, showModal: false });

      toast.success('Added!');
    } else {
      toast.error('Try again')
    }
  }

  const showInterestModal = async (id) => {
    setSpinner({ ...showSpinner, status: true, id: id });

    let resultData = await ProfileService.getInterests();

    if (resultData.details.status) {
      setInterestModalData(resultData.details.data)
      setSpinner({ ...showSpinner, status: false, id: '' });
      setSubCategoriesActive(false);
      setCategoriesActive(true);

      setShowInterestsModal({ ...showInterestsModal, showModal: true });
    } else {
      setSpinner({ ...showSpinner, status: false, id: '' });
      toast.error('Try again')
    }
  }


  return (
    <>
    <Modal show={showInterestsModal.showModal} onHide={closeInterestModal} backdrop="static" keyboard={false} className="tj-modal int-h" size="lg">
      <div>
        <>
          {(!categoriesActive) ?
            <div className="interest-back">
              <FontAwesomeIcon role="button" icon={faAngleDoubleLeft} size="2x" onClick={setModalStatus} />
            </div> : ''
          }
          <div className="interest-mod-times">
            <FontAwesomeIcon role="button" icon={faTimes} size="" onClick={closeInterestModal} />
          </div>
          {(categoriesActive) ?
            <img className="int-cat-img" src={process.env.PUBLIC_URL + '/images/icons/interest-category.png'} alt="" />
            : <img className="int-cat-img" src={process.env.PUBLIC_URL + '/images/icons/interest-subcategory.png'} alt="" />
          }
          <div className="text-center mt-1">
            <div className="row">
              {(categoriesActive) ? <h4 className="about-me-weight">My Interest Category</h4> : <h4 className="about-me-weight">My Interest Sub Category</h4>}
            </div>
            <div className="row">
              <small>Please select your area of interest</small>
            </div>
          </div>
          {/* interest categories */}
          {(categoriesActive) ?
            <div className="int-card">
              <div className="row categories-height">
                {(interestModalData.length > 0) ? interestModalData.map((interest, iKey) => {
                  interest.isActive = (interests.indexOf(interest.interests_id) > -1) ? 1 : 0;
                  return (
                    <div className="col-md-3 mt-3 mb-2">
                      <button key={iKey} className={`text-truncate btn default interest-btn-category ` + ((interest.isActive) ? (iKey < 12) ? colorSet1[iKey] : colorSet2[0] : '')} onClick={(e) => addInterestArray(e, interest, iKey)}>{interest.interests_name}</button>
                    </div>
                  )
                }) : 'No data found'
                }
              </div>
              {(interestModalData.length) ?
                <div className="row mt-5 mb-5">
                  <div className="col-md-4 mx-auto text-center">
                    <button className="profile-gradient-btn btn btn-secondary btn-block rounded" onClick={setModalStatus} disabled={(interests.length > 0) ? '' : 'true'}>Continue</button>
                  </div>
                </div> : ""
              }
            </div> : ''
          }
          {/* sub categories */}
          {(subCategoriesActive) ?
            <>
              <div className="int-sub-card interest-scroll">
                <div className="row mb-4">
                  {(interestModalData.length) ? interestModalData.map((interest, k) => {
                    if (interests.includes(interest.interests_id)) {
                      return (
                        <div>
                          <div className="row sub-title">
                            <h4 className="sub-int-title">{interest.interests_name}</h4>
                          </div>
                          <div className="row mb-4 int-bg interest-inside-scroll text-center">
                            {interest.children.map((child, childKey) => {
                              child.isSubActive = (interestChildData.indexOf(child.interests_id) > -1) ? 1 : 0;
                              return (
                                <div className="col-md-4 mt-2 mb-1">
                                  <button className={`btn interest-btn default text-truncate ` + ((child.isSubActive) ? (k % 2 === 0) ? colorSet1[childKey] : colorSet2[childKey] : '')} onClick={(e) => addSubCategories(child.interests_id, child, childKey, k, interest.interests_id)}>{child.interests_name}</button>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )
                    }
                  }) : ''
                  }
                </div>
              </div>
              <ModalFooter className="justify-content-center">
                <button type="button" className="btn text-center btn-sm interest-close-btn" onClick={() => createInterest()}>Submit</button>
              </ModalFooter>
            </>
            : ''}
        </>
      </div>
    </Modal>

  {/* interest alert modal */}
  <Modal show={showInterestAlertModal.showAlert} onHide={closeInterestAlertModal} backdrop="static" keyboard={false} centered className="tj-modal">
    <FontAwesomeIcon icon={faTimes} role="button" className="text-muted interest-alert-times" onClick={() => closeInterestAlertModal()} />
    <Modal.Header className="d-flex">
      <p className="flex-grow-1 text-center interest-alert-text">Are you sure you want to continue without choosing the sub category for {missedInterests}?</p>
    </Modal.Header>

    <Modal.Footer className="justify-content-center">
      <button type="button" className="btn btn-primary global-blue mx-2 btn-sm" onClick={submitInterestForm}>Continue</button>
      <button type="button" className="btn btn-outline-danger btn-sm" onClick={closeInterestAlertModal}>No</button>
    </Modal.Footer>
  </Modal>
  </>
  )
}

export default InterestModal