const AdsMenu = () => {
  return (
    <>
      <div className="ads-view">
        <img src={process.env.PUBLIC_URL + '/images/icons/online-3412473_1920.jpg'} />
      </div>
      <div className="ads-view">
        <img src={process.env.PUBLIC_URL + '/images/icons/online-3412498_1920.jpg'} />
      </div>
    </>
  )
}

export default AdsMenu;
