import React, { useMemo } from 'react';

const Tooltip = (props) => {
  const data = useMemo(() => props.api.getDisplayedRowAtIndex(props.rowIndex).data, []);
  return (
    <div className="custom-tooltip">
      <p>
        <span>{data.athlete}</span>
      </p>
    </div>
  );
};

export default Tooltip;