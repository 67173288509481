import React, { useState, useEffect } from "react";

import { GoogleLogin } from "react-google-login";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {  useHistory } from 'react-router-dom';

import AuthService from "../../services/auth.service"
import { getUser, setUserSession } from "../../utils/UserDetails";
import Axios from "../../config/axios";


const GoogleLoginComponent = (props) => {
    const [activeRole, setActiveRole] = useState(null);
    const [googleUserData, setGoogleUserData] = useState({});
    const history = useHistory();

    useEffect(() => {
      setActiveRole(props.activeRole);
      if(props.activeRole) {
        googleRegister();
      }
    },[props.activeRole])

   const  responseGoogleSuccess = (response) => {
     let googleData = {};
        if(response.profileObj) {
          googleData = {
            'first_name': response.profileObj.givenName,
						'last_name':response.profileObj.familyName,
						'email': response.profileObj.email,
						'provider': 'google',
						'provider_id': response.googleId,
						'token': response.tokenId,
						'refresh_token': response.tokenObj.access_token,
						'expires_in': response.tokenObj.expires_in,
          }
        }

        if(googleData && googleData.email) {
          setGoogleUserData(googleData);
          register(googleData)
        } else { 
          toast.error("Please Try Agian!")
        }
    }

    const register = async(googleData) => {
        let emailData = {
          'email': googleData.email,
        }
        let userData = await AuthService.checkUserExist(emailData);
        if (userData.status === true ) {
          let resultData = await AuthService.googleLogin(googleData);

          if(resultData?.status && resultData.status === true) {
            Axios.interceptors.request.use(function (config) {
              config.headers['Authorization'] = (resultData?.data?.api_token) ?  `Bearer ${resultData.data.api_token}` : '';
              return config;
            });
            setUserSession(resultData.data);
			
            if(getUser()) {
              setTimeout(() => {
               history.push("/home")
              }, 500)
            }
          } else {
            toast.error("Please Try Agian!")
          }
        } else {
          googleRegister(googleData);
        }

      }

     const googleRegister = async(googleData) => {

       googleData.role_type = 'TRAINER';

      let resultData = await AuthService.googleRegister(googleData);

      if(resultData?.status && resultData.status === true) {
          setUserSession(resultData.data);

          Axios.interceptors.request.use(function (config) {
            config.headers['Authorization'] = (resultData?.data?.api_token) ?  `Bearer ${resultData.data.api_token}` : '';
            return config;
          });


        if(resultData.data && resultData.data.is_new_user) {
          history.push("/primary-profile-view");
        } else {
          history.push("/");
        }
      } else {
        toast.error("Please Try Agian!")
      }
    } 

    // Error Handler
    const responseGoogleError = (response) => {
      if(response && (response.error !=="idpiframe_initialization_failed"))
        toast.error("Please Try Agian!");
      };
      

    return (
      <GoogleLogin
        clientId={process.env.REACT_APP_CLIENT_ID}
        render={renderProps => (
            <Link className="btn btn-block btn social-btn-google" to="#" onClick={renderProps.onClick} disabled={renderProps.disabled}>
            <img src={process.env.PUBLIC_URL + '/images/icons/icon-google-search.svg'} />
              Continue with Google
          </Link>
          )}

        buttonText="Sign In with Google"
        onSuccess={responseGoogleSuccess}
        onFailure={responseGoogleError}
        cookiePolicy={"single_host_origin"}
        prompt={"select_account"}
      />
    )
}

export default GoogleLoginComponent
