import React, {useState, useEffect, Suspense} from 'react'

import { connect } from "react-redux";

import Filter from './Filter';
import Qboard from './qboard/Qboard';
import Requirements from './requirements/Requirements';
import { getUser } from '../../utils/UserDetails';
import ProfileService from "../../services/profile.service";
import { filterData, setModalData, setActiveKeyTable, resetFilterData } from '../../actions/dashboradCommon';
import { QboardMenus, RequirementMenus } from '../../constants/Menu';
import CommonService from '../../services/common.service';

const DeleteModal = React.lazy(() => import('./DeleteModal'));
const CommentModal = React.lazy(() => import('./CommentModal'));
const JobModal = React.lazy(() => import('./requirements/job/JobModal'));
const TrainingModal = React.lazy(() => import('./requirements/training/TrainingModal'));

const FeedTabContent = (props) => {

  const currentUser = getUser();
  const [activeTab, setActiveTab] = useState(null);
  const [tabHeaders, setTabHeaders] = useState([]);
  const [postPlaceholder, setPostPlaceholder] = useState(null);

  useEffect(() => {

    let tabs = QboardMenus;

    if(props.activeParentTab && props.activeParentTab.toLowerCase() === 'requirements') {
      tabs = RequirementMenus;
    } 
    setTabHeaders(tabs)
    setActiveTab(tabs[0].label)

  }, [props.activeParentTab])

  useEffect(() => {
    
    if(props.activeParentTab && props.activeParentTab.toLowerCase() === 'qboard') {
      let tabFilter = {...props.mainFilter}
      tabFilter.tab_view = activeTab;
      props.filterData(tabFilter)
      setPostPlaceholder('Post your queries ...')
      props.setActiveKeyTable(1);
    } else {
      if(activeTab === 'requirements-jobs') {
        setPostPlaceholder('Post your job requirements ...')
        props.setActiveKeyTable(3);
      } else {
        setPostPlaceholder('Post your training requirements ...')
        props.setActiveKeyTable(2);
      }
      props.resetFilterData();
    }
    CommonService.scrollToTop();
    
  }, [activeTab])



  const openModal = () => {
    let modalData = {...props.modalContent};
    if(props.activeParentTab.toLowerCase() === 'qboard') {
      props.setModalData({...modalData, qboardModal:true, qboardObj:{} })
    } else {
        if(activeTab === 'requirements-jobs') {
          props.setModalData({...modalData, requirementJobModal:true, jobObj:{} })
        } else {
          props.setModalData({...modalData, requirementTrainingModal:true, trainingObj:{} })
        }
    }
  }

  return (
    <>
      <div className="sticky-center">
      <div className={`post-details-headers ${props.activeColor}`} >
        <div className="row">
          <ul className="list-inline list-style-type col-sm-8">
              {tabHeaders.map((tab) => {
                  return (
                    <li key={tab.label} className={(activeTab === tab.label) ? "font-weight-bold" : ""} onClick={() => (currentUser || (props.activeParentTab === 'Requirements')) ? setActiveTab(tab.label) : null}>
                      {tab.name}
                    </li>
                  )
                })
              }
            </ul>
          <Filter
            activeParentTab = {props.activeParentTab}
          />
        </div>
      </div>
      <div className="post-query-input d-flex">
          <img className="profile-icon rounded-circle " src={(props.activeProfile && props.activeProfile.user_profile_image_url) ? props.activeProfile.user_profile_image_url : ProfileService.defaultProfilePic()}
            alt="profile-logo"
            onError={(e)=>{e.target.onerror = null; e.target.src = process.env.PUBLIC_URL + '/images/icons/profile1.jpg'}}
          />
          <input type="text" placeholder={postPlaceholder} className="form-control primary-bg-color"  onClick={ (currentUser) &&  openModal} readOnly />
      </div>
      </div>
      <div className="post-details-content">
        
        <div className="post-queries-data">
          {
           ((props.activeParentTab && props.activeParentTab.toLowerCase() === 'qboard')) &&
             <Qboard  />
          }
          {
           ((props.activeParentTab &&props.activeParentTab.toLowerCase() === 'requirements')) &&
            <Suspense fallback={""}>
              <Requirements 
                activeTab = {activeTab}
              />
              </Suspense>
          }
        </div>
      </div>

      {/* modal view */}
      <Suspense fallback={""}>
        <CommentModal />
      </Suspense>

      <Suspense fallback={""}>
        <DeleteModal />
      </Suspense>

      <Suspense fallback={""}>
        <TrainingModal />
      </Suspense>

      <Suspense fallback={""}>
        <JobModal />
      </Suspense>
    </>
  )
}
const mapStateToProps = state => ({
  activeProfile: state.profile.activeProfile,
  activeColor: state.activetheme.activeColor,
  mainFilter:  state.dashboardCommon.mainFilter,
  modalContent: state.dashboardCommon.modalContent,

});

export default connect(mapStateToProps, {filterData, setActiveKeyTable, resetFilterData, setModalData})(FeedTabContent);
