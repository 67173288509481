import {  SET_CATEGORIES, SET_CURRENCIES, SET_TIME_PERIODS, SET_INDUSTRY, BROADCASTING_DATA, FEED_LEFT_MENU_COLLAPSED  } from '../actions/types';

const initialState = {
	categories: [],
  currencies: [],
  timePeriods: [],
  industry: [],
  broadcastingEcho: null,
  feedLeftMenuIsExpand: null,
}
const reducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case SET_CATEGORIES:
       return {
          ...state, 
          categories:payload
        }
      case SET_CURRENCIES:
        return {
            ...state, 
            currencies:payload
          }
      case SET_TIME_PERIODS:
        return {
            ...state, 
            timePeriods:payload
          }
      case SET_INDUSTRY:
        return {
            ...state, 
            industry: payload
          }
      case BROADCASTING_DATA:
        return {
            ...state, 
            broadcastingEcho: payload
          }
      case FEED_LEFT_MENU_COLLAPSED:
         return {
          ...state, 
          feedLeftMenuIsExpand: payload
         }
      default:
        return state;
    }
  }
  
  export default reducer;