import { useEffect, useState } from "react";

import Loader from "react-loader-spinner";
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { toast } from 'react-toastify';
import renderHTML from 'react-render-html';
import { RatingView } from 'react-simple-star-rating';
import { connect } from "react-redux";

import CommanSpinner from '../../spinner/CommanSpinner';
import ProfileService from "../../../services/profile.service";
import { addUserProfile, setActiveProfile } from '../../../actions/profile';
import { getUser } from "../../../utils/UserDetails";
import { useHistory } from "react-router-dom";

const Preview = (props) => {

	let profileId = props.match.params.id;
	const currentUser = getUser();
	const history = useHistory();

	const [previewData, setPreviewData] = useState({});
	const [profileDetails, setProfileDetails] = useState({});
	const [awardsData, setAwardsData] = useState([]);
	const [qualificationData, setQualificationData] = useState([]);
	const [jobExperienceData, setJobExperienceData] = useState([]);
	const [trainingExperienceData, setTrainingExperienceData] = useState([]);
	const [certificateData, setCertificateData] = useState([]);
	const [skillsData, setSkillsData] = useState([]);
	const [moreSkills, setMoreSkills] = useState([]);
	const [showEndorseDetails, setShowEndorseModal] = useState({ showModal: false });
	const [showSpinner, setSpinner] = useState({ status: false, id: '' });
	const [endorseData, setEndorseData] = useState([]);
	const [interestData, setInterestData] = useState([]);
	const [alertModal, setAlertModal] = useState({ status: false });

	useEffect(() => {
		getPreview(profileId);
	}, [])

	const getPreview = async (profileId) => {
		let profileData = {
			profileId: profileId
		}
		let resultData = await ProfileService.getProfileViewDetails(profileData);
		let data = resultData.details;

		let newPreviewData = { ...previewData }
		newPreviewData = data;
		setPreviewData(newPreviewData);
		setProfileDetails(newPreviewData);
		setQualificationData(newPreviewData.qualification)
		setJobExperienceData(newPreviewData.job_experience);
		setAwardsData(newPreviewData.awards);
		setTrainingExperienceData(newPreviewData.experience);
		setSkillsData(newPreviewData.skill);
		setCertificateData(newPreviewData.certificates);
		setMoreSkills(newPreviewData.more_skills);
		setInterestData(newPreviewData.interest);
	}

	const setEndorsement = async (index, skillId) => {
		if (currentUser) {
			let endorseData = {
				user_profile_id: previewData.user_profile_id,
				userskill_id: skillId
			}
			let resultData = await ProfileService.endorse(endorseData);

			if (resultData.status) {
				let newSkillData = [...skillsData];
				newSkillData[index]['count'] = resultData.count;
				newSkillData[index]['btnText'] = resultData.btnText;

				setSkillsData(newSkillData);

				toast.success('Endorsed!')
			} else {
				toast.error('Try again')
			}
		} else {
			setAlertModal({ ...alertModal, status: true });
		}
	}

	const showEndorseModal = async (id, skillId) => {
		setSpinner({ ...showSpinner, status: true, id: id });

		let endorseData = {
			user_profile_id: profileDetails.user_profile_id,
			userskill_id: skillId
		}
		let resultData = await ProfileService.getEndorseDetails(endorseData);

		if (resultData.status) {
			setEndorseData(resultData.details);
			setSpinner({ ...showSpinner, status: false, id: '' });
			setShowEndorseModal({ ...showEndorseDetails, showModal: true });
		} else {
			toast.error('Try again');
			setSpinner({ ...showSpinner, status: false, id: '' });
		}
	}

	const closeEndorseModal = () => {
		setShowEndorseModal({ ...showEndorseDetails, showModal: false });
	}

	const closeAlertModal = () => {
		setAlertModal({ ...alertModal, status: false });
		history.push('/');
	}

	return (
		<>
			{(previewData.user_profile_id) ?
				<div className={`profile-view-container ` + ((props.darkTheme) ? 'is-dark-theme' : 'primary-bg-color')}>
					<div className="container">
						<div className="row">
							<div className="cover profile-deatils-view">
								<div className="bg-img m-2">
									<div className="col-md-2 text-center position-relative about-me shadow">
										<img src={process.env.REACT_APP_BACKEND_BASEURL + '/storage' + previewData.user_profile_image_url} className="profile-pic shadow" alt="" onError={(e) => { e.target.onerror = null; e.target.src = process.env.PUBLIC_URL + '/images/icons/profile1.jpg' }} />
									</div>
									<div className="col-md-1"></div>
									<div className="pull-right">
										<img src={(process.env.REACT_APP_BACKEND_BASEURL + '/storage' + previewData.user_profile_bg_img_url) ? process.env.REACT_APP_BACKEND_BASEURL + '/storage' + previewData.user_profile_bg_img_url : process.env.PUBLIC_URL + '/images/profile-pic/ram-cover.jpg'} className="p-2 profile-cover-img-view" alt="" /*style={{ marginRight: "-78px" }}*/ onError={(e) => { e.target.onerror = null; e.target.src = process.env.PUBLIC_URL + '/images/icons/profile-background.jpg' }} />
									</div>

									<div className="profile-name mb-3">
										<h4 className={`font-weight-bold text-capitalize ${props.activeColor}-font`}>{previewData.user.first_name} {previewData.user.last_name}</h4>
										<p className="text-capitalize">{previewData.user_profile_name}</p>
										<div>
											<RatingView ratingValue={0} stars={5} size={15} />
										</div>
										<div className="d-flex flex-row justify-content-end" style={{ marginTop: "-7px" }}>
											<p>{previewData.user_profile_locality}</p>
											<p className="mx-2">{previewData.user_profile_city}</p>
										</div>
										<div className="d-flex flex-row justify-content-end">
											<p>{previewData.user_profile_state}</p>
											<p className="mx-2">{previewData.user_profile_country}</p>
										</div>
										<div className="d-flex flex-column justify-content-start">
											<p>{previewData.user_profile_email}</p>
											<p>{previewData.user_profile_contact_number}</p>
										</div>
									</div>
								</div>
							</div>

							<div className="col-md-12 p-0">
								{(previewData) ?
									<div className="tab-content border-left border-bottom border-right pb-4 pr-4 mb-4">
										<div className="tab-pane pl-4 active" id="about" role="tabpanel" aria-labelledby="about-tab">
											{/* trainer profile description */}
											{(profileDetails.user_description_enabled) ?
												<div className="profile-deatils-view">
													<div className="text-infos clearfix">
														<p className={`pull-left lead font-weight-bold ${props.activeColor}-font`}>Brief introduction</p>
													</div>
													{(profileDetails.user_profile_description && profileDetails.user_profile_description !== null && profileDetails.user_profile_description !== '') ?
														<p className="py-1 m-1">{renderHTML(profileDetails.user_profile_description)}</p> : ''
													}
												</div> : ''
											}

											{(profileDetails.user_experience_enabled) ?
												<div className="profile-deatils-view">
													<div className="text-infos clearfix">
														<p className={`pull-left lead font-weight-bold ${props.activeColor}-font`}>Training experience</p>
													</div>
													{(trainingExperienceData && trainingExperienceData.length) ? trainingExperienceData.map((texp, key) => {
														if (texp.userexperience_show === 1)
															return (
																<div className="">
																	<div className="p-1">
																		<div className="d-flex flex-row">
																			<div className="col-md-4 m-1">
																				{(texp.userexperience_designation !== null) ?
																					<p className="py-1 m-2 tab-sub-heading">{texp.userexperience_designation}</p>
																					: ''
																				}
																			</div>
																			<div className="col-md-2 m-1">
																				{
																					(texp.userexperience_from !== null) ?
																						<p className="py-1 m-2 sub-tab-date"> {texp.userexperience_from} </p> : ''
																				}
																			</div>
																			<div className="col-md-2 m-1">
																				{
																					(texp.userexperience_to !== null) ?
																						<p className="py-1 m-2 sub-tab-date"> {texp.userexperience_to} </p>
																						: ''
																				}
																			</div>
																		</div>
																	</div>
																	<div className="card-body p-1">
																		<div className="row">
																			<div className="col-md-12">
																				{
																					(texp.user_experience_description !== null) ?
																						<p className="py-1 m-2">{renderHTML(texp.user_experience_description)}</p> : ''
																				}
																			</div>
																		</div>
																	</div>
																</div>)
													}) : 'No details found'}
												</div> : ''
											}

											{/* job experience details */}
											{(profileDetails.user_job_experience_enabled) ?
												<div className="profile-deatils-view">
													<div className="text-infos clearfix">
														<p className={`pull-left lead font-weight-bold ${props.activeColor}-font`}>Job experience</p>
													</div>

													{(jobExperienceData && jobExperienceData.length) ? jobExperienceData.map((jobexperience, key) => {
														if (jobexperience.user_job_experience_show === 1)
															return (
																<div className="">
																	<div className="p-1">
																		<div className="d-flex flex-row">
																			<div className="col-md-2 m-1">
																				{
																					(jobexperience.user_job_experience_company_name !== null) ?
																						<p className="py-1 m-2 tab-sub-heading">{jobexperience.user_job_experience_company_name}</p> : ''
																				}
																			</div>
																			<div className="col-md-2 m-1">
																				{(jobexperience.user_job_experience_job_name !== null) ?
																					<p className="py-1 m-2 ">{jobexperience.user_job_experience_job_name}</p> : ''
																				}
																			</div>
																			<div className="col-md-2 m-1">
																				{
																					(jobexperience.user_job_experience_from !== null) ?
																						<p className="py-1 m-2 sub-tab-date"> {jobexperience.user_job_experience_from} </p>
																						: ''
																				}
																			</div>
																			<div className="col-md-2 m-1">
																				{
																					(jobexperience.user_job_experience_to !== null) ?
																						<p className="py-1 m-2 sub-tab-date"> {jobexperience.user_job_experience_to} </p> : ''
																				}
																			</div>
																		</div>
																	</div>
																	<div className="card-body p-1">
																		<div className="row">
																			<div className="col-md-12">
																				{
																					(jobexperience.user_job_experience_description !== null) ?
																						<p className="py-1 m-2">{renderHTML(jobexperience.user_job_experience_description)}</p>
																						: ''
																				}
																			</div>
																		</div>
																	</div>
																</div>
															)
													}) : 'No job experience details found'}
												</div> : ''
											}

											{/* Education Details */}
											{(profileDetails.user_qualification_enabled) ?
												<div className="profile-deatils-view">
													<div className="text-infos clearfix">
														<p className={`pull-left lead font-weight-bold ${props.activeColor}-font`}>Education</p>
													</div>

													{(qualificationData && qualificationData.length) ? qualificationData.map((qualification, qualificationKey) => {
														if (qualification.userqualification__show === 1)
															return (
																<div className="">
																	<div className="p-1">
																		<div className="d-flex flex-row">
																			<div className="col-md-2 m-1">
																				{
																					(qualification.userqualification_institution_name !== null) ?
																						<p className="py-1 m-2 tab-sub-heading">{qualification.userqualification_institution_name}</p> : ''
																				}
																			</div>
																			<div className="col-md-2 m-1">
																				{
																					(qualification.userqualification_name !== null) ?
																						<p className="py-1 m-2">{qualification.userqualification_name}</p> : ''
																				}
																			</div>
																			<div className="col-md-2 m-1">
																				{
																					(qualification.userqualification_year_start !== null) ?
																						<p className="py-1 m-2 sub-tab-date">{qualification.userqualification_year_start}</p> : ''
																				}
																			</div>
																			<div className="col-md-2 m-1">
																				{
																					(qualification.userqualification_year_end !== null) ?
																						<p className="py-1 m-2 sub-tab-date">{qualification.userqualification_year_end}</p> : ''
																				}
																			</div>
																		</div>
																	</div>
																	<div className="card-body p-1">
																		<div className="row">
																			<div className="col-md-12">
																				{
																					(qualification.userqualification_subject !== null) ?
																						<p className="py-1 m-2">{renderHTML(qualification.userqualification_subject)}</p> : ''
																				}
																			</div>
																		</div>
																	</div>
																</div>
															)
													}) :
														'No details found'
													}
												</div> : ''
											}

											{/* Certification Details */}
											{(profileDetails.user_certifications_enabled) ?
												<div className="profile-deatils-view">
													<div className="text-infos clearfix">
														<p className={`pull-left lead font-weight-bold ${props.activeColor}-font`}>Certifications</p>
													</div>

													{(certificateData && certificateData.length) ? certificateData.map((certificate, key) => {
														if (certificate.user_certificates_show === 1)
															return (
																<div className="">
																	<div className="p-1">
																		<div className="d-flex flex-row">
																			<div className="col-md-4 m-1">
																				{
																					(certificate.user_certificates_name !== null) ?
																						<p className="py-1 m-2 tab-sub-heading">{certificate.user_certificates_name}</p>
																						: ''

																				}
																			</div>
																			<div className="col-md-4 m-1">
																				{
																					(certificate.user_certificates_institution_name !== null) ?

																						<p className="py-1 m-2">{certificate.user_certificates_institution_name}</p>
																						: ''
																				}
																			</div>
																		</div>
																	</div>
																	<div className="card-body p-1">
																		<div className="row">
																			<div className="col-md-12">
																				{
																					(certificate.user_certificates_description !== null) ?

																						<p className="py-1 m-2">{renderHTML(certificate.user_certificates_description)}</p>
																						: ''
																				}
																			</div>
																		</div>
																	</div>
																</div>
															)
													}) :
														'No details found'
													}
												</div> : ''
											}

											{/* awards section */}
											{(profileDetails.user_awards_enabled) ?
												<div className="profile-deatils-view">
													<div className="text-infos clearfix">
														<p className={`pull-left lead font-weight-bold ${props.activeColor}-font`}>Awards and recognitions</p>
													</div>

													{(awardsData && awardsData.length) ? awardsData.map((awards, key) => {
														if (awards.user_awards_show === 1)
															return (
																<div className="">
																	<div className="p-1">
																		<div className="d-flex flex-row">
																			<div className="col-md-8">
																				{(awards.user_awards_title !== null) ?
																					<p className="py-1 m-2 tab-sub-heading">{awards.user_awards_title}</p> : ''
																				}
																			</div>
																		</div>
																	</div>
																	<div className="card-body p-1">
																		<div className="row">
																			<div className="col-md-12">
																				{
																					(awards.user_awards_description !== null) ?
																						<p className="m-2">{renderHTML(awards.user_awards_description)}</p> : ''
																				}
																			</div>
																		</div>
																	</div>
																</div>)
													}) : 'No awards found'}
												</div> : ''
											}

											{/* Skills section */}
											{(profileDetails.user_skills_enabled) ?
												<div className="profile-deatils-view">
													<div className="text-infos clearfix">
														<p className={`pull-left lead font-weight-bold ${props.activeColor}-font`}>Skills &amp; Endorsements</p>
													</div>

													<div>
														{(skillsData && skillsData.length) ? skillsData.map((skill, skey) => (
															<div className="col-md-6 row">
																<div className="col-3">
																	{
																		(skill.userskill_name !== null) ? <p className="m-1">{skill.userskill_name}</p> : ''
																	}
																</div>

																<div className="col-md-2">
																	<p className="endorse-count m-1" onClick={() => showEndorseModal(skey, skill.userskill_id)}>{(showSpinner.status && showSpinner.id === skey) ? <CommanSpinner /> : skill.count}</p>
																</div>
															</div>
														)) : 'No skills found'}
													</div>
												</div> : ''
											}

											{/* more skills */}
											<div className="profile-deatils-view">
												<p className={`lead font-weight-bold text-infos ${props.activeColor}-font`}>More about me</p>
												<Tabs defaultActiveKey="skills" className="mb-3">
													<Tab eventKey="skills" title="Skills">
														<div>
															{(skillsData && skillsData.length) ? skillsData.map((mskills) => (
																<div className="col-md-6 row">
																	<div className="col-3 text-truncate">
																		<p className="m-1">{mskills.userskill_name}</p>
																	</div>
																	<div className="col-3">
																		<label className="m-1">{(mskills.userskill_expertize) ? mskills.userskill_expertize + '/10' : 'Not rated'}</label>
																	</div>
																</div>

															)) : 'No data found'
															}
														</div>
													</Tab>
													<Tab eventKey="interests" title="Interests">
														{(interestData && interestData.length) ? interestData.map((interest, intKey) => (
															<div className="row">
																<div className="col-md-3">
																	<p className="py-1 m-2">{interestData[intKey].interests.interests_name}</p>
																</div>
															</div>
														)) : "No data found"}
													</Tab>
												</Tabs>
											</div>
										</div>
									</div> : ''
								}
							</div>
						</div>
					</div>
				</div> : <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} className="loader" />
			}
			{/* Endorsements list modal */}
			<Modal show={showEndorseDetails.showModal} onHide={closeEndorseModal} backdrop="static" keyboard={false} className="tj-modal">
				<Modal.Header closeButton>
					<Modal.Title>Endorsements</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<>
						{(endorseData && endorseData.length > 0) ? endorseData.map((endorse) => {
							return (
								<div className="row">
									<div className="col-md-4 text-center">
										<h6>{` ${endorse.user.first_name}  ${endorse.user.last_name}`}</h6>
									</div>
									<div className="col-md-8 text-justify">
										{(endorse.profile_description.user_profile_description) ? renderHTML(endorse.profile_description.user_profile_description) : 'No description found'}
									</div>
								</div>
							)
						})
							: 'No endorsements'
						}
					</>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="success" onClick={closeEndorseModal}>Ok</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

const mapStateToProps = state => ({
	activeProfile: state.profile.activeProfile,
	activeColor: state.activetheme.activeColor,
	darkTheme: state.activetheme.darkTheme,
});

export default connect(mapStateToProps, { setActiveProfile, addUserProfile })(Preview);