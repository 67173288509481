import ReactPlayer from "react-player"

const Player = (props) => {
    return (
      <ReactPlayer url={props.fileUrl}
        config={{ 
            file: { attributes: { controlsList: 'nodownload' } },
            youtube: {
                playerVars: { showinfo: 1 }
            }, 
        }}
        onContextMenu={e => e.preventDefault()}
        className='react-player' 
        volume={1}
        muted={true} 
        controls={true} 
        width="100%"
        height="500px"
      />
    )
}

export default Player
