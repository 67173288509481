import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';

import { deleteRecord, setActiveProfile } from "../../../actions/profile";

const Trash = (props) => {
  const dispatch = useDispatch();
  const [deleteData, setDeleteData] = useState({ id: '', source: '', index: '' });
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleClick = () => {
    let newActiveProfile = { ...props.activeProfile };
    let deleteInfo = {
      id: deleteData.id,
      source: deleteData.source
    }

    if (deleteData.id !== null) {
      props.deleteRecord(deleteInfo);
    }

    switch (deleteData.source) {
      case 'experience':
        props.active.splice(deleteData.index, 1)
        newActiveProfile['experience'] = props.active;
        break;
      case 'job':
        props.active.splice(deleteData.index, 1)
        newActiveProfile['job_experience'] = props.active;
        break;
      case 'qualification':
        props.active.splice(deleteData.index, 1)
        newActiveProfile['qualification'] = props.active;
        break;
      case 'certificate':
        props.active.splice(deleteData.index, 1)
        newActiveProfile['certificates'] = props.active;
        break;
      case 'award':
        props.active.splice(deleteData.index, 1)
        newActiveProfile['awards'] = props.active;
        break;
      case 'skills':
        props.active.splice(deleteData.index, 1)
        newActiveProfile['skills'] = props.active;
        break;
      case 'interest':
        props.active.splice(deleteData.index, 1)
        newActiveProfile['interest'] = props.active;
        break;
      case 'profile':
        props.active.splice(deleteData.index, 1);
        newActiveProfile['profiles'] = props.active;
        break;
      default:
        break;
    }

    dispatch(props.setActiveProfile(newActiveProfile));
    setDeleteData({ ...deleteData, id: '', source: '', index: '' });
    setShowDeleteModal(false);
  }

  const handleDelete = (id, source, index) => {
    setDeleteData({ ...deleteData, id: id, source: source, index: index });
    setShowDeleteModal(true);
  }

  const closeDeleteModal = () => {
    setShowDeleteModal({ ...showDeleteModal, showDelete: false });
  }

  return (
    <>
      <FontAwesomeIcon icon={faTrash} onClick={(e) => handleDelete(props.id, props.source, props.index)} />

      {/* delete modal */}
      <Modal show={showDeleteModal} onHide={closeDeleteModal} backdrop="static" keyboard={false} centered className="tj-modal delete-modal">
        <Modal.Header closeButton className="d-flex">
          <Modal.Title className="flex-grow-1 text-center">Are you sure want to delete ?</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <button type="button" className="btn btn-success mx-2" onClick={handleClick}>Yes</button>
          <button type="button" className="btn btn-outline-danger" onClick={closeDeleteModal}>No</button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  activeProfile: state.profile.activeProfile,
});

export default connect(mapStateToProps, {
  setActiveProfile,
  deleteRecord
})(Trash);