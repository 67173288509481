import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';

import CurrencyFormat from 'react-currency-format';
import renderHTML from 'react-render-html';

const CurrencyFormatter = forwardRef((props, ref) => {
  console.log(props)
  const refInput = useRef(null);
  const [value, setValue] = useState(0);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },

      myCustomFunction() {
        return {
          rowIndex: props.rowIndex,
          colId: props.column.getId()
        };
      }
    };
  });

  const handleOnChange = (params) => {
    setValue(params.value)
  }

  return (
    <CurrencyFormat ref={refInput} value={value} thousandSeparator={true} prefix={(props.format !== null) ? renderHTML(props.format) : '$'} onValueChange={(values) => handleOnChange(values)} />
  )
});

const mapStateToProps = (state) => ({
  currencies: state.training.currencies
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(CurrencyFormatter);