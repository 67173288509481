import Axios from '../config/axios.js';

class RequirementService {

	getJobPosts = (filterData) => {
		return Axios.post('/training-job-view', filterData).then((response) => {
			const { data } = response;
    	return data;
		}).catch((error) => {
			console.log(error);
		});
	}

	getrequirementCategory = async () => {

		let requirementCategories = [{
			value: "",
			label: 'Select'
		}];

		await Axios.post('/getjobcategory', []).then((response) => {
			response.data.forEach((reqCategory) => {
				var obj = {};
				obj['value'] = reqCategory.requirements_trainer_job_category_id;
				obj['label'] = reqCategory.requirements_trainer_job_category_name;
				requirementCategories.push(obj);
			})
		});

		return requirementCategories;
	}

	getrequirementSpecialization = async () => {

		let requirementSpecialization = [{
			value: "",
			label: 'Select'
		}];

		Axios.post('/get-specialization', []).then((response) => {
			response.data.forEach((reqSpecialization) => {
				var obj = {};
				obj['value'] = reqSpecialization.job_specialization_id;
				obj['label'] = reqSpecialization.job_specialization_name;
				requirementSpecialization.push(obj);
			})
		});

		return requirementSpecialization;
	}

	getrequirementIndustry = async () => {

		let requirementIndustry = [{
			value: "",
			label: 'Select'
		}];

		Axios.post('/get-requirmentinstry', []).then((response) => {
			response.data.forEach((data) => {
				var obj = {};
				obj['value'] = data.industry_id;
				obj['label'] = data.industry_name;
				requirementIndustry.push(obj);
			})
		});

		return requirementIndustry;
	}

	postJobData = async (data) => {
		let returnData = [];

		await Axios.post("/post-job-requirement", data)
			.then(res => {
				returnData = res.data;
			})

		return returnData;
	}

	postJobApply = async (data) => {
		let returnData = [];
		let form_data = new FormData();

		for ( var key in data ) {
			form_data.append(key, data[key]);
		}

		await Axios.post("/apply-job-requirement", form_data)
			.then(res => {
				returnData = res.data;
			})

		return returnData;
	}

	postJobDelete = async (jobDeleteId) => {
		let returnData = [];

		await Axios.get("delete-trainer-job/" + jobDeleteId, [])
			.then(res => {
				returnData = res.data;
			})

		return returnData;
	}

	getTrainingPosts = async (filterData) => {
		return Axios.post('/training-requirement-view', filterData).then((response) => {
			const { data } = response;
    	return data;
		}).catch((error) => {
			console.log(error);
		});

	}

	postTrainingData = async (data) => {
		let posts = [];

		await Axios.post('/post-trainer-requirement', data).then((response) => {
			posts = response.data;
		})

		return posts;
	}

	jobApplicationDetails = async (data) => {
		let jobApplyDetails = [];

		await Axios.get('/get-job-application', {
			params: data,
		}).then((response) => {
			jobApplyDetails = response.data;
		})

		return jobApplyDetails;

	}

	getAppliedResume = async (jobAppliedId) => {
		Axios({
			url: '/applied-resume-download',
			method: 'GET',
			responseType: 'blob', // important
			params: {
				job_applied_id: jobAppliedId,
			},
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'jobdata.pdf'); //or any other extension
			document.body.appendChild(link);
			link.click();
		});

	}

	getRequiredJobData = () => {
   return	Axios.get("get-required-job-data")
      .then(function (response) {
        const { data } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
	}

	postTrainingDelete = (deleteId) => {
		return	Axios.get("remove-trainer-requirement/" + deleteId)
			 .then(function (response) {
				 const { data } = response;
				 return data;
			 }).catch((error) => {
				 console.log(error);
			 })
 
	 }

	postTrainingApply = (form_data) => {
		return Axios.post("confirm-trainer-requirement" , form_data)
			 .then(function (response) {
				 const { data } = response;
				 return data;
			 }).catch((error) => {
				 console.log(error);
			 })
	}

	trainingApplicationDetails =  (data) => {
		return  Axios.get('/get-training-application', {
			params: data,
		}).then(function (response) {
			const { data } = response;
			return data;
		}).catch((error) => {
			console.log(error);
		})
	}

	
}

export default new RequirementService();