import axiosInstance from "../config/axios";
import AuthService from "../services/auth.service";
import { setUserSession } from "../utils/UserDetails";
import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  REGISTER_SUCCESS,
  REGISTER_ERROR

} from './types';


export const loginUser = (loginInput, setSubmitting, setFieldValue) => async(dispatch) => {

  try {
    const resultData = await AuthService.userLogin(loginInput);
    
      if (resultData?.status && resultData.status === true) {
         let userData = resultData?.data ? resultData.data : "";
         setUserSession(userData);
        
        if (userData?.api_token && userData.api_token) {
          axiosInstance.interceptors.request.use(function (config) {
            config.headers.Authorization =  `Bearer ${userData.api_token}`;
            return config;
          });
        }

         dispatch(setLoginSuccessState({'user_role': userData.user_role}));
      } else {
        dispatch(setLoginErrorState(resultData.message));
        setFieldValue('password', '', false)
        setSubmitting(false);
      }
  } catch (err) {
    dispatch(setLoginErrorState('Please Try Agian'));
    setFieldValue('password', '', false)
    setSubmitting(false);
  }
}

export const userRegister = (formData, resetForm, setSubmitting, setFieldValue) => async(dispatch) => {

  let errorStatus = "Please Try Agian";
  
try {
    const resultData = await AuthService.registerLearningHub(formData);

    if(resultData?.status && resultData.status === true) {
      dispatch(setRegisterSuccessState('Success'));
      resetForm();
    } else { 
      errorStatus = (resultData?.error) ? resultData.error : errorStatus; 
      dispatch(setRegisterErrorState(errorStatus));
      setFieldValue('password', '', false)
      setSubmitting(false);
    } 
  } catch (err) {
    dispatch(setRegisterErrorState(errorStatus));
    setFieldValue('password', '', false)
    setSubmitting(false);
  }
}

export const userLogout = (history) => async() => {
  try {
    const resultData = await AuthService.userLogout();
    if (resultData?.status && resultData.status === true) {
      history.push("/")
    }
  } catch (err) {
    console.log(err)
  }
}


export const setLoginSuccessState = (data) => {
  return {
      type: LOGIN_SUCCESS,
      payload: data,
  };
};

const setLoginErrorState = (data) => {
  return {
      type: LOGIN_ERROR,
      payload: data,
  };
};

const setRegisterSuccessState = (data) => {
  return {
      type: REGISTER_SUCCESS,
      payload: data,
  };
};

const setRegisterErrorState = (data) => {
  return {
      type: REGISTER_ERROR,
      payload: data,
  };
};