const initialState = {
	activeColor:  	(localStorage.getItem('colorTheme')) ? localStorage.getItem('colorTheme') : "global-blue",
	darkTheme: 0,
};
const colorReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ACTIVE_COLOR": {
			return {
				...state,
				activeColor: action.activeColor
			}
		}
		case "SET_ACTIVE_THEME": {
			return {
				...state,
				darkTheme: action.darkTheme
			}
		}
        default:
			return state;
    }
}
export default colorReducer;