import {
  CREATE_MENUS,
  GET_LANGUAGES,
  GET_MENUS,
  GET_LEVELS,
  GET_TRAINING_CATEGORY,
  GET_COURSES,
  DELETE_COURSE,
  CREATE_COURSE,
  ADD_BATCH,
  DELETE_SESSION,
  GET_CURRENCIES,
  ADD_SESSION,
  ADD_ASSOCIATE,
  DELETE_ASSOCIATE,
  ADD_FINANCE,
  DELETE_FINANCE
} from "../actions/types";

const reducer = (state = {}, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_MENUS: {
      return {
        ...state,
        menus: payload
      }
    }
    case CREATE_MENUS: {
      return {
        ...state,
        newMenus: payload
      }
    }
    case GET_LANGUAGES: {
      return {
        ...state,
        languages: payload
      }
    }
    case GET_LEVELS: {
      return {
        ...state,
        levels: payload
      }
    }
    case GET_TRAINING_CATEGORY: {
      return {
        ...state,
        trainingCategory: payload
      }
    }
    case GET_COURSES: {
      return {
        ...state,
        userCourses: payload
      }
    }
    case DELETE_COURSE: {
      return {
        ...state,
        delCourse: payload
      }
    }
    case CREATE_COURSE: {
      return {
        ...state,
        createCourse: payload
      }
    }
    case ADD_BATCH: {
      return {
        ...state,
        batch: payload
      }
    }
    case DELETE_SESSION: {
      return {
        ...state,
        deleteSession: payload
      }
    }
    case GET_CURRENCIES: {
      return {
        ...state,
        currencies: payload
      }
    }
    case ADD_SESSION: {
      return {
        ...state,
        session: payload
      }
    }
    case ADD_ASSOCIATE: {
      return {
        ...state,
        associate: payload
      }
    }
    case DELETE_ASSOCIATE: {
      return {
        ...state,
        deleteAsso: payload
      }
    }
    case ADD_FINANCE: {
      return {
        ...state,
        finance: payload
      }
    }
    case DELETE_FINANCE: {
      return {
        ...state,
        deleteFinan: payload
      }
    }
    default:
      return state;
  }
}

export default reducer;