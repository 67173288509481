import {
  getUser
} from '../utils/UserDetails';

class TrainingService {
  async getMenus() {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/get_menus', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${currentUser.api_token}`
      }
    }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const menu = data;
      return menu;
    })
  }

  async createMenus(menuData) {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/add_menus', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${currentUser.api_token}`
      },
      body: JSON.stringify({
        menus: menuData
      })
    }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const menuData = data.result.data;
      return menuData;
    })
  }

  async getAllLanguages() {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/getlanguages', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${currentUser.api_token}`
      }
    }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const lang = data;
      return lang;
    })
  }

  async getAllLevels() {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/getlearninglevels', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${currentUser.api_token}`
      }
    }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const levels = data;
      return levels;
    })
  }

  async getTrainingCategory() {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/get_category', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${currentUser.api_token}`
      }
    }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const category = data;
      return category;
    })
  }

  async getCourses() {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/get_courses', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${currentUser.api_token}`
      }
    }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const courses = data;
      return courses;
    })
  }

  async deleteCourse(deleteData) {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/new_delete_course', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${currentUser.api_token}`
      },
      body: JSON.stringify(deleteData)
    }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const status = data;
      return status;
    })
  }

  async createCourse(courseData) {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/add_course', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${currentUser.api_token}`
      },
      body: JSON.stringify(courseData)
    }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const course = data;
      return course;
    })
  }

  async changeOrder(orderData) {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/change_order', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${currentUser.api_token}`
      },
      body: JSON.stringify(orderData)
    }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const order = data;
      return order;
    })
  }

  async addBatch(batchData) {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/add_batch', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${currentUser.api_token}`
      },
      body: JSON.stringify(batchData)
    }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const batch = data;
      return batch;
    })
  }

  async deleteSession(sessionData) {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/delete_session', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${currentUser.api_token}`
      },
      body: JSON.stringify(sessionData)
    }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const deleted = data;
      return deleted;
    })
  }

  async getCurrencies() {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/getcurrencies', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${currentUser.api_token}`
      }
    }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const currencies = data;
      return currencies;
    })
  }

  async addSession(sessionData) {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/add_session', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${currentUser.api_token}`
      },
      body: JSON.stringify(sessionData)
    }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const currencies = data;
      return currencies;
    })
  }

  async addAssociate(associateData) {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/add_associate', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${currentUser.api_token}`
      },
      body: JSON.stringify(associateData)
    }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const currencies = data;
      return currencies;
    })
  }

  async deleteAssociate(associateData) {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/delete_associate', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${currentUser.api_token}`
      },
      body: JSON.stringify(associateData)
    }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const deleted = data;
      return deleted;
    })
  }

  
  async addFinance(financeData) {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/add_finance', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${currentUser.api_token}`
      },
      body: JSON.stringify(financeData)
    }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const finance = data;
      return finance;
    })
  }

  async addFeedback(feedbackData) {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/add_feedback', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${currentUser.api_token}`
      },
      body: JSON.stringify(feedbackData)
    }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const feedback = data;
      return feedback;
    })
  }
}

export default new TrainingService();