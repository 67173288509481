import { Modal } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const DeleteModal = (props) => {
    return (
        <Modal show={props.showDeleteModal.showModal} onHide={props.closeDeleteModal} backdrop="static" keyboard={false} centered className="tj-modal">
            <FontAwesomeIcon icon={faTimes} role="button" className="text-muted interest-alert-times" onClick={() => props.closeDeleteModal()} />
            <Modal.Header className="d-flex">
                <p className="flex-grow-1 text-center interest-alert-text">Are you sure you want to delete?</p>
            </Modal.Header>

            <Modal.Footer className="justify-content-center">
                <button type="button" className="btn btn-success mx-2" onClick={props.submitDelete}>Yes</button>
                <button type="button" className="btn btn-outline-danger" onClick={props.closeDeleteModal}>No</button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteModal;