import { useState, useEffect } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Modal } from 'react-bootstrap';

const SessionGrid = (props) => {
  const [allSessions, setAllSessions] = useState([]);

  const [columnDefs] = useState([
    { field: 'Session Number' },
    { field: 'Session Name' }
  ]);

  useEffect(() => {
    let temp = [];
    if (props.viewData.length) {
      props.viewData[0].sessions.map((session) => {
        let obj = {};
        obj['Session Number'] = session.session_number;
        obj['Session Name'] = session.session_name;
        temp.push(obj);
      })
      setAllSessions(temp);
    }

  }, [props.viewData])

  return (
    <>
      <Modal show={props.showViewSessions} onHide={props.closeSessionViewModal} backdrop="static" keyboard={false} centered className="tj-modal" size="lg">
        <Modal.Header closeButton className="d-flex">
          <Modal.Title className="flex-grow-1">Sessions</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='row justify-content-center'>
            <div className='col-md-2'>
              <label>Course Name</label>
            </div>
            <div className='col-md-2'>
              < label > {
                (props.viewData.length) ? props.viewData[0].course_name : ''} </label>
            </div>
            <div className='col-md-2'>
              <label>Batch Name</label>
            </div>
            <div className='col-md-2'>
              <label>{(props.viewData.length) ? props.viewData[0].course_batchname : ''}</label>
            </div>
          </div>
          <div className='row justify-content-center m-2'>
            <div className="ag-theme-alpine mb-2" style={{ height: 300, width: 430 }}>
              <AgGridReact
                rowData={allSessions}
                columnDefs={columnDefs}>
              </AgGridReact>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SessionGrid;