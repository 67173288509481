import { Modal } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const TrainingModal = (props) => {
  return (
    <Modal show={props.showRefreshAlertModal.showAlert} onHide={props.closeRefreshAlertModal} backdrop="static" keyboard={false} centered className="tj-modal" >
      <FontAwesomeIcon icon={faTimes} role="button" className="text-muted interest-alert-times" onClick={() => props.closeRefreshAlertModal()} />
      <Modal.Header className="d-flex">
        <p className="flex-grow-1 text-center interest-alert-text">Are you sure you want to refresh?</p>
      </Modal.Header>

      <Modal.Footer className="justify-content-center">
        <button type="button" className="btn btn-primary global-blue mx-2 btn-sm" onClick={props.submitRefresh}>Continue</button>
        <button type="button" className="btn btn-outline-danger btn-sm" onClick={props.closeRefreshAlertModal}>No</button>
      </Modal.Footer>
    </Modal>
  )
}

export default TrainingModal;