import  React, {useState, useEffect, Suspense} from 'react'

import { Link, useLocation, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { connect } from "react-redux";
import { BiHeart, BiPlus } from "react-icons/bi";
import { BsJournalText,BsCameraVideo, BsCircle} from "react-icons/bs";

import CustomUserLinkModal from './CustomUserLinkModal';
import CommonIcons from '../../CommonIcons';
import { getUser } from '../../../utils/UserDetails';
import { getCustomUserLink, setCustomUserLinkModal } from '../../../actions/customUserLink';
import { feedSidebarIsCollapsed } from '../../../actions/common';
import { getZoomConnect } from '../../../actions/zoomMeeting';
// import TrainingCourse from './TrainingCourse';



const CustomUserLink = React.lazy(() => import('./CustomUserLink'));
const ZoomMeeting = React.lazy(() => import('./ZoomMeeting/ZoomMeeting'));
const TrainingCourse = React.lazy(() => import('./TrainingCourse/TrainingCourse'))  

const FeedLeftMenu = (props) => {

  const currentUser = getUser();
  const history = useHistory()
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const zoomRedirectCode = queryParams.get("code");

  const [isExpanded, setIsExpanded] = useState(null)
  const [collapseHover, setCollapseHover] = useState(null)
  const [menuHover,setMenuHover]=useState(null)
  const [activeMenu, setActiveMenu] = useState(null);
  const [customUserMenu, setCustomUserMenu] = useState(null);
  const [showResults, setShowResults] = useState(false)
  const [isMyFavOpen, setIsMyFavOpen] = useState(true)
  const [isMyPageOpen, setIsMyPageOpen] = useState(true)
  const [isVidOpen, setIsVidOpen] = useState(true)
  const [isMyTrainingOpen, setIsMyTrainingOpen] = useState(true)
  
  const onClick = () => {
    if(showResults){
      setShowResults(false);
    }
    else{
      setShowResults(true);
    }
  }

  useEffect(()=> {
    if(currentUser) {
      props.getCustomUserLink();
    }
    setIsExpanded(true);
    setCollapseHover(false);
    setMenuHover(false)
  },[])

  useEffect(()=> {
    setCustomUserMenu(props.customUserLink)
 },[props.customUserLink])

 useEffect(() => {
   if(zoomRedirectCode) {
      queryParams.delete('code');
      queryParams.delete('state');
      history.replace({
        search: queryParams.toString(),
      })

      props.getZoomConnect({code: zoomRedirectCode})
      setTimeout(() => {
        if(window.opener && window.name === 'tjunctionZoomAuth') {
          window.self.close();
        }
      },1000)
      
   }
 
 },[zoomRedirectCode])

  useEffect(() => {
    props.feedSidebarIsCollapsed((isExpanded) ? 'is-expand' : '')
  },[isExpanded])

  const handleToggler = () => {
		setCollapseHover(false)
		setActiveMenu('')
		if(isExpanded) {
			setIsExpanded(false)
			return;
		}
		setIsExpanded(true)
	}

  const openLinkModal = (customUserLinkObj = {}) => {
    let modalData= {...props.customUserLinkModal} 
    props.setCustomUserLinkModal({...modalData, isModalOpen:true, customUserLinkObj:{...customUserLinkObj} })
  }


  

  return(
    <>
    	<div className={isExpanded ? "qboard-sidebar" : "qboard-sidebar collapsed"}>
				<div className="sidebar-items">

         <div className={`collapse-left d-none d-lg-block`} onClick={handleToggler} onMouseEnter={()=>setCollapseHover(true)} onMouseLeave={()=>setCollapseHover(false)}>
            {(isExpanded) ?
                <CommonIcons
                  collapse = {1}
                  collapseHover = {collapseHover}
                /> :
                <CommonIcons
                  expand = {1}
                  collapseHover = {collapseHover}
                />
            } 
					</div>
          <div className='overflow-auto'>
            <div className='sidebar-item'>
              
            <div id={props.activeColor} 
                className={`item ` 
                            + ((isMyFavOpen === true)? `${props.activeColor} active` : ``)
                          } 
                onMouseEnter={()=>setMenuHover(true)} 
                onClick={()=> currentUser && (isExpanded && setIsMyFavOpen(!isMyFavOpen))}>
                {/* <img src={process.env.PUBLIC_URL + '/images/menu-icons/heart.svg'} className="sidebar-icons" /> */}
                <BiHeart/>
                <span className="sidebar-text">My Favourite</span>
                <span className={`fas fa sidebar-arrow-down ` +((isMyFavOpen === true) ? "fa-angle-left" : 'fa-angle-down')}
></span> 
            </div>

            </div> 
              {(currentUser && (isMyFavOpen === true)) ? 
                <div className='sidebar-nav' >
                <Suspense fallback={""}>
                  <div id={props.activeColor} className="item sub-item">
                      <span className="sidebar-text" onClick={openLinkModal }> 
                        <BiPlus className="plus-icon"/>
                        <span className="sidebar-text">
                          Add Link
                        </span> 
                      </span>
                  </div>
                  {(customUserMenu && customUserMenu.length) ? 
                    customUserMenu.map((menu, menuKey) => {
                      return (
                        <CustomUserLink 
                          key = {menuKey}
                          menu = {menu} 
                          openLinkModal = {openLinkModal}
                        />
                      )
                    })
                    : null
                  }


                </Suspense>
                </div>
              : 
              null 
              }

            <div id={props.activeColor} 
                className={`item ` 
                + ((isMyPageOpen === true) ? `${props.activeColor} active` : ``)
                } onClick={()=>  currentUser && (isExpanded && setIsMyPageOpen(!isMyPageOpen))}>
              {/* <img src={process.env.PUBLIC_URL + '/images/menu-icons/page-view.svg'} className="sidebar-icons" /> */}
              < BsJournalText/>
              <span  className="sidebar-text">My Page</span>
              <span className={`fas fa sidebar-arrow-down ` + ((isMyPageOpen == true) ? "fa-angle-left" : 'fa-angle-down')}></span> 
            </div>

            {(isMyPageOpen === true) ? 
                <div className="item sub-item">
                  <Link to="/profile" target="_blank">
                  <span className="sidebar-text" id={`${props.activeColor}`+ `-insidezoom`}>My Profile</span>
                  </Link>
                </div>
                : null
            }

            <div id={props.activeColor} 
                className={`item ` 
                + ((isVidOpen === true) ? `${props.activeColor} active` : ``)
                } onClick={()=> currentUser && (isExpanded && setIsVidOpen((!isVidOpen)))}>
              {/* <img src={process.env.PUBLIC_URL + '/images/menu-icons/web.svg'} className="sidebar-icons" /> */}
              {/* <i class="bi bi-camera-video sidebar-icons"></i> */}
              <BsCameraVideo/>
              <span className="sidebar-text">Video Conferencing</span>
              <span className={`fas fa sidebar-arrow-down ` +((isVidOpen === true) ? "fa-angle-left" : 'fa-angle-down')}></span> 
            </div>

            {(isVidOpen === true) ? 
              <Suspense fallback={""}>
                <div id={props.activeColor} className="item sub-item " onClick={onClick}>
                  <span className="sidebar-text">Zoom</span>
                  { showResults ? <ZoomMeeting /> : null }
                </div>
                <div id={`${props.activeColor}`+ `-insidezoom`} className="item sub-item " >
                  <span className="sidebar-text">Google Meet</span>
                </div>
                <div id={`${props.activeColor}`+ `-insidezoom`} className="item sub-item " >
                  <span className="sidebar-text">ZointVC</span>
                </div>
              </Suspense>
            : null
            }


            <div id={props.activeColor} 
                className={`item `
                + ((isMyTrainingOpen === true) ? `${props.activeColor} active` : ``)
                } 
                onClick={()=>  currentUser && (isExpanded && setIsMyTrainingOpen((!isMyTrainingOpen)))}
                >
              {/* <img src={process.env.PUBLIC_URL + '/images/menu-icons/page-view.svg'} className="sidebar-icons" /> */}
              {/* <i class="bi bi-journal-text sidebar-icons"></i> */}
              < BsJournalText/>
              <span className="sidebar-text">My Trainings</span>
              <span className={`fas fa sidebar-arrow-down ` + ((isMyTrainingOpen === true) ? "fa-angle-left" : 'fa-angle-down')}></span> 
            </div>

            {
              (isMyTrainingOpen === true) ? 

                // <div className="item sub-item">
                //   <Link to="/training" target="_blank">
                //   <BsCircle className='small' /><span id={`${props.activeColor}`+ `-insidezoom`} className="sidebar-text">Manage My Trainings</span>
                //   </Link>
                // </div>
                <Suspense fallback={""}>
                <div id={props.activeColor} className="item sub-item " onClick={onClick}>
                  <span className="sidebar-text">Manage My Trainings</span>
                  { showResults ? <TrainingCourse /> : null }
                </div>
                </Suspense>
                : null
            }
          </div>
        </div>
      </div>

      <CustomUserLinkModal />
     </>
  );
};

const mapStateToProps = state => ({
	activeColor: state.activetheme.activeColor,
  customUserLink: state.customUserLink.customUserLink,
  customUserLinkModal:  state.customUserLink.customUserLinkModal,
  activePopupWindow:state.common.activePopupWindow
});


export default connect(mapStateToProps, {getCustomUserLink, setCustomUserLinkModal, feedSidebarIsCollapsed, getZoomConnect})(FeedLeftMenu);