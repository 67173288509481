import {
  SET_CUSTOM_USER_LINK,
  CUSTOM_USER_LINK_NOTIFICATION,
  CUSTOM_USER_LINK_MODAL,
  INSERT_CUSTOM_USER_LINK,
  UPDATE_CUSTOM_USER_LINK,
  DELETE_CUSTOM_USER_LINK
} from "../actions/types";

const initialState = { 
  customUserLink: [],
  customUserLinkModal: {
    isModalOpen: false,
    customUserLinkObj: {},
  },
  customUserLinkNotification: {
    message: '',
    isSuccess: 1
  },
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case SET_CUSTOM_USER_LINK:
      return {
        ...state, 
        customUserLink: payload
      }
    case CUSTOM_USER_LINK_NOTIFICATION:
      return {
        ...state, 
        customUserLinkNotification: (payload.message) ? payload : initialState.customUserLinkNotification
      }
    case CUSTOM_USER_LINK_MODAL:
      return {
        ...state, 
        customUserLinkModal: {...state, ...payload}
      }
    case INSERT_CUSTOM_USER_LINK:
      return {
        ...state, 
        customUserLink: [payload, ...state.customUserLink]
      }
    case UPDATE_CUSTOM_USER_LINK:
      return {
        ...state, 
        customUserLink: state.customUserLink.map(menulink => menulink.user_link_id === payload.user_link_id ? 
          { ...menulink, ...payload} : menulink
        )
      }
    case DELETE_CUSTOM_USER_LINK:
      return {
        ...state,
        customUserLink:  state.customUserLink.filter(menulink => menulink.user_link_id !== payload.user_link_id)
      }
       
    default:
      return state;
  }
  
}
export default reducer;