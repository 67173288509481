import Trash from '../trash/Trash';

const Interests = (props) => {
	return (
		<>
			<div className="col-md-6 row interest-col">
				<div className="col-5 interest-col">
					<p className="py-1 m-2">{props.interestData.interests.interests_name}</p>
				</div>
				<div className="col-1 interest-col small-toggle" style={{ marginTop: "-10px" }}>
					{(props.editableStatus.status) ?
						<div className={((props.interestData.user_interests_show === 1) ? 'checkbox-container-off' : 'checkbox-container')}>
							<input type="checkbox" id={"checkbox-interest-" + props.index} onChange={(e) => props.showAndHideOption(e.currentTarget.checked, props.interestData.user_interests_id, 'interest', props.index)} name="interestShowHide" defaultChecked={(props.interestData.user_interests_show === 1) ? false : true} />
							<label htmlFor={"checkbox-interest-" + props.index}><span className="interest-hide-text">Hide</span></label>
						</div>
						: <div className=""></div>
					}
				</div>
				<div className="col-1 interest-col">
					{(props.allInterests.length > 1 && props.editableStatus.status) ?
						<div className="interest-trash trash">
							<Trash
								id={props.interestData.user_interests_id}
								index={props.index}
								source='interest'
								active={props.activeInterest} />
						</div> : ''
					}
				</div>
			</div>
		</>
	)
}

export default Interests;
