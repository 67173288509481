import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import renderHTML from 'react-render-html';

import Trash from '../trash/Trash';

const Certificates = (props) => {
  let descriptionIsVisible = 0;
  let certificateNameIsVisible = 0;
  let institutionIsVisible = 0;

  if (props.certificateData.user_certificates_description) {
    descriptionIsVisible = 1;
  }

  if (props.certificateData.user_certificates_name) {
    certificateNameIsVisible = 1;
  }

  if (props.certificateData.user_certificates_institution_name) {
    institutionIsVisible = 1
  }

  if (props.editableStatus.status) {
    descriptionIsVisible = 1;
    certificateNameIsVisible = 1;
    institutionIsVisible = 1;
  }

  return (
    <div className={(!props.editableStatus.status) ? "" : "card mb-4"}>
      {(certificateNameIsVisible || institutionIsVisible) ?
        <div className={(!props.editableStatus.status) ? "" : "card-header p-1"}>
          <div className="row">
            {(certificateNameIsVisible) ?
              <div className={`col-md-4 ` + ((props.editableStatus.status && !props.certificateData.user_certificates_show) ? 'hide-opacity' : '')}>
                {(props.editableStatus.status) ?
                  <label className="profile-form-label justify-content-center d-flex card-labels-size"> Certification name </label> : ''}
                {(props.editableStatus.status) ?
                  <input type="text" key={`certiname${props.index}`}
                    className={`form-control py-1 m-2 text-center ` + ((props.editableStatus.status && !props.certificateData.user_certificates_show) ? 'hide-opacity' : '')}
                    name="user_certificates_name"
                    value={
                      (props.certificateData.user_certificates_name) ? props.certificateData.user_certificates_name : ""
                    }
                    onBlur={
                      (e) => props.editCertificate(props.index, props.certificateData)
                    }
                    onChange={
                      (e) => props.certificateChangeHandler(e, 'text', props.index)
                    }
                  /> : <p className="tab-sub-heading">{(props.certificateData.user_certificates_name) ? props.certificateData.user_certificates_name : ''}</p>}

              </div> : <div className="col-md-4"></div>
            }

            {(institutionIsVisible) ?
              <div className={`col-md-4 px-4 ` + ((props.editableStatus.status && !props.certificateData.user_certificates_show) ? 'hide-opacity' : '')}>
                {(props.editableStatus.status) ?
                  <label className="profile-form-label justify-content-center d-flex card-labels-size"> Institution name </label> : ''}
                {(props.editableStatus.status) ?
                  <input type="text" key={`certiinst${props.index}`}
                    className={`form-control py-1 m-2 text-center ` + ((props.editableStatus.status && !props.certificateData.user_certificates_show) ? 'hide-opacity' : '')}
                    name="user_certificates_institution_name"
                    value={
                      (props.certificateData.user_certificates_institution_name) ? props.certificateData.user_certificates_institution_name : ""
                    }
                    onBlur={
                      (e) => props.editCertificate(props.index, props.certificateData)
                    }
                    onChange={
                      (e) => props.certificateChangeHandler(e, 'text', props.index)
                    }
                  /> : <p className="">{(props.certificateData.user_certificates_institution_name) ? props.certificateData.user_certificates_institution_name : ''}</p>}

              </div> : <div className="col-md-4 px-4"></div>
            }
            <div className="col-md-3" style={{ marginLeft: "-21px" }}></div>
            <div className="col-md-1">
              {(props.editableStatus.status) ?
                <div className={((props.certificateData.user_certificates_show === 1) ? 'checkbox-container-off' : 'checkbox-container')}>
                  <input type="checkbox" id={"checkbox-cert-" + props.index} onChange={(e) => props.showAndHideOption(e.currentTarget.checked, props.certificateData.user_certificates_id, 'certificate', props.index)} name="certificateShowHide" defaultChecked={(props.certificateData.user_certificates_show === 1) ? false : true} />
                  <label htmlFor={"checkbox-cert" + props.index}><span className={((props.certificateData.user_certificates_show === 1) ? 'inner-hide-text-position1' : 'inner-hide-text-position')}>Hide</span></label>
                </div>
                : <div className="checkbox-container"></div>
              }

              <div className="">
                {(props.activeCertificates.length > 1 && props.editableStatus.status) ?
                  <div className="trash" style={{ marginTop: "27px", marginLeft: "50px" }}>
                    <Trash
                      id={props.certificateData.user_certificates_id}
                      index={props.index}
                      source='certificate'
                      active={props.activeCertificates} />
                  </div> : ''
                }
              </div>
            </div>

          </div>
        </div> : 'No certificate details found'}

      {/* description section */}
      <div>
        {(descriptionIsVisible) ?
          <div className="card-body p-1 desc-resp">
            <div className="row">
              <div className="col-md-12">
                {(props.editableStatus.status) ?
                  <ReactQuill
                    className={(props.editableStatus.status && !props.certificateData.user_certificates_show) ? 'hide-opacity' : ''}
                    key={`certidesc${props.index}`}
                    name="user_certificates_description"
                    modules={props.modules}
                    formats={props.formats}
                    value={props.certificateData.user_certificates_description}
                    onBlur={() => props.editCertificate(props.index, props.certificateData)}
                    onChange={(e) => props.certificateChangeHandler(e, 'editor', props.index)}
                  />
                  : <p className="">{(props.certificateData.user_certificates_description) ? renderHTML(props.certificateData.user_certificates_description) : ''}</p >
                }
              </div>
            </div>
          </div> : ''
        }
      </div>
    </div>
  )
}

export default Certificates;
