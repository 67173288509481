import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const FacebookLoginComponent = () => {

    const responseFacebookSuccess = (response) => {
        console.log("Facebook login successful:", response);
        toast.success("Logged in with Facebook!");
    }

    const responseFacebookError = (response) => {
        toast.error("Please Try Again!");
    };

    return (
        <FacebookLogin
            appId="your-facebook-app-id"  // Replace with your actual Facebook App ID
            autoLoad={false}
            fields="first_name,last_name,email"
            callback={responseFacebookSuccess}
            onFailure={responseFacebookError}
            render={renderProps => (
                <Link className="btn btn-block btn social-btn-google" to="#" onClick={renderProps.onClick} disabled={renderProps.isDisabled}>
                    <img src="https://img.icons8.com/ios-filled/50/0000FF/facebook-new.png" alt="Facebook Icon" style={{ marginRight: '10px', verticalAlign: 'middle' }}/>
                    Continue with Facebook
                </Link>
            )}
        />
    )
}

export default FacebookLoginComponent;
