import React from 'react';

import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { setTrainingApplyDetails } from '../../../../actions/reqTraining';



const TrainingApplyDetailsModal = (props) => {

  const closeModal = () => {
    let trainingApplyData = {...props.trainingApplyDetails}
    props.setTrainingApplyDetails({...trainingApplyData, isModalOpen: false})
  }

  return (
    <Modal show={props.trainingApplyDetails.isModalOpen} size={"lg"} className={`tj-modal ` + (props.darkTheme ? 'is-dark-theme' : '')}  onHide={closeModal} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton className="d-flex">
        <Modal.Title className="flex-grow-1">Applied Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
            <div className="job-application-view">
							<div className="row">
								<div className="col-md-1 text-center font-weight-bold">
									S.no
								</div>
								<div className="col-md-4 text-center font-weight-bold">
									Name
								</div>
								<div className="col-md-4 font-weight-bold text-center">
									Email
								</div>
								<div className="col-md-3 text-center font-weight-bold">
									Applied Date
								</div>
							</div>

              { (props.trainingApplyDetails.appliedDetailsData.length) ? props.trainingApplyDetails.appliedDetailsData.map((details, index) => {
                    return (
                      	<div className="row">
                          <div className="col-md-1 text-center">
                            {index+1}
                          </div>
                          <div className="col-md-4 text-center">
                            {details.name}
                          </div>
                          <div className="col-md-4 text-center">
                            {details.email}
                          </div>
                          <div className="col-md-3 text-center">
                            {details.created_at}
                          </div>
                        </div>
                    )
                }) 
                : 
                <p className="text-center">No Data Found</p>
              }
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = state => ({
  activeColor: state.activetheme.activeColor,
  darkTheme: state.activetheme.darkTheme,
  trainingApplyDetails: state.reqTraining.trainingApplyDetails
});

export default connect(mapStateToProps, {setTrainingApplyDetails})(TrainingApplyDetailsModal);
