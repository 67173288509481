import {useState, useEffect} from 'react'

import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { Modal} from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';

import AuthService from '../services/auth.service';
import { setUserSession } from '../utils/UserDetails';
import Axios from "../config/axios";


const EmailVerification = () => {

	const successData = { 
		isModalOpen: false,
		isSuccessOpen: false,
	}
	const history = useHistory();
	const location = useLocation()
	const [successModalData, setSuccessModalData] = useState(successData)
	const [iserror, setError] = useState(false)


	useEffect(() => {
		verifyEmail();
	}, [])

	const verifyEmail = async() => {
		const verificationData = location.pathname.split("/");
		const verfiyData = {
			'email' : verificationData[verificationData.length - 2],
			'token' : verificationData[verificationData.length-1]
		}

		const getverification = await AuthService.verifyEmail(verfiyData);

		if(getverification?.status && getverification.status === 2000) {
			setSuccessModalData({...successModalData, isModalOpen: true, isSuccessOpen: true})
			setUserSession(getverification.data);

			Axios.interceptors.request.use(function (config) {
				config.headers['Authorization'] = (getverification?.data?.api_token) ?  `Bearer ${getverification.data.api_token}` : '';
				return config;
			});
		} else {
			setError(true)
		}
	}

	const closeSuccessModal = () => {
		history.push("/primary-profile-view");
	}

	return (
		
		<>
			{ (iserror)
				? 
				<div className="container">
					<p>Invalid Link</p>
					<Link to="/" className="btn btn-primary">Try Again</Link>
				</div>
				: <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} className="loader text-center margin-auto" />
			}
		
			<Modal show={successModalData.isModalOpen} onHide={closeSuccessModal} backdrop="static" keyboard={false} className="tj-modal" size="lg">
				<Modal.Header>
						<Modal.Title></Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="container p-4 text-center">							
							<div className="">
								<h5 className="mb-4">Email Verified Successfully</h5>
								<button className="btn btn-primary" onClick={closeSuccessModal}>
									ok
								</button>
							</div>						
						</div>
					</Modal.Body>
			</Modal>

	    </>
	)
}

export default EmailVerification
