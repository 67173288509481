import { useState, useEffect } from 'react'

import { connect } from "react-redux";
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroller';

import TrainingApplyModal from './TrainingApplyModal';
import TrainingApplyDetailsModal from './TrainingApplyDetailsModal';
import PostCardHeader from '../../postcard/PostCardHeader';
import PostCardFooter from '../../postcard/PostCardFooter';
import PostCardBody from '../../postcard/PostCardBody';
import CommentView from '../../commentview/CommentView';
import Axios from '../../../../config/axios';
import { setModalData } from '../../../../actions/dashboradCommon';
import {getTrainingPost, insertTrainingPost, setTrainingError, setTrainingSuccess, updateTrainingPost} from '../../../../actions/reqTraining'

toast.configure();

const Training = (props) => {

	const isFrom = 'training';
	const [isLoading, setIsLoading]  = useState(false);
	const [hasMoreItems, setHasMoreItems] = useState(true)

	useEffect(() => {

		if (props.broadcastingEcho) {
			props.broadcastingEcho.private('requirement-training-chat')
				.listen('RequirementTrainingEvent', (e) => {
					let postdata = e.postdata;
          if(postdata.isUpdate) {
            props.updateTrainingPost(postdata)
          } else {
            props.insertTrainingPost(postdata)
          }
				});

        setTimeout(function() {
          Axios.interceptors.request.use(function (config) {
            config.headers['X-Socket-ID'] =  props.broadcastingEcho?.socketId() ? props.broadcastingEcho.socketId() : '';
            return config;
          });
         
        }, 2000);
		}
  },[])

	useEffect(() => {
    setIsLoading(true)
    getTrainingPost(1);
  }, [props.mainFilter])

	useEffect(() => {
    if(props.trainingSuccess) {
      toast.success(props.trainingSuccess)
      props.setTrainingSuccess('');
    }
  }, [props.trainingSuccess])

  useEffect(() => {
    if(props.trainingError) {
      toast.error(props.trainingError)
      props.setTrainingSuccess('');
    } 
  }, [props.trainingError])

	useEffect(() => {
    if(props?.trainingPosts && props.trainingPosts.length && (props.trainingPosts.length === props.trainingPostCount)) {
      setHasMoreItems(false)
    }
  },[props.trainingPosts])


	const getTrainingPost = (isFromFilter = 0, skip = 0) => {
    let trainingRequestData = {...props.mainFilter};
    trainingRequestData.skip = skip;
    props.getTrainingPost(trainingRequestData ,setIsLoading, isFromFilter);
  }

	const openModal = (trainingObj = {}) => {
		let modalContent= {...props.modalContent} 
    props.setModalData({...modalContent, requirementTrainingModal:true, trainingObj:{...trainingObj} })
	}
	
	return (
		<>

		{
		(isLoading) ? 
			 <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} className="loader text-center" />
			 :
			 <>
					 {(props.trainingPosts && props.trainingPosts.length) ?

						<InfiniteScroll
							pageStart={0}
							loadMore={ () => getTrainingPost(0, (props.trainingPosts && props.trainingPosts.length) ? props.trainingPosts.length : 0)}
							hasMore={hasMoreItems}
							loader={<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} className="loader text-center" />}
							initialLoad={false}
						>

						{props.trainingPosts.map((post, postKey) => {
							return (
								<>
									<div className='position-relative mb-2'>	
										<div>
											<div className="post-card">
												
												<PostCardHeader
													post = {post}
													key = {postKey}
													index = {postKey}
													openModal = {openModal}
													isFrom = {isFrom}
												/>
												<PostCardBody
													post = {post}
													key = {postKey}
													index = {postKey}
													isFrom = {isFrom}
												/>
												{/* <PostCardFooter 
													post = {post}
													key = {postKey}
													index = {postKey}
													isFrom = {isFrom}
												/> */}
					
											</div>
										</div>
								</div>

									{/* comment view  */}
									{(post.common_comments.length && post.showComment) ?
										<div className="post-comment-view mb-1">
											{post.common_comments.map((cmts, postKeys) => {
													return (
													<CommentView
														post= {cmts}
														key={postKeys}
														/>
													)
											})
											}
											</div>
										: null
								}

								</>
							)
						})
					}
					</InfiniteScroll>
					: <p className="text-center no-data-found my-2"> No Data Found</p>
				}

			 </>
		}
	<TrainingApplyModal />
	<TrainingApplyDetailsModal />
	</>
	)
}

const mapStateToProps = state => ({
	activeProfile: state.profile.activeProfile,
	activeColor: state.activetheme.activeColor,
	trainingPosts: state.reqTraining.trainingPost,
	trainingPostCount:  state.reqTraining.trainingPostCount,
	mainFilter:  state.dashboardCommon.mainFilter,
	broadcastingEcho: state.common.broadcastingEcho,
	trainingSuccess: state.reqTraining.trainingSuccess,
  trainingError: state.reqTraining.trainingError
});

export default connect(mapStateToProps, {getTrainingPost, setModalData, insertTrainingPost, updateTrainingPost, setTrainingError, setTrainingSuccess})(Training);