import { useEffect } from 'react'

import { connect } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Navbar, Nav, NavDropdown, Form, FormControl } from 'react-bootstrap'
import { BsSearch, BsChatLeftText, BsBell, BsExclamationCircle, BsBoxArrowRight, BsPerson, BsGear, BsQuestionCircle } from "react-icons/bs";
import { BiBell } from "react-icons/bi";
import 'bootstrap/dist/css/bootstrap.min.css';


import ThemeColor from '../ThemeColor';
import ProfileService from "../../services/profile.service";
import { filterData } from '../../actions/dashboradCommon';
import { userLogout } from '../../actions/auth';
import { getUser, removeUserSession } from '../../utils/UserDetails';
import { getProfileDetails, setPrimaryProfile } from "../../actions/profile";
import CommonService from '../../services/common.service';


const Header = (props) => {

  const history = useHistory();
  const currentUser = getUser();

  useEffect(() => {
    if (currentUser) {
      props.getProfileDetails(1);
    }
    CommonService.scrollToTop()
  }, [])

  const setPrimaryProfile = (activeProfile) => {
    props.setPrimaryProfile(activeProfile);
  }

  const handleSearchChange = (event) => {
    let filterContent = { ...props.mainFilter };
    filterContent.searchTerm = event.target.value;
    props.filterData(filterContent);
  }

  const onLogoutHandler = () => {
    removeUserSession();
    props.userLogout(history);
  };

  const headerProfilePic = () => {
    return (
      <img className="p-1 header-profile-pic"
        src={(props.activeProfile.user_profile_image_url) ? props.activeProfile.user_profile_image_url : ProfileService.defaultProfilePic()}
        onError={(e) => { e.target.onerror = null; e.target.src = process.env.PUBLIC_URL + '/images/icons/profile1.jpg' }}
      />
    );
  }

  return (
    <>
      <header className="header pt-1 fixed-top">
        <Navbar expand="lg" sticky="top">
          <div className="container-xxl">
            <Navbar.Brand className="tjunction-logo logo-marginleft">
              <Link to="/">
                {(props.darkTheme) ?
                  <img src={process.env.PUBLIC_URL + '/images/tjunction_black_bg_logo.png'} />
                  :
                  <img src={process.env.PUBLIC_URL + '/images/tjunction_white_bg_logo.png'} />
                }

              </Link>
            </Navbar.Brand>

            <div class="search-bar">
              <Form inline className="search-form d-flex align-items-center">
                <FormControl type="text" name="query" placeholder="Search" title="Enter search keyword" onChange={handleSearchChange} value={props.mainFilter.searchTerm} />
                <button type="submit" title="Search"><BsSearch /></button>
              </Form>
            </div>


            {(currentUser && props.userProfiles) ?
              <>
                <nav class="header-nav ms-auto">
                  <ul class="d-flex align-items-center">
                    <div className="d-none d-lg-block">
                      {(props.userProfiles && props.userProfiles.length > 1) &&
                        <NavDropdown className='currentprfl' alignLeft title={props.activeProfile.user_profile_name} id="basic-nav-dropdown" align="end">
                          {props.userProfiles.map((profile, profileKey) => {
                            return (
                              <NavDropdown.Item key={profileKey} onClick={() => setPrimaryProfile(profile)}><FontAwesomeIcon icon={faCheck} id="tick-mark-check" className={(props.activeProfile.user_profile_id === profile.user_profile_id) ? '' : 'invisible'} />  {profile.user_profile_name}</NavDropdown.Item>
                            )
                          })
                          }
                        </NavDropdown>
                      }
                    </div>
                    <NavDropdown alignLeft title={(props.darkTheme) ?
                      <a href="#" className={(props.darkTheme) ? "notification-dark padding-and-margin-for-notification" : "notification padding-and-margin-for-notification"}>
                        <BsBell />
                        <span class="badge">3</span>
                      </a> :
                      <a href="#" className='notification pr-5 padding-and-margin-for-notification'>
                        <BsBell />
                        <span class="badge">3</span>
                      </a>}
                      id="basic-nav-dropdown pr-0" className='dropdown-menu-end notifications pr-0 ' >
                      <li class="dropdown-header">
                        You have 1 new notifications
                        <a href="#"><span class="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                      </li>
                      <li>
                        <hr class="dropdown-divider" />
                      </li>
                      <li class="notification-item">
                        <BsExclamationCircle />
                        <div>
                          <NavDropdown.Item>
                            <h4>Lorem Ipsum</h4>
                            <p>Quae dolorem earum veritatis oditseno</p>
                            <p>30 min. ago</p>
                          </NavDropdown.Item>
                        </div>
                      </li>

                      <li>
                        <hr class="dropdown-divider" />
                      </li>
                      <li class="dropdown-footer">
                        <a href="#">Show all notifications</a>
                      </li>
                    </NavDropdown>
                    <NavDropdown alignLeft title={(props.darkTheme) ? <a href="#" class={(props.darkTheme) ? "notification-dark" : "notification"}>
                      <BsChatLeftText />
                      <span class="badge">3</span>
                    </a> : <a href="#" class={(props.darkTheme) ? "notification-dark padding-and-margin-for-notification" : "notification padding-and-margin-for-notification"}>
                      <BsChatLeftText />
                      <span class="badge">3</span>
                    </a>} id="basic-nav-dropdown" className='dropdown-menu-end notifications pr-0'>
                      <li class="dropdown-header">
                        You have 2 new messages
                        <a href="#"><span class="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                      </li>
                      <li>
                        <hr class="dropdown-divider" />
                      </li>

                      <NavDropdown.Item className="message-item">
                        <a href="#">
                          <img src="assets/img/messages-1.jpg" alt="" class="rounded-circle" />
                          <div>
                            <h4>Maria Hudson</h4>
                            <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                            <p>4 hrs. ago</p>
                          </div>
                        </a>
                      </NavDropdown.Item >
                      <li>
                        <hr class="dropdown-divider" />
                      </li>

                      <NavDropdown.Item className="message-item">
                        <a href="#">
                          <img src="assets/img/messages-2.jpg" alt="" class="rounded-circle" />
                          <div>
                            <h4>Anna Nelson</h4>
                            <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                            <p>6 hrs. ago</p>
                          </div>
                        </a>
                      </NavDropdown.Item>
                      <li>
                        <hr class="dropdown-divider" />
                      </li>

                      <li class="dropdown-footer">
                        <a href="#">Show all messages</a>
                      </li>
                    </NavDropdown>
                   
                    <NavDropdown alignLeft title= {<img className="profile-icon rounded-circle currentprfl img-padding" src={(props.activeProfile && props.activeProfile.user_profile_image_url) ? props.activeProfile.user_profile_image_url : ProfileService.defaultProfilePic()}
                                alt="profile-logo"
                                onError={(e)=>{e.target.onerror = null; e.target.src = process.env.PUBLIC_URL + '/images/icons/profile1.jpg'}}
                              />}
                              id="basic-nav-dropdown1" className='profile-img-dropdown'> 
                        <li class="notification-item">
                        <NavDropdown.Item>
                          <li class="dropdown-header">
                            <h6><b>{currentUser?.user_name}</b></h6>
                            <span>{props.activeProfile.user_profile_name}</span>
                          </li>
                          <li>
                            <hr class="dropdown-divider" />
                          </li>
                          <li>
                            <a class="dropdown-item d-flex align-items-center" href="users-profile.html">
                              <BsPerson className='for-logout' />
                              <span>My Profile</span>
                            </a>
                          </li>
                          <li>
                            <hr class="dropdown-divider" />
                          </li>

                          <li>
                            <a class="dropdown-item d-flex align-items-center" href="users-profile.html">
                              <BsGear className='for-logout' />
                              <span>Account Settings</span>
                            </a>
                          </li>
                          <li>
                            <hr class="dropdown-divider" />
                          </li>

                          <li>
                            <a class="dropdown-item d-flex align-items-center" href="pages-faq.html">
                              <BsQuestionCircle className='for-logout' />
                              <span>Need Help?</span>
                            </a>
                          </li>
                          <li>
                            <hr class="dropdown-divider" />
                          </li>
                          <a class="dropdown-item d-flex align-items-center padding-and-margin-for-notification" href="#">
                            <div>
                              <BsBoxArrowRight className='for-logout' />
                              <span onClick={onLogoutHandler}>Sign Out</span>
                            </div>
                          </a>
                        </NavDropdown.Item>
                      </li>
                    </NavDropdown>
                    {/* <NavDropdown alignLeft title= {<><img className="profile-icon rounded-circle currentprfl " src={(props.activeProfile && props.activeProfile.user_profile_image_url) ? props.activeProfile.user_profile_image_url : ProfileService.defaultProfilePic()}
                                alt="profile-logo"
                                onError={(e)=>{e.target.onerror = null; e.target.src = process.env.PUBLIC_URL + '/images/icons/prfl.png'}}
                              /><span>{props.activeProfile.user_profile_name}</span></>}
                              id="basic-nav-dropdown"> 
                        <li class="notification-item">
                        <NavDropdown.Item>
                        <li class="dropdown-header">
                        <h6><b>{currentUser?.user_name}</b></h6>
                        <span>{props.activeProfile.user_profile_name}</span>
                        </li>
                        <li>
                        <hr class="dropdown-divider"/>
                        </li>
                        <li>
                        <a class="dropdown-item d-flex align-items-center" href="users-profile.html">
                            <BsPerson className='for-logout'/>
                            <span>My Profile</span>
                        </a>
                        </li>
                        <li>
                        <hr class="dropdown-divider"/>
                        </li>

                        <li>
                        <a class="dropdown-item d-flex align-items-center" href="users-profile.html">
                            <BsGear className='for-logout'/>
                            <span>Account Settings</span>
                        </a>
                        </li>
                        <li>
                        <hr class="dropdown-divider"/>
                        </li>

                        <li>
                        <a class="dropdown-item d-flex align-items-center" href="pages-faq.html">
                            <BsQuestionCircle className='for-logout'/>
                            <span>Need Help?</span>
                        </a>
                        </li>
                        <li>
                        <hr class="dropdown-divider"/>
                        </li>
                        <a class="dropdown-item d-flex align-items-center padding-and-margin-for-notification" href="#">
                            <div>
                            <BsBoxArrowRight className='for-logout'/>
                            <span onClick={onLogoutHandler}>Sign Out</span>
                            </div>
                        </a>
                        </NavDropdown.Item>
                        </li>
                    </NavDropdown> */}


                  </ul>
                </nav>

              </>
              :
              <div className="d-flex navmenu">
                {(!currentUser) &&
                  <>
                    <Link to="/login" className="btn btn-sm mx-2 font-weight-bold">Log In</Link>
                    <Link to="/register" className={`${props.activeColor} btn btn-sm`}>Register</Link>
                  </>
                }
              </div>
            }
          </div>
        </Navbar>
      </header>
    </>
  )
}

const mapStateToProps = state => ({
  userProfiles: state.profile.userProfiles,
  activeProfile: state.profile.activeProfile,
  activeColor: state.activetheme.activeColor,
  darkTheme: state.activetheme.darkTheme,
  mainFilter: state.dashboardCommon.mainFilter,
});


export default connect(mapStateToProps, { getProfileDetails, setPrimaryProfile, filterData, userLogout })(Header);