import {useState, useEffect} from 'react'

import Select from 'react-select';
import { connect } from "react-redux";

import { getUser } from '../../utils/UserDetails';
import { filterData } from '../../actions/dashboradCommon';

const Filter = (props) => {
 
  const currentUser = getUser();
  const starClass = (currentUser) ? ((props.mainFilter && props.mainFilter.star_filter) ? "fas fa fa-star fa-1x highlight-star" : "fas fa fa-star fa-1x") : 'fas fa fa-star fa-1x invisible';
  const colourStyles = {
    option: (styles, { isFocused}) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#999999" : null,
    };
    }
  };

  const [categoryFilter, setCategoryFilter] = useState(null);

  useEffect(() => {
    filterSelect()
  },[props.activeParentTab])

  const filterSelect = (data = '') => {
    setCategoryFilter(data)
    setfilterData(data.value, 1)
  }

  const setfilterData = (value = '', isFromFilter = 0) => {
    let filterData = {};
    if(isFromFilter) {
      filterData.category_id = value;
    } else {
      filterData.star_filter = (props.mainFilter.star_filter) ? 0 : 1;
    }
    props.filterData(filterData);
  }

  return (
    <div className="filter-data col-sm-4">
      <div className="d-flex">
        <Select className="category-select"
          onChange={filterSelect}
          options={props.categories}
          value={categoryFilter}
          placeholder = {'Filter'}
          styles={colourStyles}
        />
         <i className={starClass}  onClick={() => setfilterData()} ></i>
          
      
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  categories: state.common.categories,
  mainFilter:  state.dashboardCommon.mainFilter,
})


export default connect(mapStateToProps,{filterData})(Filter);
