import { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Loader from 'react-loader-spinner';
import '../../../styles/training.css';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';

import { getMenus, createMenus, getCourses, deleteCourse, createCourse, changeOrder, addFinance, addFeedback } from '../../../actions/training';
import DAndDRightMenu from '../rightmenu/DAndDRightMenu';
import VerticalMenus from '../verticalmenu/VerticalMenus';
import TrainingModal from '../modals/TrainingModal';
import Language from '../language/Language';
import LearningLevel from '../learninglevel/LearningLevel';
import Category from '../category/Category';
import Currency from '../currency/Currency';
import DeleteModal from '../modals/DeleteModal';
import BatchModal from '../modals/BatchModal';
import useDidMountEffect from '../../DidMount';
import { planMenus, batchMenus, defaultMenus, associateMenus, feedbackMenus, financeMenus } from '../Menus';
import Tooltip from '../tooltip/Tooltip';
import BatchAlertModal from '../modals/BatchAlertModal';
import SessionModal from '../modals/SessionModal';
import SessionGrid from '../modals/SessionGrid';
import CurrencyFormatter from '../currency/CurrencyFormatter';
import AssociatesModal from '../modals/AssociatesModal';
import FinanceModal from '../modals/FinanceModal';


const TrainingTable = (props) => {
  const [listedMenus, setListedMenus] = useState([
    {
      name: 'Course Code',
      type: 'string',
      enabled: 0
    },
    {
      name: "Course Name",
      type: 'string',
      enabled: 0
    },
    {
      name: "Training Category",
      type: 'training_category',
      enabled: 0
    },
    {
      name: "Course / Program Title",
      type: 'string',
      enabled: 0
    },
    {
      name: "Course Short description",
      type: 'string',
      enabled: 0
    },
    {
      name: "Language",
      type: 'language',
      enabled: 0
    },
    {
      name: "Level",
      type: 'level',
      enabled: 0
    },
    {
      name: "Pre-requisite",
      type: 'string',
      enabled: 0
    },
    {
      name: "Activity (Online Training / Class Room Training)",
      type: 'string',
      enabled: 0
    },
    {
      name: "Batch Number",
      type: 'batch_type',
      enabled: 0
    },
    {
      name: "Batch Name",
      type: 'batch_type',
      enabled: 0
    },
    {
      name: "Number of session(s)",
      type: 'number_of_sessions',
      enabled: 0
    },
    {
      name: "Session Name",
      type: 'string',
      enabled: 0
    },
    {
      name: "Organization",
      type: 'string',
      enabled: 0
    },
    {
      name: "Associates / Vendor",
      type: 'string',
      enabled: 0
    },
    {
      name: "Planned Students / Participants",
      type: 'batch_type',
      enabled: 0
    },
    {
      name: "Students / Participants Attended",
      type: 'string',
      enabled: 0
    },
    {
      name: "Program Status",
      type: 'string',
      enabled: 0
    },
    {
      name: "Free / Paid",
      type: 'batch_type',
      enabled: 0
    },
    {
      name: "Currency",
      type: 'currency',
      enabled: 0
    },
    {
      name: "Course Fee",
      type: 'currency_format',
      enabled: 0
    },
    {
      name: "Pending",
      type: 'string',
      enabled: 0
    },
    {
      name: "Payment Status",
      type: 'string',
      enabled: 0
    },
    {
      name: "Feedback Status",
      type: 'string',
      enabled: 0
    },
    {
      name: "Feedback Rating",
      type: 'string',
      enabled: 0
    },
    {
      name: "Upload Feedback Doc Proof",
      type: 'string',
      enabled: 0
    }
  ])
  const [showRefreshAlertModal, setShowRefreshAlertModal] = useState({ showAlert: false, from: '' });
  const [showDeleteModal, setShowDeleteModal] = useState({ showModal: false });
  const [courseData, setCourseData] = useState([]);
  const [userMenus, setUserMenus] = useState([]);
  const [verticalMenu, setVerticalMenu] = useState('default');
  const [isExpanded, setIsExpanded] = useState(true);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [deleteId, setDeleteId] = useState([]);
  const [deleteKeys, setDeleteKeys] = useState([]);
  const [showBatchModal, setShowBatchModal] = useState(false);
  const [isBatchExists, setIsBatchExists] = useState(false);
  const [showBatchAlert, setShowBatchAlert] = useState(false);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [sessionData, setSessionData] = useState([]);
  const [showViewSessions, setShowViewSessions] = useState(false);
  const [sessionViewData, setSessionViewData] = useState([]);
  const [currencyFormatSymbol, setCurrencyFormatSymbol] = useState(null);
  const [showAssociateModal, setShowAssociateModal] = useState(false);
  const [associateData, setAssociateData] = useState([]);
  const [financeData, setFinanceData] = useState([]);
  const [feedbackData, setFeedbackData] = useState([]);
  const [showFinanceModal, setShowFinanceModal] = useState(false);
  const gridWidth = (isExpanded) ? 1040 : 1262;
  // const gridHeight = 490;

  const isNumber = (n) => { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }

  useEffect(() => {
    props.getMenus();
    props.getCourses();
  }, [])

  useEffect(() => {
    if (props.allMenus && props.allMenus.length) {
      let newMenu = [];

      props.allMenus.map((item) => {
        item.user_default_menus.map((child) => {
          newMenu.push(child)
        })
      })

      let menus = highlightMenu(newMenu, listedMenus);
      setUserMenus(menus);
      setVerticalMenu(props.allMenus[0].user_menus_category_name);
    } else {
      let menus = highlightMenu(defaultMenus, listedMenus);
      setUserMenus(menus);
      setVerticalMenu('default');
    }
  }, [props.allMenus])


  useEffect(() => {
    if (props.courses && props.courses.length) {
      generateCourse(props.courses)
    }
  }, [props.courses, userMenus]);

  useDidMountEffect(() => {
    if (props.batch && props.batch.status) {
      let courseData = props.batch.data;
      generateCourse(courseData);
      setShowBatchModal(false);
    }
  }, [props.batch])

  useDidMountEffect(() => {
    if (props.session && props.session.status) {
      let courseData = props.session.data;
      generateCourse(courseData);
      setShowSessionModal(false);
    }
  }, [props.session])

  useDidMountEffect(() => {
    if (props.delCourse && props.delCourse.status) {
      let courseData = props.delCourse.data;
      generateCourse(courseData);
      setShowDeleteModal({ ...showDeleteModal, showModal: false });
    }
  }, [props.delCourse])

  const cloneObject = (obj) => {
    let copy = obj.constructor();
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }

    return copy;
  }

  const generateCourse = (data) => {
    let newCourseData = [];
    //let newModifiedCourseData = [];

    if (data.length > 0) {
      data.map((c, courseKey) => {
        let obj = {};
        userMenus.map((menu, k) => {
          obj['key'] = courseKey;

          switch (menu.name) {
            case 'Course Code':
              obj['Course Code'] = (isNumber(c.course_serial_number)) ? courseKey + 1 : c.course_serial_number;
              break;
            case 'Training Category':
              obj['Training Category'] = (c.course_category) ? c.course_category.category.categories_name : null;
              break;
            case 'Course Name':
              obj['Course Name'] = c.course_name;
              break;
            case 'Course / Program Title':
              obj['Course / Program Title'] = c.course_subtitle;
              break;
            case 'Course Short description':
              obj['Course Short description'] = c.course_shortdescription;
              break;
            case 'Language':
              obj['Language'] = (c.batches[0]) ? c.batches[0].language.teaching_languages_master_name : '';
              break;
            case 'Level':
              obj['Level'] = (c.batches[0]) ? c.batches[0].learning_level.learning_levels_name : '';
              break;
            case 'Associates / Vendor':
              obj['Associates / Vendor'] = (c.associates) ? c.associates.associates_vendor : '';
              break;
            case 'Planned Students / Participants':
              obj['Planned Students / Participants'] = c.course_maxstudentsperbatch;
              break;
            case 'Students / Participants Attended':
              obj['Students / Participants Attended'] = (c.finance) ? c.finance.students_attended : '';
              break;
            case 'Program Status':
              obj['Program Status'] = (c.finance) ? c.finance.program_status : '';
              break;
            case 'Free / Paid':
              obj['Free / Paid'] = (c.finance) ? c.finance.course_type : '';
              break;  
            case 'Pending':
              obj['Pending'] = (c.finance) ? c.finance.pending : '';
              break;
            case 'Payment Status':
              obj['Payment Status'] = (c.finance) ? c.finance.payment_status : '';
              break;
            case 'Feedback Status':
              obj['Feedback Status'] = (c.feedback) ? c.feedback.feedback_status : '';
              break;
            case 'Feedback Rating':
              obj['Feedback Rating'] = (c.feedback) ? c.feedback.feedback_rating : '';
              break;
            case 'Upload Feedback Doc Proof':
              obj['Upload Feedback Doc Proof'] = (c.feedback) ? c.feedback.feedback_doc_proof : '';
              break;
            case 'Organization':
              obj['Organization'] = (c.associates) ? c.associates.organization : '';
              break;
            case 'Activity (Online Training / Class Room Training)':
              obj['Activity (Online Training/Class Room Training)'] = c.course_type;
              break;
            case 'Pre-requisite':
              obj['Pre-requisite'] = c.course_prerequisite;
              break;
            case 'Batch Number':
              obj['Batch Number'] = c.course_batchnumber;
              break;
            case 'Batch Name':
              obj['Batch Name'] = c.course_batchname;
              break;
            case 'Number of session(s)':
              obj['Number of session(s)'] = (c.sessions) ? c.sessions.length : 0;
              break;
            case 'Currency':
              obj['Currency'] = (c.finance) ? c.finance.currency : '';
              break;
            case 'Course Fee':
              obj['Course Fee'] = (c.finance) ? c.finance.course_fee : '';
              break;
            default:
              break;
          }

          obj['id'] = c.course_id;
          newCourseData.push(obj);
        })
      })
    } else {
      newCourseData.push([{}]);
    }


    let uniq = [...new Set(newCourseData)];

    // uniq.map((newCourse) => {
    //   let filteredCourse = data.filter(item => {
    //     return item.course_id === newCourse.id
    //   })

    //   if (filteredCourse[0].batches.length) {
    //     for (let i = 0; i < filteredCourse[0].batches.length; i++) {
    //       let newObject = cloneObject(newCourse);
    //       newObject['Batch Name'] = filteredCourse[0].batches[i].coursebatch_name;
    //       newObject['Batch Number'] = filteredCourse[0].batches[i].course_batch_number;
    //       newObject['Planned students / Participants'] = filteredCourse[0].batches[i].coursebatch_studentsperbatch;
    //       newObject['Free / Paid'] = filteredCourse[0].batches[i].coursebatch_type;
    //       newObject['Currency'] = filteredCourse[0].batches[i].coursebatch_currency;
    //       newObject['Course Fee'] = filteredCourse[0].batches[i].coursebatch_fee;
    //       newObject['duplicate'] = (i === 0) ? false : true;
    //       newModifiedCourseData.push(newObject);
    //     }
    //   } else {
    //     newModifiedCourseData.push(newCourse);
    //   }
    // })

    setCourseData(uniq);
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
 

  const addNewRow = () => {
    gridApi.updateRowData({ add: [{ id: null }], addIndex: 0 });
  }

  const cellEditorSelector = (params) => {
    if (params.colDef.type === 'language') {
      return {
        component: 'languageComponent'
      };
    }

    if (params.colDef.type === 'level') {
      return {
        component: 'levelComponent'
      };
    }

    if (params.colDef.type === 'training_category') {
      return {
        component: 'categoryComponent'
      };
    }

    if (params.colDef.type === 'currency') {
      return {
        component: 'currencyComponent'
      }
    }

    if (params.colDef.type === 'currency_format') {
      return {
        component: 'currencyFormatterComponent',
        params: {
          format: currencyFormatSymbol
        },
      }
    }

    return undefined;
  }

  const toggleMenus = async (menuName, menuKey) => {
  //   console.log(toggleMenus)
    let newUserMenu = [...userMenus];
    let exists = false;
    let index = -1;

    newUserMenu.filter(function (item, key) {
      if (item.name === menuName.name) {
        index = key;
        exists = true
      }
        return;
      })

      if (exists) {
      menuName.enabled = 0;
      newUserMenu.splice(index, 1)
    } else {
      menuName.enabled = 1;
      newUserMenu.push(menuName);
      newUserMenu.sort((a, b) => listedMenus.indexOf(a) - listedMenus.indexOf(b));
    }

    setUserMenus(newUserMenu);

    let menuData = {
      'menus': newUserMenu,
      'category_name': verticalMenu
    }

    props.createMenus(menuData);
  }

  const addGroup = async (category) => {
    let newMenu = [...userMenus];
    let newListed = [...listedMenus];

    if (verticalMenu === category) {
      newMenu = defaultMenus;
      setUserMenus(newMenu)
      setVerticalMenu('default');
    } else {
      switch (category) {
        case 'plans':
          newMenu = highlightMenu(planMenus, listedMenus);
          break;
        case 'batch':
          newMenu = highlightMenu(batchMenus, listedMenus);
          break;
        case 'associates':
          newMenu = highlightMenu(associateMenus, listedMenus);
          break;
        case 'finance':
          newMenu = highlightMenu(financeMenus, listedMenus);
          break;
        case 'feedback':
          newMenu = highlightMenu(feedbackMenus, listedMenus);
          break;
        case 'default':
          newMenu = highlightMenu(defaultMenus, listedMenus);
          break;
        default:
      }

      let nameObj = newMenu.map(item => {
        return item.name
      })

      let refined = newListed.map((i) => {
        if (nameObj.indexOf(i.name) > -1) {
          i.enabled = 1
        } else {
          i.enabled = 0
        }

        return i;
      })

      setListedMenus(refined)
      setUserMenus(newMenu);
      setVerticalMenu(category);
    }
    let menuData = {
      'menus': newMenu,
      'category_name': category
    }

    props.createMenus(menuData);
  }

  const highlightMenu = (groupMenu, listedMenu) => {
    let result = listedMenu.filter(menu1 => groupMenu.some(menu2 => menu1.name === menu2.name));
    let refined = result.map((i) => {
      i.enabled = 1
      return i;
    })

    return refined;
  }

  const refreshDefaultMenu = (category) => {
    let newDefault = [...userMenus];
    let newListed = [...listedMenus]

    switch (category) {
      case 'plans':
        newDefault = userMenus.filter(menu1 => planMenus.some(menu2 => menu1.name === menu2.name));
        break;
      case 'batch':
        newDefault = userMenus.filter(menu1 => batchMenus.some(menu2 => menu1.name === menu2.name));
        break;
      case 'associates':
        newDefault = userMenus.filter(menu1 => associateMenus.some(menu2 => menu1.name === menu2.name));
        break;
      case 'finance':
        newDefault = userMenus.filter(menu1 => financeMenus.some(menu2 => menu1.name === menu2.name));
        break;
      case 'feedback':
        newDefault = userMenus.filter(menu1 => feedbackMenus.some(menu2 => menu1.name === menu2.name));
        break;
      case 'default':
        newDefault = userMenus.filter(menu1 => defaultMenus.some(menu2 => menu1.name === menu2.name));
        break;
      default:
    }

    let nameObj = newDefault.map(item => {
      return item.name
    })

    let refined = newListed.map((i) => {
      if (nameObj.indexOf(i.name) > -1) {
        i.enabled = 1
      } else {
        i.enabled = 0
      }

      return i;
    })

    setListedMenus(refined)
    setUserMenus(newDefault);
  }

  const closeRefreshAlertModal = () => {
    setShowRefreshAlertModal({ ...showRefreshAlertModal, showAlert: false, source: '' });
  }

  const closeDeleteModal = () => {
    setShowDeleteModal({ ...showDeleteModal, showModal: false });
  }

  const submitRefresh = () => {
    refreshDefaultMenu(showRefreshAlertModal.source);
    setShowRefreshAlertModal({ ...showRefreshAlertModal, showAlert: false, source: '' });
  }

  const submitDelete = () => {
    props.deleteCourse(deleteId);
  }

  const checkDeleteData = () => {
    if (deleteId.length > 0 && deleteId[0].id !== null) {
      setShowDeleteModal({ ...showDeleteModal, showModal: true })
    } else {
      toast.error('Please select atleast one course');
    }
  }

  const onSelectionChanged = () => {
    let ids = [];
    let keys = [];
    const selectedRows = gridApi.getSelectedRows();

    selectedRows.map((item) => ids.push({ id: item.id }))
    selectedRows.map((keyData) => keys.push(keyData.key))
    setDeleteId([]);
    setDeleteId(ids);
    setDeleteKeys(keys);
  }

  const cellValueChanged = (event) => {
    // console.log(event)
    if (event.colDef.field === 'Currency') {
      let obj = props.currencies.find(o => o.currency_name === event.newValue);
      setCurrencyFormatSymbol(obj.currency_symbol)
    }

    let completeData = event.data;
    let refinedData = {};

    if (event.data !== null) {
      Object.keys(completeData).forEach(function (key) {
        switch (key) {
          case 'Course Name':
            refinedData['course_name'] = completeData[key]
            break;
          case 'Training Category':
            refinedData['category_course_id'] = completeData[key]
            break;
          case 'Free / Paid':
            refinedData['course_type'] = completeData[key]
            break;
          case 'Course / Program Title':
            refinedData['course_subtitle'] = completeData[key]
            break;
          case 'Course Short description':
            refinedData['course_shortdescription'] = completeData[key]
            break;
          case 'id':
            refinedData['course_id'] = completeData[key]
            break;
          case 'Course Code':
            refinedData['course_serial_number'] = completeData[key]
            break;
          case 'Organization':
            refinedData['organization'] = completeData[key];
            break;
          case 'Associates / Vendor':
            refinedData['associates_vendor'] = completeData[key];
            break;
          case 'Planned Students / Participants':
            refinedData['course_maxstudentsperbatch'] = completeData[key];
            break;
          case 'Students / Participants Attended':
            refinedData['students_attended'] = completeData[key];
            break;
          case 'Program Status':
            refinedData['program_status'] = completeData[key];
            break;
          case 'Currency':
            refinedData['currency'] = completeData[key];
            break;
          case 'Course Fee':
            refinedData['course_fee'] = completeData[key];
            break;
          case 'Pending':
            refinedData['pending'] = completeData[key];
            break;
          case 'Payment Status':
            refinedData['payment_status'] = completeData[key];
            break;
          case 'Feedback Status':
            refinedData['feedback_status'] = completeData[key];
            break;
          case 'Feedback Rating':
            refinedData['feedback_rating'] = completeData[key];
            break;
          case 'Upload Feedback Doc Proof':
            refinedData['feedback_doc_proof'] = completeData[key];
            break;
          case 'Activity (Online Training / Class Room Training)':
            refinedData['course_type_activity'] = completeData[key];
            break;
          case 'Pre-requisite':
            refinedData['course_prerequisite'] = completeData[key];
            break;
          case 'Batch Number':
            refinedData['course_batchnumber'] = completeData[key];
            break;
          case 'Batch Name':
            refinedData['course_batchname'] = completeData[key];
            break;
          default:
        }
      });

      props.createCourse(refinedData)
    }
  }

  const rowDragEnd = (event) => {
    // if a course has batches it is marked as duplicate 
    let obj = event.node.data;
    let check = false;
// console.log(obj)
    if ('duplicate' in obj) {
      if (obj['duplicate'] === false) {
        check = true;
        gridApi.setRowData(courseData);
      } else {
        check = false;
      }
    } else {
      check = false;
    }

    if (event.node.data.id && check === false) {
      let rowData = {
        course_id: event.node.data.id,
        order: event.overIndex
      }
      console.log(rowData)

      props.changeOrder(rowData);
    }
  }

  const closeBatchModal = () => {
    setShowBatchModal(false);
  }

  const defaultColDef = useMemo(() => {
    return {
      tooltipComponent: Tooltip
    };
  }, []);

  const enableBatchModal = () => {
    // delete id is used as course id here
    if (deleteId !== null && deleteId.length === 1) {
      setShowBatchModal(true);
    } else {
      toast.error('Please select atleast one course')
    }
  }

  const closeBatchAlertModal = () => {
    setShowBatchAlert(false);
  }

  const closeSessionModal = () => {
    setSessionData([]);
    setShowSessionModal(false);
  }

  const closeSessionViewModal = () => {
    setShowViewSessions(false);
  }

  const enableSessionModal = () => {
    if (deleteId !== null && deleteId.length === 1) {
      setSessionData(getFilteredCourse());
      setShowSessionModal(true);
    } else {
      toast.error('Please select atleast one course');
    }
  }

  const cellClicked = (event) => {
    let id = event.data.id;
   
    if (event.colDef.type === 'number_of_sessions') {
      let filteredCourse = props.courses.filter((item) => {
        return item.course_id === id
      })

      setSessionViewData(filteredCourse);
      setShowViewSessions(true);
    }

    // if (event.colDef.field === 'Currency') {
    // console.log(event)
    //   let obj = props.currencies.find(o => o.currency_name === event.Value);
    //   setCurrencyFormatSymbol(obj.currency_symbol)
    // }

    let completeData = event.data;
    // console.log(completeData)
    let refinedData = {};

    if (event.data === null) {
      Object.keys(completeData).forEach(function (key) {
        switch (key) {
          case 'id':
            refinedData['course_id'] = completeData[key]
            break;
          case 'Students / Participants Attended':
            refinedData['students_attended'] = completeData[key];
            break;
          case 'Program Status':
            refinedData['program_status'] = completeData[key];
            break;
          case 'Free / Paid':
            refinedData['course_type'] = completeData[key]
            break;
          case 'Currency':
            refinedData['currency'] = completeData[key];
            break;
          case 'Course Fee':
            refinedData['course_fee'] = completeData[key];
            break;
          case 'Pending':
            refinedData['pending'] = completeData[key];
            break;
          case 'Payment Status':
            refinedData['payment_status'] = completeData[key];
            break;
          case 'Feedback Status':
            refinedData['feedback_status'] = completeData[key];
            break;
          case 'Feedback Rating':
            refinedData['feedback_rating'] = completeData[key];
            break;
          case 'Upload Feedback Doc Proof':
            refinedData['feedback_doc_proof'] = completeData[key];
            break;  
          default:
        }
      });

      props.addCourse(refinedData)
    }
  }

  // const courseRowDrag = (params) => {
  //   let obj = params.data;
  //   let check = false;

  //   if ('duplicate' in obj) {
  //     check = false;
  //   } else {
  //     check = true;
  //   }

  //   return check;
  // }

  // const checkboxStatus = (params) => {
  //   let obj = params.data;
  //   let check = false;
  //   if ('duplicate' in obj) {
  //     if (obj['duplicate'] === false) {
  //       check = true;
  //     } else {
  //       check = false;
  //     }
  //   } else {
  //     check = true;
  //   }

  //   return check;
  // }

  const closeAssociateModal = () => {
    setAssociateData([]);
    setShowAssociateModal(false);
  }

  const getFilteredCourse = () => {
    let filteredCourse = props.courses.filter((item) => {
      return item.course_id === deleteId[0].id
    })

    return filteredCourse;
  }

  const enableAssociateModal = () => {
    if (deleteId !== null && deleteId.length === 1) {
      setAssociateData(getFilteredCourse());
      setShowAssociateModal(true);
    } else {
      toast.error('Please select atleast one course');
    }
  }

  const enableFinanceModal = () => {
    if (deleteId !== null && deleteId.length === 1) {
      setFinanceData(getFilteredCourse());
      setShowFinanceModal(true);
    } else {
      toast.error('Please select atleast one course');
    }
  }

  const closeFinanceModal = () => {
    setFinanceData([]);
    setShowFinanceModal(false);
  }

  return (
    <>
    
      <div className='row pt-3 pb-3' style={{ backgroundColor: '#fff' }}>
        {(userMenus.length) ?
          <>
          <div className='edit-function'>
                <FontAwesomeIcon icon={faPlus} className='icon-color mx-2' onClick={() => addNewRow()} role="button" />
                <FontAwesomeIcon icon={faTrash} className='icon-color mx-2' role="button" onClick={() => checkDeleteData()} />
                <Button variant='outline-secondary' className={`mx-2 btn-modified ${props.activeColor}-btn :hover`} size='sm' onClick={() => enableBatchModal()}>Add Batch</Button>
                <Button variant='outline-secondary' className='mx-2 btn-modified' size='sm' onClick={() => enableSessionModal()}>Add Session</Button>
                <Button variant='outline-secondary' className='mx-2 btn-modified' size='sm' onClick={() => enableAssociateModal()}>Assign Associates</Button>
              </div>
            <div className='col-md-9 grid-section'>
              {/* AG GRID SECTION */}
              <div className="ag-theme-alpine grid-section" style={{ minWidth: 100 }}>
                <AgGridReact
                  frameworkComponents={{
                    languageComponent: Language,
                    levelComponent: LearningLevel,
                    categoryComponent: Category,
                    currencyComponent: Currency,
                    currencyFormatterComponent: CurrencyFormatter
                  }}
                  onGridReady={onGridReady}
                  rowData={courseData}
                  onSelectionChanged={onSelectionChanged}
                  rowSelection={'multiple'}
                  rowDragManaged={true}
                  animateRows={true}
                  onRowDragEnd={(e) => rowDragEnd(e)}>

                  <AgGridColumn field="" checkboxSelection={true} width={70} columnDrag={true} rowDrag={true} pinned={true} lockPinned={true} />
                  {userMenus.map((field) => {
                    return <AgGridColumn
                      field={field.name}
                      type={field.type}
                      width={145}
                      sortable={true}
                      resizable={true}
                      filter={true}
                      editable={true}
                      tooltipField={field.name}
                      cellEditorSelector={cellEditorSelector}
                      onCellValueChanged={cellValueChanged}
                      onCellClicked={cellClicked}
                      defaultColDef={defaultColDef}
                      tooltipShowDelay={0}
                      tooltipHideDelay={2000}
                      headerTooltip={field.name}
                      pinned={(field.name === 'Course Code' || field.name === 'Course Name') ? true : false}
                      lockPinned={true}
                      lockPosition={true}>
                    </AgGridColumn>
                  })}
                </AgGridReact>
              </div>
            </div>

            <div className="col-md-2 m-1">
              <div className='row'>
                <div className="col-sm-2">
                  <DAndDRightMenu
                    userMenus={userMenus}
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                    listedMenus={listedMenus}
                    setListedMenus={setListedMenus}
                     toggleMenus={toggleMenus}
                    verticalMenu={verticalMenu}
                    isBatchExists={isBatchExists} />
                </div>
                <div className={`col-sm-1 vertical-col ` + ((isExpanded) ? 'apply-z' : 'apply-z-again')}>
                  <VerticalMenus
                    isExpanded={isExpanded}
                    verticalMenu={verticalMenu}
                    addGroup={addGroup}
                    setShowRefreshAlertModal={setShowRefreshAlertModal}
                    isBatchExists={isBatchExists} />
                </div>
              </div>
            </div>
          </> : <Loader type="ThreeDots" color="#00BFFF" height={250} width={50} className="loader" />
        }
      </div>


      <TrainingModal
        closeRefreshAlertModal={closeRefreshAlertModal}
        showRefreshAlertModal={showRefreshAlertModal}
        submitRefresh={submitRefresh} />
      <DeleteModal
        closeDeleteModal={closeDeleteModal}
        showDeleteModal={showDeleteModal}
        submitDelete={submitDelete} />
      <BatchModal
        closeBatchModal={closeBatchModal}
        showBatchModal={showBatchModal}
        courseId={deleteId} />
      <BatchAlertModal
        showBatchAlert={showBatchAlert}
        closeBatchAlert={closeBatchAlertModal} />
      <SessionModal
        showSessionModal={showSessionModal}
        closeSessionModal={closeSessionModal}
        sessionData={sessionData}
        courseId={deleteId} />
      <SessionGrid
        showViewSessions={showViewSessions}
        closeSessionViewModal={closeSessionViewModal}
        viewData={sessionViewData}
      />
      <AssociatesModal
        showAssociateModal={showAssociateModal}
        closeAssociateModal={closeAssociateModal}
        associateData={associateData}
        courseId={deleteId}
      />
      <FinanceModal
        showFinanceModal={showFinanceModal}
        closeFinanceModal={closeFinanceModal}
        financeData={financeData}
        courseId={deleteId} />
    </>
  )
}

TrainingTable.propTypes = {
  allMenus: PropTypes.array,
  courses: PropTypes.array,
  darkTheme: PropTypes.bool
};

const mapStateToProps = (state) => ({
  allMenus: state.training.menus,
  activeColor: state.activetheme.activeColor,
  darkTheme: state.activetheme.darkTheme,
  courses: state.training.userCourses,
  course: state.training.createCourse,
  finance: state.training.addFinance,
  feedback: state.training.addFeedback,
  batch: state.training.batch,
  session: state.training.session,
  delCourse: state.training.delCourse,
  currencies: state.training.currencies
});

export default connect(mapStateToProps, { getMenus, getCourses, createMenus, deleteCourse, createCourse, changeOrder, addFinance, addFeedback })(TrainingTable);