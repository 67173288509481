import {useState, useEffect} from "react"

import { connect } from "react-redux";
import { RatingView } from 'react-simple-star-rating';
import { NavDropdown } from 'react-bootstrap'

import CommonService from '../../../services/common.service';
import { getUser } from '../../../utils/UserDetails';
import { onFavouriteChange, setDeletePostData} from "../../../actions/dashboradCommon";

const PostCardHeader = (props) => {
    const [post, setPost] = useState();
    const [starFlag, setStarFlag] = useState(0);
    const [showStar, setshowStar] = useState(1);
    const currentUser = getUser();

    useEffect(() => {
      setPost(props.post)
      if(props.post.qboard_id) {
        setStarFlag((props.post.favourite && props.post.favourite.qboard_id === props.post.qboard_id) ? 1 : 0)
      } else if(props.post.requirements_job_id) {
        setStarFlag((props.post.favourite && props.post.favourite.requirements_job_id === props.post.requirements_job_id) ? 1 : 0)
      } else {
        setStarFlag((props.post.favourite && props.post.favourite.requirements_training_id === props.post.requirements_training_id) ? 1 : 0)
      }
      
    }, [props.post])

    useEffect(() => {
        if(props.post?.qboard_parent_id) {
            setshowStar(0)
        }
    },[])

    const editPost = (obj) => {
        props.openModal(obj)
    }

    const onFavouriteChangeHandler = (post, favourite_posts_id = null) => {
        let favouriteData = {};

        if(post.qboard_id) {
          favouriteData.qboard_id = post.qboard_id
        }

        if(post.requirements_job_id) {
          favouriteData.requirements_job_id =  post.requirements_job_id
        }

        if(post.requirements_training_id) {
          favouriteData.requirements_training_id =  post.requirements_training_id
        }

        if (favourite_posts_id) {
          favouriteData.favourite_posts_id = favourite_posts_id
        }

        props.onFavouriteChange(favouriteData)
    }

    const deletePost = (post) => {
        let deleteContent = {...props.deletePostData}
        deleteContent.deleteModalOpen = true;
        deleteContent.qboard_id = "";
        deleteContent.qboard_parent_id = "";
        deleteContent.requirements_job_id = "";
        deleteContent.requirements_training_id = "";

        if(post.qboard_id) {
            deleteContent.qboard_id = post.qboard_id;
            deleteContent.qboard_parent_id = post.qboard_parent_id;
        } else if (post.requirements_job_id) {
            deleteContent.requirements_job_id = post.requirements_job_id;
        } else {
            deleteContent.requirements_training_id = post.requirements_training_id;
        }
        props.setDeletePostData(deleteContent)
    }

    return (
        <>
        { (post) ?
            <div className="card-header">
                <div className="row userquery-header">
                    <div className="col-4 col-md-3 d-flex text-break">
                        <img className="rounded-circle profile-icon d-block align-self-center ms-1" 
                            src={(post.user_profile_image_url) ? post.user_profile_image_url : process.env.PUBLIC_URL + '/images/icons/profile1.jpg'}
                            onError={(e)=>{e.target.onerror = null; e.target.src = process.env.PUBLIC_URL + '/images/icons/profile1.jpg'}}
                        />
                        <h6 className="user-name align-self-center" title={post.user}>{post.user}</h6>
                    </div>
                    <div className="col-4 col-md-2 text-nowrap align-self-center">
                        <RatingView ratingValue={0}
                            stars={5}
                            size={13}
                        />
                    </div>
                    <div className="col-4 col-md-2 text-sm-end text-md-center align-self-center prfl-nm-overflow font-size-12px" title={ post.qboard_user_profile_name }>
                        { post.qboard_user_profile_name }
                        { post.requirement_user_profile_name }
                    </div>
                    <div className="col-4 col-md-1 text-sm-start text-md-center align-self-center font-size-12px prfl-nm-overflow" title={post.category_name}>
                        {	(post.category_name) ? post.category_name : null }
                    </div>
                    <div className={`col-4 col-md-2 text-center align-self-center font-size-12px`}>
                                { CommonService.formDate(post.created_at) }
                    </div>
                    <div className="col-4 col-md-2 align-self-center ">
                        
                    { (currentUser) &&
                        <>
                        <NavDropdown className="float-end show-more-view font-size-12px" title="..." >
                            <NavDropdown.Item className="pt-3">
                                    <i className="fas fa fa-warning warning pt-3 font-size-12px"></i>
                            </NavDropdown.Item>
                            
                            {(post.isMyPost) ? 
                                <NavDropdown.Item>
                                    <i className="fas fa fa-pencil" onClick={ ()=> editPost(post) }></i>
                                </NavDropdown.Item>
                                : null
                            }
                        
                            <NavDropdown.Item>
                                <i className="fas fa fa-close"  onClick={ ()=> deletePost(post) }></i>
                            </NavDropdown.Item>
                        </NavDropdown>
                        { (showStar) ?
                                <i className={"fas fa fa-star " + ( starFlag ? 'highlight-star' : 'dull-star')} 
                                onClick={ () => onFavouriteChangeHandler(post, (starFlag) ? post.favourite.favourite_posts_id : null) } ></i>
                          : null
                        }


                    </>
                    }
                    </div>
                </div>
            </div>
        : null 
        }
        </>
    )
}

const mapStateToProps = state => ({
    activeProfile: state.profile.activeProfile,
    activeColor: state.activetheme.activeColor,
    deletePostData: state.dashboardCommon.deletePostData
});

export default connect(mapStateToProps, {onFavouriteChange, setDeletePostData})(PostCardHeader);