import {useEffect, useState} from 'react'

import { connect } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from 'react-router-dom';

import {setActiveColor, setDarkTheme} from '../actions/colorTheme'
import commonService from '../services/common.service';
import profileService from '../services/profile.service';
import { getUser } from '../utils/UserDetails';
import { colors } from '../constants/DefaultValues';

const ThemeColor = (props) => {

  const currentUser = getUser()
  const location = useLocation()
  const themeColors = colors;
  const pageDetails = {
      setColor: '',
      setDark: '',
  }
  const [pageData, setPageData] = useState({});
  
  useEffect(() => {
      setPageData(pageDetails);
      let windowview = location.pathname.split("/").pop();
      if (windowview && windowview === 'profile') {
              setPageData({...pageData,setColor:'user_profile_page_color', setDark:'user_profile_page_dark'});
          } else {
              setPageData({...pageData,setColor:'qboard_page_color', setDark:'qboard_page_dark'});
          }
  },[])

  useEffect(() => {
    document.body.classList.remove('is-dark-theme');
      if(props.darkTheme) 
    document.body.classList.add('is-dark-theme');
  },[props.darkTheme])

  const setUserProfile = (column, value) => {

      if(currentUser) {
          let windowview = location.pathname.split("/").pop();
          if (windowview && windowview === 'profile') {
              profileService.setProfileTheme({
                  'profile_id': props.activeProfile.user_profile_id,
                  'column': column,
                  'value': value,
              });
          } else {
              commonService.setUserTheme({
                  'column': column,
                  'value': value,
              });
          }
      }
  }
  
  return (
    <ul className={`list-inline list-style-type color-theme mb-0 primary-bg-color colour-changes` + (props.windowView ? " invisible" : "")}>
        {themeColors.map((color, index) => (
          <li key={index} onClick={() => {props.setActiveColor(color); setUserProfile(pageData.setColor, color)}} className={`${color}` + (color === props.activeColor ? '-font active-color' : '')}></li>
          ))
        }
    
        {(props.darkTheme) ?
          <li className="d-theme"  onClick={()=>{ props.setDarkTheme(0); setUserProfile(pageData.setDark, 0)}}><FontAwesomeIcon icon={faSun} /></li>		
            : <li className="d-theme" onClick={()=>{ props.setDarkTheme(1); setUserProfile(pageData.setDark, 1)}}><FontAwesomeIcon icon={faMoon} /></li> 	
        }
    </ul>
  )
}

const mapStateToProps = state => ({
  activeProfile: state.profile.activeProfile,
    activeColor: state.activetheme.activeColor,
    darkTheme: state.activetheme.darkTheme,
});



export default connect(mapStateToProps, {setActiveColor, setDarkTheme})(ThemeColor);

