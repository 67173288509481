import {useState, useEffect} from 'react'

import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import { useLocation, useHistory } from 'react-router-dom';

import Login from './Login';
import Register from './Register';
import CarouselData from './CarouselData';
import CommonService from '../../services/common.service';
import { getUser } from '../../utils/UserDetails';
import { caroseldata, breakPoints } from '../../constants/DefaultValues';


const AuthForm = () => {

  const history = useHistory();
	const location = useLocation()
  const currentUser = getUser();

  const [carouselDetails, setCarouselDetails] = useState([]);
  const [viewLogin, setViewLogin] = useState(true);
  const [masterRoles, setMasterRoles] = useState([]);
  const [activeRole, setActiveRole] = useState(null);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentText, setCurrentText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const words = ["Company","Recruiter", "Student","Trainer"]; // Array of words to display
  const typingSpeed = 250; // Adjust typing speed as needed
  

  useEffect(() => {
    let windowview = location.pathname.split("/").pop();

    if(currentUser) {
      history.push("/home")
    }

    if (windowview && windowview === 'register') {
      setViewLogin(false)
    }

    getRoles();
    setCarouselDetails(caroseldata);

  },[])

    useEffect(() => {
    const updateTypewriter = () => {
      const currentWord = words[currentWordIndex];
      let nextText = currentText;
      let delta = typingSpeed;

      if (isDeleting) {
        nextText = currentWord.substring(0, nextText.length - 1);
      } else {
        nextText = currentWord.substring(0, nextText.length + 1);
      }

      if (!isDeleting && nextText === currentWord) {
        setIsDeleting(true);
        delta = 1000; // Pause after completing typing
      } else if (isDeleting && nextText === '') {
        setIsDeleting(false);
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        delta = 1000; // Pause before next typing
      }

      setCurrentText(nextText);
    };

    const typingTimer = setTimeout(updateTypewriter, typingSpeed);

    return () => clearTimeout(typingTimer);
  }, [currentText, currentWordIndex, isDeleting, words, typingSpeed]);

  const getRoles = async() => {
    let resultData = await CommonService.getRoles();

    if(resultData && resultData.status === 2000) {
      setMasterRoles(resultData.data)
    }
  }

  return (
    <div className="auth container">
      <div className="row login ">
        <div className="col-md-8 login-left-container">
          <div className="row">
              <div className="description-container col-md-6">
                <div className="tjunction-logo">
                  <Link to="/">
                    <img src={process.env.PUBLIC_URL + '/images/logo.png'} /> 
                  </Link>
                </div>
                <div className="tjunction-description">
                  <h2 className="tjunction-description-heading">Your platform for <span data-period="2000" data-type={JSON.stringify(words)}>{currentText} </span></h2>
                  <div className="tjunction-description-content">
                      Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. 
                  </div>
                </div>
              </div>
              <div className="image-container col-md-6">
                <img src={process.env.PUBLIC_URL + '/images/icons/login2-icon.svg'}  className='login-cover-image'/>
              </div>
          </div>

          <div className='container testimonial'>
            <div className="row">
              <div className="col">
                  <h4 className="mb-3">Our Testimonials</h4>
              </div>
            </div>  
            <Carousel breakPoints={breakPoints} enableAutoPlay autoPlaySpeed={1500}>
              {carouselDetails.map((item, key) => {
                return (
                  <CarouselData 
                    item = {item}
                    key = {key}
                  />
                )
              })}
        
            </Carousel>
          </div>
        </div>
        <div className="col-md-4 auth-container">
          {
            (viewLogin) ? <Login 
              setViewLogin={setViewLogin} 
              masterRoles={masterRoles} 
              activeRole={activeRole}
              setMasterRoles={setMasterRoles}
             /> : <Register 
                setViewLogin={setViewLogin}
                masterRoles={masterRoles}
                activeRole={activeRole}
                setMasterRoles={setMasterRoles}
            />
          }
          
        </div>
      </div>
    </div>
  )
}

export default AuthForm;
