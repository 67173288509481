//setActivecolor 
export const setActiveColor = (activeColor) => {
	localStorage.setItem('colorTheme', activeColor);
    return {
		type: "SET_ACTIVE_COLOR",
		activeColor: activeColor,
	}
}

//setDarkTheme
export const setDarkTheme = (isTrue) => {
	return {
		type: "SET_ACTIVE_THEME",
		darkTheme: isTrue,
	}
}