import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';

import Loader from 'react-loader-spinner';

import { getLanguage } from '../../../actions/training';

const Language = forwardRef((props, ref) => {
  const refInput = useRef(null);
  const [languages, setLanguages] = useState([]);
  const [value, setValue] = useState(null);

  useEffect(() => {
    props.getLanguage();
  }, [])

  useEffect(() => {
    if (props.allLanguages && props.allLanguages.length) {
      setLanguages(props.allLanguages);
    }
  }, [props.allLanguages])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },

      myCustomFunction() {
        return {
          rowIndex: props.rowIndex,
          colId: props.column.getId()
        };
      }
    };
  });

  return (
    <>
      {(languages.length) ?
        <select className="form-control" ref={refInput} value={value} onChange={event => setValue(event.target.value)} >
          {
            languages.map((lang) => (
              <option value={lang.language_name}>{lang.language_name}</option>
            ))
          }
        </select> : <Loader type="TailSpin" color="#00BFFF" height={25} width={25} className="loader" />
      }
    </>
  )
});

const mapStateToProps = (state) => ({
  allLanguages: state.training.languages
});

export default connect(mapStateToProps, { getLanguage }, null, { forwardRef: true })(Language);
