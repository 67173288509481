import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';

import Loader from 'react-loader-spinner';

import { getLevels } from '../../../actions/training';

const LearningLevel = forwardRef((props, ref) => {
  const [levels, setLevels] = useState([]);
  const refInput = useRef(null);
  const [value, setValue] = useState(null);

  useEffect(() => {
    props.getLevels();
  }, [])

  useEffect(() => {
    if (props.allLevels && props.allLevels.length) {
      setLevels(props.allLevels);
    }
  }, [props.allLevels])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },

      myCustomFunction() {
        return {
          rowIndex: props.rowIndex,
          colId: props.column.getId()
        };
      }
    };
  });

  return (
    <>
      {(levels.length) ?
        <select className="form-control" ref={refInput} value={value} onChange={event => setValue(event.target.value)}>
          {levels.map((level) => (
            <option value={level.learning_levels_name}>{level.learning_levels_name}</option>
          ))}
        </select> : <Loader type="TailSpin" color="#00BFFF" height={25} width={25} className="loader" />
      }
    </>
  )
});

const mapStateToProps = (state) => ({
  allLevels: state.training.levels
});

export default connect(mapStateToProps, { getLevels }, null, { forwardRef: true })(LearningLevel);