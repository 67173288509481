import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose
} from 'redux';
import thunk from "redux-thunk";

import profileReducer from '../reducers/profile';
import trainingReducer from '../reducers/training';
import colorReducer from '../reducers/colorTheme';
import qboardReducer from '../reducers/qboard';
import commonReducer from '../reducers/common';
import authReducer from '../reducers/auth';
import jobReducer from '../reducers/job';
import dashboardCommonReducer from '../reducers/dashboardCommon';
import reqTrainingReducer from '../reducers/reqTraining'
import customUserLinkReducer from '../reducers/customUserLink';
import zoomMeetingReducer from '../reducers/zoomMeeting';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      profile: profileReducer,
      activetheme: colorReducer,
      training: trainingReducer,
      qboard: qboardReducer,
      common: commonReducer,
      auth: authReducer,
      job: jobReducer,
      dashboardCommon: dashboardCommonReducer,
      reqTraining: reqTrainingReducer,
      customUserLink: customUserLinkReducer,
      zoomMeeting: zoomMeetingReducer
    }),
    composeEnhancers(applyMiddleware(thunk))
  );
  return store;
};