import CommonService from "../services/common.service";
import {
  SET_CUSTOM_USER_LINK,
  CUSTOM_USER_LINK_NOTIFICATION,
  CUSTOM_USER_LINK_MODAL,
  INSERT_CUSTOM_USER_LINK,
  UPDATE_CUSTOM_USER_LINK,
  DELETE_CUSTOM_USER_LINK
} from "./types";

export const getCustomUserLink = () => async(dispatch) => {

  try {
    const resultData = await CommonService.getUserUrl();

    if(resultData?.status && resultData.status === true) {
      dispatch({type:SET_CUSTOM_USER_LINK , payload: resultData?.data ? resultData.data : []})
    } 
  } catch(err) {
    dispatch(setCustomUserLinkNotification({message:"Please Try Again", isSuccess: 0}))
  }
}

export const setCustomUserLinkModal = (data) => {
  return {
    type: CUSTOM_USER_LINK_MODAL,
    payload: data,
  };
}

export const setCustomUserLinkNotification = (message={}) => {
  return {
      type: CUSTOM_USER_LINK_NOTIFICATION,
      payload: message,
  };
}

export const updateCustomLink = (formRequestData, resetForm, setSubmitting, closeModal) =>  async(dispatch) => {
  try {
  const resultData = await CommonService.saveUserUrl(formRequestData);

  if(resultData && resultData.status === true) {

    if(formRequestData.user_link_id) {
      dispatch(updateData(resultData.data));
      dispatch(setCustomUserLinkNotification({message:"Updated", isSuccess: 1}))
    } else {
      dispatch(insertData(resultData.data));
      dispatch(setCustomUserLinkNotification({message:"Added", isSuccess: 1}))
    }
    closeModal();
    resetForm();
  } else {
    dispatch(setCustomUserLinkNotification({message:"Please Try Again", isSuccess: 0}))
  }
   
  setSubmitting(false);
  } catch(err) {
    dispatch(setCustomUserLinkNotification({message:"Please Try Again", isSuccess: 0}))
    setSubmitting(false);
  }
} 

export const deleteCustomUserLink = (data, setConfirmationOpen) => async(dispatch) => {
  try {
    const resultData =  await CommonService.deleteUserUrl(data.user_link_id);
    if(resultData && resultData.status === true) {
      dispatch(deleteData(data))
      setConfirmationOpen(false)
      dispatch(setCustomUserLinkNotification({message:"Deleted", isSuccess: 1}))
    } else {
      dispatch(setCustomUserLinkNotification({message:"Please Try Again", isSuccess: 0}))
    }
  } catch(err) {
    dispatch(setCustomUserLinkNotification({message:"Please Try Again", isSuccess: 0}))
  }
}

const insertData = (data) => {
  return {
    type: INSERT_CUSTOM_USER_LINK,
    payload: data
  }
}

const updateData = (data) => {
  return {
    type: UPDATE_CUSTOM_USER_LINK,
    payload: data
  }
}

const deleteData = (data) => {
  return {
    type: DELETE_CUSTOM_USER_LINK,
    payload: data
  }
}
