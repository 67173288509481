import React from 'react';

import { connect } from "react-redux";

import CommonService from '../../../../services/common.service';

const JobDetails = (props) => {

  return (
    <>
      <div className='row requirement-preview px-2'>
          <div className='col-6'>
            <div className="form-group row">
              <label  className="col-sm-4 col-form-label">Job Requirement: </label>
              <div className="col-sm-8">
                {(props.post.requirements_job_own_post) ? "Others" : "My Requirement"}
              </div>
            </div>

            {(props.post.requirements_job_industry_id) ?
              <div className="form-group row">
                <label  className="col-sm-4 col-form-label">Job Industry: </label>
                <div className="col-sm-8">
                { CommonService.filterArray(props.industry, 'industry_id', parseInt(props.post.requirements_job_industry_id)).pop().industry_name }
                </div>
              </div>
              : null 
            }
           {(props.post.requirements_no_of_participants) ?
            <div className="form-group row">
              <label  className="col-sm-4 col-form-label">No. of Requirement: </label>
              <div className="col-sm-8">
                 { props.post.requirements_no_of_participants }
              </div>
            </div>
            : null 
          }
          {(props.post.requirements_job_fulltime) ?
            <div className="form-group row">
              <label  className="col-sm-4 col-form-label">Part Time / Full time: </label>
              <div className="col-sm-8">
              { parseInt(props.post.requirements_job_fulltime) ? "FTE" : "PTE" }
              </div>
            </div>
            : null 
          }
            {(props.post.job_duration_hours) ?
            <div className="form-group row">
              <label  className="col-sm-4 col-form-label">Working Hours: </label>
              <div className="col-sm-8">
                { props.post.job_duration_hours } Hrs/Day
              </div>
            </div>
            : null 
          }
          {(props.post.requirements_job_duration) ?
            <div className="form-group row">
              <label  className="col-sm-4 col-form-label">Job Duration: </label>
              <div className="col-sm-8">
              { props.post.requirements_job_duration } { props.post.requirements_job_duration_periods }
              </div>
            </div>
            : null 
          }
          {(props.post.requirements_company_details) ?
            <div className="form-group row">
              <label  className="col-sm-4 col-form-label">Company / Institution Name: </label>
              <div className="col-sm-8">
               { props.post.requirements_company_details }
              </div>
            </div>
            : null 
          }
          {(props.post.requirements_contact_person) ?
            <div className="form-group row">
              <label  className="col-sm-4 col-form-label">Contact Person: </label>
              <div className="col-sm-8">
                { props.post.requirements_contact_person }
              </div>
            </div>
             : null 
            }
            {(props.post?.global_job?.requirements_contact_number) ?
             <div className="form-group row">
              <label  className="col-sm-4 col-form-label">Contact Number: </label>
              <div className="col-sm-8">
              {props.post.global_job.requirements_contact_number }
              </div>
            </div>
             : null 
            }

          </div>

          <div className='col-6'>

            {(props.post?.require_category?.requirements_trainer_job_category_name) ?
             <div className="form-group row">
              <label  className="col-sm-4 col-form-label">Role: </label>
              <div className="col-sm-8">
                {props.post.require_category.requirements_trainer_job_category_name}
              </div>
            </div>
            : null 
            }
             {(props.post?.require_specialization?.job_specialization_name) ?
            <div className="form-group row">
              <label  className="col-sm-4 col-form-label">Specialization: </label>
              <div className="col-sm-8">
              { props.post.require_specialization.job_specialization_name }
              </div>
            </div>
             : null 
            }

            {(props.post.job_softskill) ?
            <div className="form-group row">
              <label  className="col-sm-4 col-form-label">Skills: </label>
              <div className="col-sm-8">
              </div>
            </div>
            : null 
            }

            {(props.post.requirements_job_salary_currency) ?
              <div className="form-group row">
                <label  className="col-sm-4 col-form-label">Salary: </label>
                <div className="col-sm-8">
                  {CommonService.filterArray(props.currencies, 'currency_code', props.post.requirements_job_salary_currency).pop().currency_symbol} {props.post.requirements_job_salary} / {props.post.requirements_job_salary_periods}
                </div>
              </div>
             : null 
             }

            {(props.post.requirements_job_experience) ?
              <div className="form-group row">
                <label  className="col-sm-4 col-form-label">Job Experience: </label>
                <div className="col-sm-8">
                { props.post.requirements_job_experience } {  props.post.requirements_job_experience_periods }
                </div>
              </div>
             : null 
            }

            {(props.post?.global_job?.requirements_email) ?
              <div className="form-group row">
                <label  className="col-sm-4 col-form-label">Email: </label>
                <div className="col-sm-8">
                { props.post.global_job.requirements_email }
                </div>
              </div>
              : null 
            }

            {(props.post?.global_job?.requirements_whatsapp_number) ?
              <div className="form-group row">
                <label  className="col-sm-4 col-form-label">WhatsApp Number: </label>
                <div className="col-sm-8">
                { props.post.global_job.requirements_whatsapp_number }
                </div>
              </div>
              : null 
            }
            
            {(props.post.requirements_job_location) ?
              <div className="form-group row">
                <label  className="col-sm-4 col-form-label">Location: </label>
                <div className="col-sm-8">
                  { props.post.requirements_job_location }
                </div>
              </div>
            : null 
            }

            {(props.post.requirements_job_accomodation) ?
              <div className="form-group row">
                <label  className="col-sm-4 col-form-label">Accomdation Provided: </label>
                <div className="col-sm-8">
                  {(props.post.requirements_job_accomodation) ? "Yes" : "No" }
                </div>
              </div>
              : null 
            }

          </div>
        </div>
    </>
  );
};

const mapStateToProps = state => ({
  jobCategory: state.job.jobCategory,
  jobSpecialization: state.job.jobSpecialization,
  industry: state.common.industry,
  timePeriods: state.common.timePeriods,
  currencies: state.common.currencies,
})

export default connect (mapStateToProps)(JobDetails);
