import { useState } from 'react';
import { connect } from 'react-redux';

import { Modal } from 'react-bootstrap';
// import CurrencyFormat from 'react-currency-format';
// import renderHTML from 'react-render-html';

import { addBatch } from '../../../actions/training';
import useDidMountEffect from '../../DidMount';
import Spinner from '../../spinner/CommanSpinner'

const BatchModal = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [batchCount, setBatchCount] = useState(null);

  useDidMountEffect(() => {
    setShowSpinner(false);
  }, [props.batch])

  const submitBatch = (e) => {
    setShowSpinner(true);
    let batchData = {
      courseId: props.courseId[0].id,
      batchCount: batchCount
    }

    props.addBatch(batchData)
  }

  return (
    <>
      <Modal show={props.showBatchModal} onHide={props.closeBatchModal} backdrop="static" keyboard={false} centered className="tj-modal" size="lg">
        <Modal.Header closeButton className="d-flex">
          <Modal.Title className="flex-grow-1">Add Batch</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <>
            <div className='m-2'>
              <div className='row'>
                <div className='col-md-3'>
                  <label>Number of batches</label></div>
                <div className='col-md-2'>
                  <input type='number' className='form-control form-control-sm' onChange={(e) => setBatchCount(e.target.value)} />
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-outline-primary mx-2 btn-sm" onClick={(e) => submitBatch(e)}>{(showSpinner) ? <Spinner /> : 'Submit'}</button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  batch: state.training.batch
})

export default connect(mapStateToProps, { addBatch })(BatchModal);