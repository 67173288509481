import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { Modal, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';

import useDidMountEffect from '../../DidMount';
import Spinner from '../../spinner/CommanSpinner';
import { addSession, deleteSession } from '../../../actions/training';

const SessionModal = (props) => {
  const inputRef = useRef([]);
  const sessionObj = {
    session_id: null,
    session_name: null,
    session_number: null
  }
  const [sessionData, setSessionData] = useState([]);
  const [sessionDeleteAlert, setSessionDeleteAlert] = useState({ show: false, sessionId: null, index: null });
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (props.sessionData.length) {
      let data = props.sessionData[0];
      setSessionData(data.sessions);
    }
  }, [props.sessionData.length]);

  useDidMountEffect(() => {
    setShowSpinner(false);
  }, [props.session])

  const generateSessionGrid = (event) => {
    if (event.target.value !== null) {
  
      let temp = [...sessionData];
      let count = event.target.value - temp.length;
       console.log(temp.length)
       console.log(count)
      if(count >= 1) {
        for (let i = 0; i < count; i++) {
          let tempObj = { ...sessionObj }
          tempObj.key = i;
          temp.push(tempObj)
        }
      }
      else{
        //console.log("santhu");
        for (let i = 1; i > 0; i--){
          let tempObj = { ...sessionObj }
          tempObj.key = i;
          temp.pop(tempObj)
        }
            
      }

      setSessionData(temp);
    }
  }

  const handleSessionChange = (event, index) => {
    let newSession = [...sessionData];
    newSession[index][event.target.name] = event.target.value;
    newSession[index][inputRef.current[index].name] = inputRef.current[index].value;
    newSession[index]['course_id'] = props.courseId[0].id;
    setSessionData(newSession);
  }

  const submitSession = (e) => {
    setShowSpinner(true);
    let sessionInfo = {
      courseId: props.courseId[0].id,
      data: sessionData
    }

    props.addSession(sessionInfo);
  }

  const deleteSession = (index, id) => {
    setSessionDeleteAlert({ ...sessionDeleteAlert, show: true, sessionId: id, index: index })
  }

  const confirmDeleteSession = () => {
    let temp = [...sessionData];
    temp.splice(sessionDeleteAlert.index, 1);
    setSessionData(temp);
    setSessionDeleteAlert({ ...sessionDeleteAlert, show: false, sessionId: null, index: null })

    if (sessionDeleteAlert.sessionId !== null) {
      let sessionInfo = {
        courseId: props.courseId[0].id,
        sessionId: sessionDeleteAlert.sessionId
      }
      props.deleteSession(sessionInfo)
    }
  }

  const closeSessionDeleteAlert = () => {
    setSessionDeleteAlert({ ...sessionDeleteAlert, show: false, sessionId: null, index: null })
  }

  return (
    <>
      <Modal show={props.showSessionModal} onHide={props.closeSessionModal} backdrop="static" keyboard={false} centered className="tj-modal" size="lg">
        <Modal.Header closeButton className="d-flex">
          <Modal.Title className="flex-grow-1">Add Session</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{height:'350px'}} >
          <div className='m-2'>
            <div className='row'>
              <div className='col-md-3'>
                <label>Number of sessions</label></div>
              <div className='col-md-2'>
                <input type='number' className='form-control form-control-sm' onChange={(e) => generateSessionGrid(e)} defaultValue={ sessionData.length} />
              </div>
            </div>
          </div>
          <div className='m-2'>
            <Table striped bordered hover size="md" responsive style={{height:'271px'}}>
              <thead className='thead-border'>
                <tr>
                  <th className='text-center' style={{ width:'10%'}}>#</th>
                  <th className='text-center'>Session Name</th>
                  {/* <th className='text-center' style={{ width:'10%'}}>Action</th> */}
                </tr>
              </thead>
              <tbody className='tbody-overflow'>
                <tr>
                  <td>
                    <input type='text' className='form-control form-control-sm text-center' placeholder='1' name='session_number'/>
                  </td>
                  <td>
                    <input type='text' className='form-control form-control-sm' placeholder='Session name' name='session_name'/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type='text' className='form-control form-control-sm text-center' placeholder='2' name='session_number'/>
                  </td>
                  <td>
                    <input type='text' className='form-control form-control-sm' placeholder='Session name' name='session_name'/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type='text' className='form-control form-control-sm text-center' placeholder='3' name='session_number'/>
                  </td>
                  <td>
                    <input type='text' className='form-control form-control-sm' placeholder='Session name' name='session_name'/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type='text' className='form-control form-control-sm text-center' placeholder='4' name='session_number'/>
                  </td>
                  <td>
                    <input type='text' className='form-control form-control-sm' placeholder='Session name' name='session_name'/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type='text' className='form-control form-control-sm text-center' placeholder='5' name='session_number'/>
                  </td>
                  <td>
                    <input type='text' className='form-control form-control-sm' placeholder='Session name' name='session_name'/>
                  </td>
                </tr>
                {sessionData.map((item, k) => (
                  <tr>
                    <td>
                      <input type='text' className='form-control form-control-sm text-center' placeholder='Session number' name='session_number' ref={el => inputRef.current[k] = el} defaultValue={k + 6} onChange={(e) => handleSessionChange(e, k)} key={k} />
                    </td>
                    <td>
                      <input type='text' className='form-control form-control-sm' placeholder='Session name' name='session_name' onChange={(e) => handleSessionChange(e, k)} defaultValue={item.session_name} key={k} />
                    </td>
                    {/* <td>
                      <div className=''>
                        <FontAwesomeIcon icon={faTrash} className='batch-delete' role='button' onClick={(e) => deleteSession(k, item.session_id)} />
                      </div>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-outline-primary mx-2 btn-sm" onClick={(e) => submitSession(e)}>{(showSpinner) ? <Spinner /> : 'Submit'}</button>
        </Modal.Footer>
      </Modal>

      {/* delete session alert modal */}
      <Modal show={sessionDeleteAlert.show} onHide={closeSessionDeleteAlert} backdrop="static" keyboard={false} centered className="tj-modal">
        <Modal.Header closeButton className="d-flex">
          <Modal.Title className="flex-grow-1 text-center">Delete confirmation</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className='text-center'>Are you sure want to delete session ?</p>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <button type="button" className="btn btn-success mx-2" onClick={confirmDeleteSession}>Yes</button>
          <button type="button" className="btn btn-outline-danger" onClick={closeSessionDeleteAlert}>No</button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  session: state.training.session
})

export default connect(mapStateToProps, { addSession, deleteSession })(SessionModal);