import RequirementService from '../services/requirement.service';

import {
  SET_TRAINING_POST,
  TRAINING_POST_COUNT,
  UPDATE_TRAINING,
  TRAINING_ERROR,
  TRAINING_SUCCESS,
  INSERT_TRAINING,
  DELETE_TRAINING_POST,
  SET_TRAINING_APPLY,
  TRAINING_APPLY_DETAILS
} from './types';

export const getTrainingPost = (trainingRequestData, setIsLoading, isFromFilter) => async(dispatch) => {

  try {
    let trainingData = {
      isCreation: isFromFilter,
    };

    let trainingPostCount = 0;

    const resultData = await RequirementService.getTrainingPosts(trainingRequestData);
    
    if(resultData?.posts && !resultData.posts.errCode) {
      setIsLoading(false)
      trainingData.trainingPost = resultData.posts.collection;
      trainingPostCount = resultData.posts.post_count;
    } else{
      dispatch(setTrainingError('Please Try Again!'));
    }

    dispatch({
        type: SET_TRAINING_POST,
        payload: trainingData,
    });

    dispatch({
        type: TRAINING_POST_COUNT,
        payload: trainingPostCount,
    });

  } catch(err) {
    dispatch(setTrainingError('Please Try Again!'));
  }

}

export const trainingPostData = (formData, resetForm, setSubmitting, closeModal) => async(dispatch) => {

  let notifyMsg = 'Posted';
  try {
      const  resultData = await RequirementService.postTrainingData(formData);
      if (resultData.errCode === 0) {
          closeModal();
          resetForm();
          if(formData.requirements_training_id) {
            notifyMsg = 'Updated';
            dispatch(updateTrainingPost(resultData.data));
          } else {
            dispatch(insertTrainingPost(resultData.data))
          } 
          dispatch(setTrainingSuccess(notifyMsg));
      } else {
          setSubmitting(false);
          dispatch(setTrainingError('Please Try Again!'));
      }
  } catch(err) {
      setSubmitting(false);
      dispatch(setTrainingError('Please Try Again!'));
  }
}

export const updateTrainingPost = (updatedData) => {
  return {
      type: UPDATE_TRAINING,
      payload: updatedData,
  };
}

export const insertTrainingPost = (updatedData) => {
  return {
      type: INSERT_TRAINING,
      payload: updatedData,
  };
}

export const deleteTrainingPost = (deletePostData, setShowDeleteLoader, closeConfirmationModal) =>  async(dispatch) => {
  try {
    const resultData = await RequirementService.postTrainingDelete(deletePostData.requirements_training_id);
    
    if (resultData.errCode === 0) {
      setShowDeleteLoader(false);
      closeConfirmationModal();
      dispatch(setTrainingSuccess('Deleted'));
      dispatch({
          type: DELETE_TRAINING_POST,
          payload: deletePostData,
      })
    }
  } catch (err) {
      setShowDeleteLoader(false);
      dispatch(setTrainingError('Please Try Again!'));
  }
}

export const applyTraining = (trainingPost, resetForm, setSubmitting, closeModal) => async(dispatch) => {
  try {
    const resultData = await RequirementService.postTrainingApply(trainingPost);
    if (resultData.errCode === 0) {
      closeModal()
      resetForm()
      dispatch(updateTrainingPost({
        requirements_training_id: trainingPost.requirements_training_apply_requirements_id,
        isApplied: 1,
      }));
      dispatch(setTrainingSuccess('Applied !'));
    } else {
      dispatch(setTrainingError('Please Try Again!'));
      setSubmitting(false)
    }

  } catch (err) {
    dispatch(setTrainingError('Please Try Again!'));
  }
}

export const showTrainingApplyDetails = (trainingID) => async(dispatch) => {
  try {
    const resultData = await RequirementService.trainingApplicationDetails(trainingID);

    if(resultData.errCode === 0) {
      let data = {
        isModalOpen: true,
        appliedDetailsData: resultData.data
      }
      dispatch(setTrainingApplyDetails(data));
    }

  } catch (err) {
    dispatch(setTrainingError('Please Try Again!'));
  }

}

export const setTrainingApplyData = (trainingApplyData) =>  async(dispatch) => {
  dispatch({
    type: SET_TRAINING_APPLY,
    payload: trainingApplyData,
  })
}

export const setTrainingApplyDetails = (data) => {
  return {
    type: TRAINING_APPLY_DETAILS,
    payload: data,
  };
}

export const setTrainingSuccess = (messages= "") => {
  return {
      type: TRAINING_SUCCESS,
      payload: messages,
  };
}

export const setTrainingError = (messages = "") => {
  return {
      type: TRAINING_ERROR,
      payload: messages,
  };
}