import { useState, useEffect } from "react"

import { connect } from "react-redux"
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

import FeedTabContent from "./FeedTabContent"
import FeedLayout from "../layout/FeedLayout";
import PopupLayout from "../layout/PopupLayout";
import commonService from '../../services/common.service'
import { getUser } from "../../utils/UserDetails";
import { getRequiredFeedData, setBroadcastingData } from "../../actions/common"

const Dashboard = (props) => {

  let FeedDetails = [
    {
      label: "Qboard",
      isActive: 1,
      isRemoved: 0
    },
    {
      label: "Requirements",
      isActive: 0,
      isRemoved: 0
    }
  ]

  const currentUser = getUser();
  const [activeParentTab, setActiveParentTab] = useState(null);
  const [feedTab, setFeedTab] = useState(null);
  const [isPopupWindow, setIsPopupWindow] = useState(null);

  useEffect(() => {
    let windowEcho = null;
    props.getRequiredFeedData();
    setIsPopupWindow(false)
   
    //set default active window when popup window
    if(window.opener && window.name === 'tjunctionPopup') {
      let activeUrl = props.location.pathname.split("/").pop();
      activeUrl = activeUrl.charAt(0).toUpperCase() + activeUrl.slice(1)
      let setActiveTab = FeedDetails.filter(data => (data.label === activeUrl)).shift();
      setActiveTab.isActive = 1;
      FeedDetails = [setActiveTab];
      setIsPopupWindow(true)
    }
    setFeedTab(FeedDetails);
    getActiveTab(FeedDetails);

   
    if(currentUser) {
      windowEcho = new Echo({
        broadcaster: 'pusher',
        key: 'anyKey',
        wsHost: process.env.REACT_APP_BROADCASTURL,
        wsPort: 6001,
        wssPort: 6001,
        forceTLS: false,
        disableStats: true,
        authEndpoint: process.env.REACT_APP_BACKEND_BASEURL + '/broadcasting/auth',
        auth: {
          headers: {
            Authorization: "Bearer " + currentUser.api_token,
            Accept: "application/json"
          }
        },
      });
    }
    props.setBroadcastingData(windowEcho)
    
  },[]);


  const toogleTabs = (tabs, isRemove = 0) => {
    let tabContent = [...feedTab];
    let setActiveTab = {};

    if(tabs.isActive && isRemove) {
      setActiveTab = tabContent.filter(data => (data.label !== tabs.label && !data.isRemoved)).shift();
      setActiveTab.isActive = 1;
      tabContent = setTabData(tabContent, setActiveTab);
    }

    if(!isRemove) {
      tabContent = removeActive(tabContent)
    }

    tabs.isRemoved = isRemove;
    tabs.isActive = (isRemove) ? 0 : 1;
    tabContent = setTabData(tabContent, tabs);
    getActiveTab(tabContent)
    setFeedTab(tabContent)
  }

  const setTabData = (tabContent, tabs) => {
    tabContent.map(data => data.label === tabs.label ? 
      { ...data, ...tabs} : data 
    )
    
    return tabContent;
  }

  const setActiveTabs = (tabs, e) => {
    if (e && (e.target == e.currentTarget)) {
      let tabContent = [...feedTab];
      tabContent = removeActive(tabContent)
      tabs.isActive = 1;
      tabContent = setTabData(tabContent, tabs)
      getActiveTab(tabContent)
    }
  }

  const getActiveTab = (tabContent) => {
     tabContent.find((data) => {
       if(data.isActive){
        setActiveParentTab(data.label)
       }
     });
  }

  const removeActive = (tabContent) => {
    tabContent.map((data) => {
      data.isActive = 0;
      return data;
    })
    return tabContent;
  }

  const openNewWindow = (link) => {
    let w1 = commonService.newMeetingWindow();
    let redirectLink = link.toLowerCase();
    w1.location.href = redirectLink;
  }

  const getReomvedItem = () => {
   return feedTab.filter(data => (data.isRemoved)).length
  }

  const getData = () => {
    return (
      <>
        <div className="col features-tab primary-bg-color">
          <ul className="list-inline list-style-type py-2 features-tab-color">

            {/* Active Tabs show the top of the tab */}
            {feedTab?.length && feedTab.map((tabs, tabkey) => {
              return (
                  <>
                    {(!parseInt(tabs.isRemoved)) ?
                      <li key={tabkey} className={(tabs.label === activeParentTab) ? `${props.activeColor}-font active` : null } onDoubleClick={() => openNewWindow(tabs.label) } onClick={(e)=> setActiveTabs(tabs, e)}>{tabs.label}
                        { ((getReomvedItem() === 0) && !isPopupWindow) && <img onClick={(e)=> toogleTabs(tabs, 1)} src={process.env.PUBLIC_URL + '/images/menu-icons/circle-minus.svg'} className="tab-circle-minus" /> }
                      </li>
                      : null
                      }
                  </>
                )
             })}
             
          </ul>
        </div>

       
        <div className="post-details-area">
          <div className={`title-label` +  (isPopupWindow ? " d-none" : "")} style={{left : "17px"}}>

            {/* Remove Tabs show in the left side of tab */}
            {feedTab?.length && feedTab.map((tabs, tabkey) => {
              return (
                <>
                  {parseInt(tabs.isRemoved) ?
                    <span key={tabkey} onClick={() => toogleTabs(tabs)}>{tabs.label}</span>
                  : null
                  }
                </>
              )
            })}

          </div>
          <FeedTabContent 
            activeParentTab = {activeParentTab}
          />
        </div>
      </>
    ) 
  }

  return (
    <>
      {(isPopupWindow) ? 
        <PopupLayout>
        { getData() }
        </PopupLayout>
        : 
        <FeedLayout>
        { getData() }
       </FeedLayout>
  
      }
    </>
  )
}

const mapStateToProps = state => ({
  activeColor: state.activetheme.activeColor,
  feedSidebar: state.feedSidebar
});


export default connect(mapStateToProps, {getRequiredFeedData, setBroadcastingData})(Dashboard);