import {useEffect, useRef, useState} from 'react'

import {  Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import FormLoader from '../../FormLoader';
import { setCustomUserLinkModal, updateCustomLink } from '../../../actions/customUserLink';

const CustomUserLinkModal = (props) => {

  const formikRef = useRef();
  let formValues = {
    user_link_id: "",
    user_link_name: "",
    user_link: ""
  }

  if(props.customUserLinkModal.customUserLinkObj?.user_link_id) {
    formValues = {...formValues, ...props.customUserLinkModal.customUserLinkObj};
  }

  const validationSchema = Yup.object().shape({
    user_link_name: Yup.string()
    .required("Enter Link"),
    user_link: Yup.string()
    .required("Enter Link Name"),
  });

  useEffect(() => {
    if(props.customUserLinkModal.isModalOpen)
     formikRef.current?.resetForm()
  }, [props.customUserLinkModal.isModalOpen])

  const closeModal = () => {
    let modalData = {...props.customUserLinkModal}
    props.setCustomUserLinkModal({...modalData, isModalOpen:false, customUserLinkObj:{}})
  }

  return (
    <>
      <Formik
         innerRef={formikRef}
         enableReinitialize={true}
         initialValues={formValues}
         validationSchema={validationSchema}
         validateOnChange={false}
         onSubmit={(formData, {resetForm, setSubmitting}) => {
           props.updateCustomLink(formData, resetForm, setSubmitting, closeModal)
         }}
        >
          { formProps => {
              const { values, isSubmitting,  errors, touched } = formProps;
               
              return (
                <Form id="customuserlinkmodal">
                  <Modal show={props.customUserLinkModal?.isModalOpen && props.customUserLinkModal.isModalOpen} className={`tj-modal ` + (props.darkTheme ? 'is-dark-theme' : '')}  onHide={closeModal} backdrop="static" keyboard={false} centered >
                    <Modal.Header closeButton className="d-flex">
                      <Modal.Title className="flex-grow-1">{ values.user_link_id  ? 'Update Link' : 'Add Link'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="form-group mb-3">
                        <Field type="text" name="user_link_name" className="form-control form-control-sm" placeholder="Insert URL name" />
                        <span className="error-message">{ (errors.user_link_name && touched.user_link_name) ? errors.user_link_name : null }</span> 
                      </div>
                      <div className="form-group mb-3">
                        <Field type="text" name="user_link" className="form-control form-control-sm" placeholder="Insert URL" />
                        <span className="error-message">{ (errors.user_link && touched.user_link) ? errors.user_link : null }</span> 
                      </div>
                     
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex  m-2 pull-right">
                          <button type="submit"  form="customuserlinkmodal" className={`btn  btn-block mx-2 ` + (props.activeColor)} disabled={isSubmitting} >	{isSubmitting ? (
                             <FormLoader />
                            ) : (
                              <span>{values.user_link_id ? 'Update' : 'Add'}</span>
                            )}
                            </button>
                            <button type="button" className="btn btn-outline-danger btn-block" onClick={closeModal}>Close</button>
                        </div>
                    </Modal.Footer>
                  </Modal>
                </Form>
               )
              }}
        </Formik>     
    </>
  );
};

const mapStateToProps = state => ({
	activeColor: state.activetheme.activeColor,
  customUserLinkModal:  state.customUserLink.customUserLinkModal
});

export default connect(mapStateToProps, {setCustomUserLinkModal, updateCustomLink})(CustomUserLinkModal);
