import TrainingTable from './leftmenu/TrainingTable';
import Layout from '../layout/Layout';

const Training = () => {
  return (
    <Layout>
      <TrainingTable />
    </Layout>
  )
}
export default Training;