import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const VerticalMenus = (props) => {
  return (
    <>
      <div className='row'>
        <div className='col-sm-1 refresh-column'>
          <FontAwesomeIcon icon={faSyncAlt} style={{ marginLeft: "-8px" }} className="text-muted" role="button" onClick={() => props.setShowRefreshAlertModal({ ...props.showRefreshAlertModal, showAlert: true, source: props.verticalMenu })} />
        </div>
      </div>
      <div className='row'>
        <div className={`col-sm-1 vertical-text-column ` + ((props.verticalMenu === 'default') ? props.activeColor : '')} style={{height:"85px"}}>
          <label className="verticaltext" onClick={() => props.addGroup('default')} role="button">My Choice</label>
        </div>
      </div>
      <div className='row'>
        <div className={`col-sm-1 vertical-text-column ` + ((props.verticalMenu === 'plans') ? props.activeColor : '')} style={{height:"100px"}}>
          <label className="verticaltext" onClick={() => props.addGroup('plans')} role="button"> Plan Trainings </label>
        </div>
      </div>

      <div className='row'>
        <div className={`col-sm-1 vertical-text-column ` + ((props.verticalMenu === 'batch') ? props.activeColor : '')} style={{height:"85px"}}>
          <label className="verticaltext" onClick={() => props.addGroup('batch')} role="button"> Batches </label>
        </div>
      </div>

      <div className='row'>
        <div className={`col-sm-1 vertical-text-column ` + ((props.verticalMenu === 'associates') ? props.activeColor : '')} style={{height:"84px"}}>
          <label className="verticaltext" onClick={() => props.addGroup('associates')} role="button"> Associates </label>
        </div>
      </div>
      <div className='row'>
        <div className={`col-sm-1 vertical-text-column ` + ((props.verticalMenu === 'finance') ? props.activeColor : '')} style={{height:"84px"}}>
          <label className="verticaltext" onClick={() => props.addGroup('finance')} role="button"> Finance </label>
        </div>
      </div>
      <div className='row'>
        <div className={`col-sm-1 vertical-text-column ` + ((props.verticalMenu === 'feedback') ? props.activeColor : '')} style={{height:"84px"}}>
          <label className='verticaltext' onClick={() => props.addGroup('feedback')} role="button"> Feedback </label>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
  darkTheme: state.activetheme.darkTheme,
});

export default connect(mapStateToProps)(VerticalMenus);