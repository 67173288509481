import React from 'react';
import {
  BrowserRouter,
  Switch
} from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Profile from '../components/profile/Profile';
import AuthForm from '../components/Auth/AuthForm';
import Training from '../components/training/Training';
import Dashboard from '../components/dashboard/Dashboard';
import ProfileRender from '../components/profile/ProfileRender';
import Preview from '../components/profile/preview/Preview';
import EmailVerification from '../components/EmailVerification';
import PrimaryProfile from '../components/profile/PrimaryProfile';

const AppRouter = () => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute path="/profile" component={Profile} exact={true} />
      <PrivateRoute path="/training" component={Training} />
      <PrivateRoute path="/contact/admin" component={Training} exact={true} />
      <PrivateRoute path="/preview/:id" component={Preview} exact={true} />
      <PrivateRoute path="/primary-profile-view" component={PrimaryProfile} exact />

      <PublicRoute path={["/login", "/register", "/"]} component={AuthForm} exact />
      <PublicRoute path={["/qboard", "/requirements", "/home"]} component={Dashboard} exact />
      <PublicRoute path="/verify-user/:token/:email" component={EmailVerification} />
      <PublicRoute path="/profile-view/:id" component={ProfileRender} exact={true} />
    </Switch>
  </BrowserRouter>
);

export default AppRouter;