import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';

import Loader from 'react-loader-spinner';

import { getTrainingCategory } from '../../../actions/training';

const Category = forwardRef((props, ref) => {
  const [categories, setCategories] = useState([]);
  const refInput = useRef(null);
  const [value, setValue] = useState(null);

  useEffect(() => {
    props.getTrainingCategory();
  }, [])

  useEffect(() => {
    if (props.allCategories && props.allCategories.length) {
      setCategories(props.allCategories);
    }
  }, [props.allCategories])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },

      myCustomFunction() {
        return {
          rowIndex: props.rowIndex,
          colId: props.column.getId()
        };
      }
    };
  });

  return (
    <>
      {(categories.length) ?
        <select className="form-control" ref={refInput} value={value} onChange={event => setValue(event.target.value)}>
          {categories.map((category) => (
            <option value={category.categories_name}>{category.categories_name}</option>
          ))}
        </select> : <Loader type="TailSpin" color="#00BFFF" height={25} width={25} className="loader" />
      }
    </>
  )
})

const mapStateToProps = (state) => ({
  allCategories: state.training.trainingCategory
});

export default connect(mapStateToProps, { getTrainingCategory }, null, { forwardRef: true })(Category);