import CommanSpinner from "../../spinner/CommanSpinner";
import Trash from '../trash/Trash'

const Skillendorsement = (props) => {
  let skillNameIsVisible = 0;

  if (props.skillData.userskill_name) {
    skillNameIsVisible = 1;
  }

  if ((props.editableStatus.status)) {
    skillNameIsVisible = 1;
  }

  return (
    <>
      {(skillNameIsVisible) ?
        <div className={`col-md-6 row mb-2 ` + ((props.editableStatus.status) ? 'modified-col-width-edit' : 'modified-col-width')}>
          <div className="col-4">
            {(skillNameIsVisible) ?
              <div>
                {(props.editableStatus.status) ?
                  <input type="text" key={`skillname${props.index}`}
                    className={
                      `form-control py-1 m-2 text-center ` + ((props.editableStatus.status && !props.skillData.userskill_show) ? 'hide-opacity' : '')
                    }
                    name="userskill_name"
                    value={
                      (props.skillData.userskill_name) ? props.skillData.userskill_name : ""
                    }
                    onBlur={
                      (e) => props.editSkill(props.index, props.skillData)
                    }
                    onChange={
                      (e) => props.skillChangeHandler(e, props.index)
                    }
                  /> : <p className="py-1 m-2">{(props.skillData.userskill_name) ? props.skillData.userskill_name : ''}</p>}
              </div> : <div className=""></div>
            }
          </div>
          <div className="col-1">
            {(!props.editableStatus.status) ?
              <p className="py-1 m-2 endorse-count" onClick={() => props.showEndorseModal(props.index, props.skillData.userskill_id)}>{(props.showSpinner.status && props.showSpinner.id === props.index) ? <CommanSpinner /> : props.skillData.count}</p> : <div style={{ marginLeft: "-15px" }}></div>}
          </div>
          {(props.editableStatus.status) ?
            <div className="col-1 small-toggle mx-5">
              <div className={((props.skillData.userskill_show === 1) ? 'checkbox-container-off' : 'checkbox-container')}>
                <input type="checkbox" id={"checkbox-skille-" + props.index} onChange={(e) => props.showAndHideOption(e.currentTarget.checked, props.skillData.userskill_id, 'skills', props.index)} name="skillShowHide" defaultChecked={(props.skillData.userskill_show === 1) ? false : true} />
                <label htmlFor={"checkbox-skille-" + props.index} className="end-label"><span className={((props.skillData.userskill_show === 1) ? 'skill-endorse-hide' : 'skill-endorse-unhide')}>Hide</span></label>
              </div>
            </div> : <div></div>}
          <div className="col-1">
            {(props.activeSkill.length > 1 && props.editableStatus.status) ?
              <div className="skill-trash trash">
                <Trash
                  id={props.skillData.userskill_id}
                  index={props.index}
                  source='skills'
                  active={props.activeSkill} />
              </div> : <div className="" style={{ marginLeft: "-20px" }}></div>
            }
          </div>
        </div>
        : 'No data found'}
    </>
  )
}

export default Skillendorsement;
