import CommonService from '../services/common.service';
import { SET_CATEGORIES, SET_CURRENCIES, SET_TIME_PERIODS, SET_INDUSTRY, BROADCASTING_DATA, FEED_LEFT_MENU_COLLAPSED } from './types';

export const getCategories = () => async(dispatch) => {
   
    try {
       const categoriesdata = await CommonService.getCategories();
        dispatch({
            type: SET_CATEGORIES,
            payload: categoriesdata,
          });
      } catch (err) {
        console.log(err);
      }

}

export const getRequiredFeedData= () => async(dispatch) => {
  try {
    const resultData = await CommonService.getRequiredFeedData();

    if(resultData?.status && resultData.status === true) {
     
      dispatch({
        type: SET_CATEGORIES,
        payload: resultData?.categories ? CommonService.getSelectCategoriesValues(resultData.categories) : [],
      });
      
      dispatch({
        type: SET_CURRENCIES,
        payload: resultData?.currencies ? CommonService.convercurrencyhtmltoSymbol(resultData.currencies) : [],
      });
      
      dispatch({
        type: SET_TIME_PERIODS,
        payload: resultData?.time_periods ? resultData.time_periods : [],
      });

      dispatch({
        type: SET_INDUSTRY,
        payload: resultData?.industry ? resultData.industry : [],
      });
    }
   
   } catch (err) {
     console.log(err);
   }
}

export const feedSidebarIsCollapsed = (data) => async(dispatch) => {
  dispatch({
    type: FEED_LEFT_MENU_COLLAPSED,
    payload: data
  })
}

export const setBroadcastingData = (echoData) => async(dispatch) => {
  dispatch({
    type: BROADCASTING_DATA,
    payload: echoData
  })
}