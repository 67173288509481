import React from 'react';

import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { setJobApplyDetails, jobApplyresumeDownload } from '../../../../actions/job';

const JobApplyDetailsModal = (props) => {

  const closeModal = () => {
    let jobApplyData = {...props.jobApplyDetails}
    props.setJobApplyDetails({...jobApplyData, isModalOpen: false})
  }
  const downloadResume = (jobApplyID) => {
    props.jobApplyresumeDownload(jobApplyID)
  }

  return (
    <Modal show={props.jobApplyDetails.isModalOpen} size={"lg"} className={`tj-modal ` + (props.darkTheme ? 'is-dark-theme' : '')}  onHide={closeModal} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton className="d-flex">
        <Modal.Title className="flex-grow-1">Applied Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
            <div className="job-application-view">
							<div className="row">
								<div className="col-md-1 text-center font-weight-bold">
									S.no
								</div>
								<div className="col-md-3 text-center font-weight-bold">
									Name
								</div>
								<div className="col-md-4 font-weight-bold text-center">
									Email
								</div>
								<div className="col-md-2 text-center font-weight-bold">
									Applied Date
								</div>
								<div className="col-md-2 text-center font-weight-bold">
									Download
								</div>
							</div>

              { (props.jobApplyDetails.appliedDetailsData.length) ? props.jobApplyDetails.appliedDetailsData.map((details, index) => {
                    return (
                      	<div className="row">
                          <div className="col-md-1 text-center">
                            {index+1}
                          </div>
                          <div className="col-md-3 text-center">
                            {details.name}
                          </div>
                          <div className="col-md-4 text-center">
                            {details.email}
                          </div>
                          <div className="col-md-2 text-center">
                            {details.created_at}
                          </div>
                          <div className="col-md-2 text-center">
                            <i class="fa fa-download" aria-hidden="true" onClick={()=> downloadResume(details.requirements_job_apply_id) }></i>
                          </div>
                        </div>
                    )
                }) 
                : 
                <p className="text-center">No Data Found</p>
              }
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = state => ({
  activeColor: state.activetheme.activeColor,
  darkTheme: state.activetheme.darkTheme,
  jobApplyDetails :state.job.jobApplyDetails,
});

export default connect(mapStateToProps, {setJobApplyDetails, jobApplyresumeDownload})(JobApplyDetailsModal);
