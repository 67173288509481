import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { Modal, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import useDidMountEffect from '../../DidMount';
import Spinner from '../../spinner/CommanSpinner';
import { addAssociate, deleteAssociate } from '../../../actions/training';

const AssociateModal = (props) => {
  const inputRef = useRef([]);
  const associateObj = {
    course_associates_id: null,
    organization: null,
    associates_vendor: null,
    planned_students: null
  }
  const [associateData, setAssociateData] = useState([]);
  const [associateDeleteAlert, setAssociateDeleteAlert] = useState({ show: false, associateId: null, index: null });
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    setShowSpinner(false);
    if (props.associateData.length) {
      let data = props.associateData[0];
      setAssociateData(data.associates);
    }
  }, [props.associateData.length]);

  useDidMountEffect(() => {
    setShowSpinner(false);
  }, [props.associates])

  const generateAssociateGrid = (event) => {
    if (event.target.value !== null) {
      let temp = [...associateData];
      let count = event.target.value - temp.length;

      for (let i = 0; i < count; i++) {
        let tempObj = { ...associateObj }
        tempObj.key = i;
        temp.push(tempObj)
      }

      setAssociateData(temp);
    }
  }

  const handleAssociateChange = (event, index) => {
    let newassociate = [...associateData];
    newassociate[index][event.target.name] = event.target.value;
    newassociate[index][inputRef.current[index].name] = inputRef.current[index].value;
    newassociate[index]['course_id'] = props.courseId[0].id;
    setAssociateData(newassociate);
  }

  const submitAssociate = (e) => {
    setShowSpinner(true);
    let associateInfo = {
      courseId: props.courseId[0].id,
      data: associateData
    }

    props.addAssociate(associateInfo);
  }

  const deleteAssociate = (index, id) => {
    setAssociateDeleteAlert({ ...associateDeleteAlert, show: true, associateId: id, index: index })
  }

  const confirmDeleteAssociate = () => {
    let temp = [...associateData];
    temp.splice(associateDeleteAlert.index, 1);
    setAssociateData(temp);
    setAssociateDeleteAlert({ ...associateDeleteAlert, show: false, associateId: null, index: null })

    if (associateDeleteAlert.associateId !== null) {
      let associateInfo = {
        courseId: props.courseId[0].id,
        associateId: associateDeleteAlert.associateId
      }
      props.deleteAssociate(associateInfo)
    }
  }

  const closeAssociateDeleteAlert = () => {
    setAssociateDeleteAlert({ ...associateDeleteAlert, show: false, associateId: null, index: null })
  }

  return (
    <>
      <Modal show={props.showAssociateModal} onHide={props.closeAssociateModal} backdrop="static" keyboard={false} centered className="tj-modal" size="lg">
        <Modal.Header closeButton className="d-flex">
          <Modal.Title className="flex-grow-1">Assign Associates</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='m-2'>
            <Table striped bordered hover size="md" responsive>
              <thead>
                <tr>
                  <th>Organization</th>
                  <th>Vendor</th>
                  <th>Planned students</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {associateData.map((item, k) => (
                  <tr>
                    <td>
                      <input type='text' className='form-control form-control-sm' placeholder='Organization' name='organization' ref={el => inputRef.current[k] = el} defaultValue={item.organization} onChange={(e) => handleAssociateChange(e, k)} key={k} />
                    </td>
                    <td>
                      <input type='text' className='form-control form-control-sm' placeholder='Vendor' name='associates_vendor' onChange={(e) => handleAssociateChange(e, k)} defaultValue={item.associates_vendor} key={k} />
                    </td>
                    <td>
                      <input type='text' className='form-control form-control-sm' placeholder='Planned students' name='planned_students' onChange={(e) => handleAssociateChange(e, k)} defaultValue={item.planned_students} key={k} />
                    </td>
                    <td>
                      <div className=''>
                        <FontAwesomeIcon icon={faTimes} className='batch-delete' role='button' onClick={(e) => deleteAssociate(k, item.associate_id)} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-outline-primary mx-2 btn-sm" onClick={(e) => submitAssociate(e)}>{(showSpinner) ? <Spinner /> : 'Submit'}</button>
        </Modal.Footer>
      </Modal>

      {/* delete associate alert modal */}
      <Modal show={associateDeleteAlert.show} onHide={closeAssociateDeleteAlert} backdrop="static" keyboard={false} centered className="tj-modal">
        <Modal.Header closeButton className="d-flex">
          <Modal.Title className="flex-grow-1 text-center">Delete confirmation</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className='text-center'>Are you sure want to delete associate ?</p>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <button type="button" className="btn btn-success mx-2" onClick={confirmDeleteAssociate}>Yes</button>
          <button type="button" className="btn btn-outline-danger" onClick={closeAssociateDeleteAlert}>No</button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  associates: state.training.associate
})

export default connect(mapStateToProps, { addAssociate, deleteAssociate })(AssociateModal);