import {useState, useEffect} from 'react'

import Emojify from "react-emojione";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Smilies from '../../Smilies';
import { getUser } from '../../../utils/UserDetails';
import { getComments, setCommentData } from '../../../actions/dashboradCommon';
import QboardService from '../../../services/qboard.service';

toast.configure();

const CommentFooter = (props) => {

    const [post, setPost] = useState();
    const currentUser = getUser();
    const [commentsCount, setCommentsCount] = useState(0);
    const [showSmilies, setShowSmilies] = useState(false);

    useEffect(() => {
      setPost(props.post)
    }, [props.post])

    useEffect(() => {
        setCommentsCount(props.post.commentLength)
    },[props.post.commentLength])

    const handleSmiliesMouseHover = (isOpen) => {
        setShowSmilies(isOpen);
      }

      const showComments = async(id) => {
        let show = !props.showcomment;

        if (show) {
          const obj = {
            comments_parent_id: id,
          }
    
        const resultData = await QboardService.getComments(obj);
        
        if (resultData.errCode !== undefined) {
          if (resultData.errCode) {
            toast.error(resultData.message);
            } else {
              props.setComments(resultData.comments)
              props.setShowComment(show)
              setCommentsCount(resultData.comments.length)
            }
          } else {
            toast.error('Error');
          }
       } else {
        props.setShowComment(show)
       }
      
      }

      const addComments = (post) => {
        let commentPostData = {...props.postComment}
        commentPostData.isOpen = true;
        commentPostData.key_table = props.keyTable;
        commentPostData.qboard_id = "";
        commentPostData.qboard_parent_id = "";
        commentPostData.requirements_job_id = "";
        commentPostData.requirements_training_id = "";
        commentPostData.cmt_description = "";
        
        if(post.common_comments_id) {
          commentPostData.comments_parent_id = post.common_comments_id;
        }
         props.setCommentData(commentPostData)
      }

    const setSmilies = async(smiliesName, like = 1) => {
      
      let smiliesData = {
        liketype: (post.user_like && post.user_like.smilies_name === smiliesName) ? 0 : like,
        key_table: props.keyTable,
        smilies_name: smiliesName,
        comment_id: post.common_comments_id
       }
       let resultData = await QboardService.qboardPostLike(smiliesData);
       if (resultData.errCode) {
         toast.error('please try again!')
       } else {
         let updateComment = {...post, ...resultData.updatePost};
          setPost(updateComment)
       }
    }

    return (
        <>
            {(post) ? 
              <div className={`post-box-answer ${props.activeColor}-icon`} onMouseLeave={() => (currentUser) ? handleSmiliesMouseHover(false) : null}>
                <div className="row text-center">
                    <div className="col-4" onMouseEnter={() => (currentUser) ? handleSmiliesMouseHover(true) : null}>
                        {(currentUser && showSmilies) ?
                            <Smilies
                             setSmilies = {setSmilies}
                             />
                            : null
                        }
                        {(post.group_by_like.length) ?
                            post.group_by_like.map((smilies) => (
                                <li className="list-inline-item">
                                    <Emojify>
                                        <span className="smilies-comment"> {smilies.split("-").shift()} <span className="smilie-like-count">{smilies.split("-").pop()}</span></span>
                                    </Emojify>
                                </li>
                            ))
                            : <i className="fas fa fa-thumbs-o-up"></i>
                        }
                    </div>
                    <div className="col">
                        <li className="list-inline-item px-3">
                            <img className="comment-img" src={process.env.PUBLIC_URL + '/images/menu-icons/comment.svg'} onClick={()=> addComments(post)}/>
                            <span className="px-1 reply-count" onClick={()=>showComments(post.common_comments_id)}>({commentsCount})</span>
                        </li>

                    </div>
                </div>
            </div>
            :null
            }
        </>
    )
}

const mapStateToProps = state => ({
  darkTheme: state.activetheme.darkTheme,
  activeColor: state.activetheme.activeColor,
  activeProfile: state.profile.activeProfile,
  postComment: state.dashboardCommon.postComment,
  keyTable: state.dashboardCommon.keyTable,
});

export default connect(mapStateToProps, {getComments, setCommentData})(CommentFooter);