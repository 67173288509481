import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as moment from 'moment';
import renderHTML from 'react-render-html';

import Trash from '../trash/Trash';

const JobExperience = (props) => {
	let descriptionIsVisible = 0;
	let designationIsVisible = 0;
	let companyIsVisible = 0;
	let fromIsVisible = 0;
	let toIsVisible = 0;

	if (props.jobData.user_job_experience_description) {
		descriptionIsVisible = 1;
	}

	if (props.jobData.user_job_experience_job_name) {
		designationIsVisible = 1;
	}

	if (props.jobData.user_job_experience_from) {
		fromIsVisible = 1
	}

	if (props.jobData.user_job_experience_to) {
		toIsVisible = 1
	}

	if (props.jobData.user_job_experience_company_name) {
		companyIsVisible = 1
	}

	if (props.editableStatus.status) {
		descriptionIsVisible = 1;
		designationIsVisible = 1;
		fromIsVisible = 1;
		toIsVisible = 1;
		companyIsVisible = 1;
	}

	return (
		<div className={(!props.editableStatus.status) ? "" : "card mb-5"}>
			{(designationIsVisible || fromIsVisible || toIsVisible || companyIsVisible) ?
				<div className={(!props.editableStatus.status) ? "" : "card-header p-1"}>
					<div className="row">
						{(companyIsVisible) ?
							<div className={`col-md-3 ` + ((props.editableStatus.status && !props.jobData.user_job_experience_show) ? 'hide-opacity' : '')}>
								{(props.editableStatus.status) ?
									<label className="profile-form-label justify-content-center d-flex card-labels-size"> Company name </label> : ''}
								{(props.editableStatus.status) ?
									<input type="text" key={`jobcomp${props.index}`}
										className={`form-control py-1 m-2 text-center ` + ((props.editableStatus.status && !props.jobData.user_job_experience_show) ? 'hide-opacity' : '')}
										name="user_job_experience_company_name"
										value={
											(props.jobData.user_job_experience_company_name) ? props.jobData.user_job_experience_company_name : ""
										}
										onBlur={
											(e) => props.editJobExperience(props.index, props.jobData)
										}
										onChange={
											(e) => props.jobChangeHandler(e, 'text', props.index)
										}
									/> : <p className="tab-sub-heading">{(props.jobData.user_job_experience_company_name) ? props.jobData.user_job_experience_company_name : ''}</p>}

							</div> : <div className="col-md-4"></div>
						}
						{(designationIsVisible) ?
							<div className={`col-md-3 ` + ((props.editableStatus.status && !props.jobData.user_job_experience_show) ? 'hide-opacity' : '')}>
								{(props.editableStatus.status) ?
									<label className="profile-form-label justify-content-center d-flex card-labels-size"> Designation </label> : ''}
								{(props.editableStatus.status) ?
									<input type="text" key={`jobdesig${props.index}`}
										className={`form-control py-1 m-2 text-center ` + ((props.editableStatus.status && !props.jobData.user_job_experience_show) ? 'hide-opacity' : '')}
										name="user_job_experience_job_name"
										value={
											(props.jobData.user_job_experience_job_name) ? props.jobData.user_job_experience_job_name : ""
										}
										onBlur={
											(e) => props.editJobExperience(props.index, props.jobData)
										}
										onChange={
											(e) => props.jobChangeHandler(e, 'text', props.index)
										}
									/> : <p className="px-2">{(props.jobData.user_job_experience_job_name) ? props.jobData.user_job_experience_job_name : ''}</p>}

							</div> : <div className="col-md-4 px-4"></div>
						}

						{(fromIsVisible) ?
							<div className={`col-md-2 px-4 sections-date` + ((props.editableStatus.status && !props.jobData.user_job_experience_show) ? 'hide-opacity' : '')}>
								{(props.editableStatus.status) ?
									<label className="profile-form-label justify-content-center d-flex card-labels-size"> From </label> : ''}
								{(props.editableStatus.status) ?
									<input type="date" className={`form-control py-1 m-2 text-center ` + ((props.editableStatus.status && !props.jobData.user_job_experience_show) ? 'hide-opacity' : '')}
										name="user_job_experience_from"
										value={
											(props.jobData.user_job_experience_from) ? props.jobData.user_job_experience_from : ''
										}
										onChange={
											(e) => props.jobChangeHandler(e, 'text', props.index)
										}
										onBlur={(e) => props.editJobExperience(props.index, props.jobData)}
									/> :
									<p className="sub-tab-date"> {(props.jobData.user_job_experience_from !== null) ? moment(props.jobData.user_job_experience_from).format('D-MMM-YYYY') : ''} </p>
								}
							</div> : <div className="col-md-2 px-2"></div>
						}

						{(toIsVisible) ?
							<div className={`col-md-2 px-4 sections-date` + ((props.editableStatus.status && !props.jobData.user_job_experience_show) ? 'hide-opacity' : '')}>
								{(props.editableStatus.status) ?
									<label className="profile-form-label justify-content-center d-flex card-labels-size"> To </label> : ''}
								{(props.editableStatus.status) ?
									<input type="date" className={`form-control py-1 m-2 text-center ` + ((props.editableStatus.status && !props.jobData.user_job_experience_show) ? 'hide-opacity' : '')}
										name="user_job_experience_to"
										value={
											(props.jobData.user_job_experience_to) ? props.jobData.user_job_experience_to : ''
										}
										onChange={
											(e) => props.jobChangeHandler(e, 'text', props.index)
										}
										onBlur={(e) => props.editJobExperience(props.index, props.jobData)}
									/> :
									<p className="sub-tab-date"> {(props.jobData.user_job_experience_to !== null) ? moment(props.jobData.user_job_experience_to).format('D-MMM-YYYY') : ''} </p>
								}
							</div> : <div className="col-md-2 px-2"></div>
						}
						<div className="col-md-1" style={{ marginLeft: "59px" }}>
							{(props.editableStatus.status) ?
								<div className={`hide-resp ` + ((props.jobData.user_job_experience_show === 1) ? 'checkbox-container-off' : 'checkbox-container')}>
									<input type="checkbox" id={"checkbox-job-exp-" + props.index} onChange={(e) => props.showAndHideOption(e.currentTarget.checked, props.jobData.user_job_experience_id, 'job', props.index)} name="jobExperienceShowHide" defaultChecked={(props.jobData.user_job_experience_show === 1) ? false : true} />
									<label htmlFor={"checkbox-job-exp-" + props.index}><span className={((props.jobData.user_job_experience_show === 1) ? 'inner-hide-text-position1' : 'inner-hide-text-position')}>Hide</span></label>
								</div>
								: <div className="checkbox-container"></div>
							}

							<div className="">
								{(props.activeJobExperience.length > 1 && props.editableStatus.status) ?
									<div className="job-trash trash">
										<Trash
											id={props.jobData.user_job_experience_id}
											index={props.index}
											source='job'
											active={props.activeJobExperience} />
									</div> : ''
								}
							</div>
						</div>

					</div>
				</div> : 'No job experience details found'}

			{/* description section */}
			<div>
				{(descriptionIsVisible) ?
					<div className="card-body p-1 desc-resp">
						<div className="row">
							<div className="col-md-12">
								{(props.editableStatus.status) ?
									<ReactQuill
										className={((props.editableStatus.status && !props.jobData.user_job_experience_show) ? 'hide-opacity' : '')}
										key={`jobdesc${props.index}`}
										name="user_job_experience_description"
										modules={props.modules}
										formats={props.formats}
										value={props.jobData.user_job_experience_description}
										onBlur={() => props.editJobExperience(props.index, props.jobData)}
										onChange={(e) => props.jobChangeHandler(e, 'editor', props.index)}
									/> : <p className="">{(props.jobData.user_job_experience_description) ? renderHTML(props.jobData.user_job_experience_description) : ''}</p>}
							</div>
						</div>
					</div> : ''
				}
			</div>
		</div>
	)
}

export default JobExperience;
