import Axios from '../config/axios';
import getSymbolFromCurrency from 'currency-symbol-map';

class CommonService {
	
	getCategories = async () => {
		var data = new FormData();
		let categories = [{
			value: "",
			label: "select",
		}];

		await Axios.post('/get_category', data).then((response) => {
			response.data.forEach((category) => {
				var obj = {};
				obj['value'] = category.categories_id;
				obj['label'] = category.categories_name;
				categories.push(obj);
			})

		}).catch((error) => {
			console.log(error);
		});

		return categories;

	}

	getContacts = async () => {

		let data = new FormData();
		let contactObj = {};
		let contacts = [{
			value: "",
			label: "select",
		}];

		await Axios.post('/get-contact-view', data).then((response) => {
			contactObj = {}
			response.data.data.users.map((contact) => {
				contactObj = {}
				contactObj['value'] = contact.email;
				contactObj['label'] = contact.first_name + " " + contact.last_name;
				contacts.push(contactObj);
			})
		}).catch((error) => {
			console.log(error);
		});

		return contacts;
	}

	formDate = (time) => {
		switch (typeof time) {
			case 'number':
			  break;
			case 'string':
			  time = +new Date(time);
			  break;
			case 'object':
			  if (time.constructor === Date) time = time.getTime();
			  break;
			default:
			  time = +new Date();
		  }
		  var time_formats = [
			[60, 's', 1], // 60
			[120, '1m ago', '1 m from now'], // 60*2
			[3600, 'm', 60], // 60*60, 60
			[7200, '1h ago', '1 h from now'], // 60*60*2
			[86400, 'h', 3600], // 60*60*24, 60*60
			[604800, 'd', 86400], // 60*60*24*7, 60*60*24
			[2419200, 'w', 604800], // 60*60*24*7*4, 60*60*24*7
			[29030400, 'mo', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
			[2903040000, 'y', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
			[58060800000, 'c', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
		  ];
		  var seconds = (+new Date() - time) / 1000,
			token = 'ago',
			list_choice = 1;
		
		  if (seconds == 0) {
			return 'Just now'
		  }
		  if (seconds < 0) {
			seconds = Math.abs(seconds);
			token = 'from now';
			list_choice = 2;
		  }
		  var i = 0,
			format;
		  while (format = time_formats[i++])
		 
			if (seconds < format[0]) {
			  if (typeof format[2] == 'string')
				return format[list_choice];
			  else
				return Math.floor(seconds / format[2])+ format[1] + ' ' + token;
			}
		  return time;

	}

	filterArray = (array, fields, value) => {
		fields = Array.isArray(fields) ? fields : [fields];
		let returnData = array.filter((item) => fields.some((field) => item[field] === value));
		return returnData;
	};


	TimePeriods = async () => {
		let requirementsExpMonth = [{
			value: "",
			label: 'Select'
		}];

		await Axios.get('/get-time-periods', []).then((response) => {
			response.data.forEach((reqCategory) => {
				var obj = {};
				obj['value'] = reqCategory.time_periods_name;
				obj['label'] = reqCategory.time_periods_name;
				requirementsExpMonth.push(obj);
			})
		});

		return requirementsExpMonth;
	}

	getrequirementCurrency = async () => {

		let requirementsExpCurrency = [{
			value: "",
			label: 'Select'
		}];

		Axios.post('/getcurrencies', []).then((response) => {
			response.data.forEach((reqCurrency) => {
				let symbol = (typeof getSymbolFromCurrency(reqCurrency.currency_code) !== 'undefined') ? getSymbolFromCurrency(reqCurrency.currency_code) : reqCurrency.currency_code;
				var obj = {};
				obj['value'] = reqCurrency.currency_master_id;
				obj['label'] = symbol;
				requirementsExpCurrency.push(obj);
			})
		});

		return requirementsExpCurrency;
	}

	getUserUrl = async () => {

		let urlData = [];
		await Axios.get('/get-user-url', []).then((response) => {
			urlData = response.data;

		})
		return urlData;
	}

	saveUserUrl = async (data) => {
		let urlData = [];
		await Axios.post('/save-user-url', data).then((response) => {
			urlData = response.data;
		})
		return urlData;
	}

	deleteUserUrl = async (id) => {
		let urlData = [];
		let data = {
			user_link_id: id,
		}
		await Axios.post('/delete-user-url', data).then((response) => {
			urlData = response.data;
		})
		return urlData;
	}

	newMeetingWindow = (url = '') => {

		let params = 'width=' + window.innerWidth;
		params += ', height=' + window.innerHeight + 10;
		params += ', top=0, left=0'
		params += ', fullscreen=yes';
		var customWindow = window.open('', 'tjunctionPopup', params);
		return customWindow;
	}
	
	getRoles = () => {
		return Axios.get('/get-roles', [])
		.then(function (response) {
			const { data } = response;
			return data;
		}).catch((error) => {
			console.log(error);
		})
	}

	secondsToTime = (secs) => {
		let hours = Math.floor(secs / (60 * 60));
	
		let divisor_for_minutes = secs % (60 * 60);
		let minutes = Math.floor(divisor_for_minutes / 60);
	
		let divisor_for_seconds = divisor_for_minutes % 60;
		let seconds = Math.ceil(divisor_for_seconds);
	
		let obj = {
		  "h": this.zeroPad(hours, 2),
		  "m": this.zeroPad(minutes, 2),
		  "s": this.zeroPad(seconds, 2)
		};
		return obj;
	  }

	  zeroPad = (num, places) => {
		return String(num).padStart(places, '0');
	}

	getCompanies = (userRole) => {
		return Axios.get('/get-client-details',{
			params: {
				user_role: userRole
			},
		})
		.then(function (response) {
			const { data } = response;
			return data;
		}).catch((error) => {
			console.log(error);
		})
	}

	getPagePermissions = (clientUserId) => {
		return Axios.get('/get-page-permission',{
			params: {
				client_user_id: clientUserId
			},
		})
		.then(function (response) {
			const { data } = response;
			return data;
		}).catch((error) => {
			console.log(error);
		})
	}

	savePagePermission = (data) => {
		return Axios.post('/save-page-permission', data)
		.then(function (response) {
			const { data } = response;
			return data;
		}).catch((error) => {
			console.log(error);
		})
	}
	
	checkUniqueDepartment = (data) => {
		
		return Axios.get('/check-unique-department', {
			params: data,
		})
		.then(function (response) {
			const { data } = response;
			return data;
		}).catch((error) => {
			console.log(error);
		})
	}

	getUserTheme = () => {
		return Axios.get('/get-user-theme', []).then(function (response) {
			const { data } = response;
			return data;
		}).catch((error) => {
			console.log(error);
		})
	}

setUserTheme = (data) => {
	return Axios.post('/set-user-theme', data).then(function (response) {
		const { data } = response;
		return data;
	}).catch((error) => {
		console.log(error);
	})
}

getRequiredFeedData = () => {
	return Axios.get('/get-required-feed-data').then(function (response) {
		const { data } = response;
		return data;
	}).catch((error) => {
		console.log(error);
	})
}

getSelectCategoriesValues = (data) => {
	let resultData = [{
		value: "",
		label: "select",
	}];

	data.forEach((category) => {
		var obj = {};
		obj['value'] = category.categories_id;
		obj['label'] = category.categories_name;
		resultData.push(obj);
	})
	return resultData;
}

convercurrencyhtmltoSymbol = (data) => {

	return data.map((value) => {
		value.currency_symbol = getSymbolFromCurrency(value.currency_code)
		return value;
	})
}

scrollToTop = () => {
	window.scrollTo({
		top: 0,
		behavior: "smooth"
	});
};

redirectLink = (redirectLink) => {
	let a = document.createElement('a');
	a.target= '_blank';
	a.href= redirectLink;
	a.click();
}
 popupwindow(title, w, h) {
	var y = window.outerHeight / 2 + window.screenY - ( h / 2)
	var x = window.outerWidth / 2 + window.screenX - ( w / 2)
	var customWindow = window.open('', title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
	return customWindow;
} 
	 
}


export default new CommonService();