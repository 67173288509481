import { Route, Redirect } from 'react-router-dom';
import { getUser } from '../utils/UserDetails';

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route {...rest}
      render={
        props =>
        (
          getUser() ? <Component {...props} /> : <Redirect to={
            {
              pathname: '/'
            }
          }
          />
        )
      }
    />
  )
}

export default PrivateRoute;