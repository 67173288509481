//Profile
export const DESCRIPTION = 'description';
export const TRAINING_EXPERIENCE = 'training_experience';
export const JOB_EXPERIENCE = 'job_experience';
export const QUALIFICATIONS = 'qualifications';
export const CERTIFICATIONS = 'certifications';
export const AWARDS = 'awards';
export const SKILLS = 'skills';
export const INTERESTS = 'interests';
export const RECORD_HIDE = 'record_hide';
export const SECTION_HIDE = 'section_hide';
export const DELETE = 'delete';
export const CREATE_NEW_PROFILE = 'create_new_profile';
export const ENDORSE = 'endorsement';
export const ENDORSE_DETAILS = 'endorse_details';
export const EDIT_PROFILE_MENU = 'edit_profile_menu';
export const SET_PRIMARY_PROFILE = 'set_primary_profile';
export const USER_PROFILES = 'user_profiles';
export const ACTIVE_PROFILE = 'active_profile';
export const CREATE_INTEREST = 'create_interest';
export const GET_INTERESTS = 'get_interests';
export const GET_PROFILE = 'get_profile';
export const UPLOAD_IMAGE = 'upload_image';

//auth
export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_ERROR = 'login_error';
export const REGISTER_SUCCESS = 'register_success';
export const REGISTER_ERROR = 'register_error';

//dashboard common
export const ACTIVE_KEY_TABLE = 'active_key_table';
export const FILTER_DATA = 'filter_data';
export const RESET_FILTER_DATA = 'reset_filter_data';
export const DASH_COMMON_ERROR = 'dash_common_error';
export const MODAL_DATA = 'modal_data';
export const COMMENT_POST_DATA = 'comment_post_data';
export const DELETE_POST_DATA = 'delete_post_data';

//qboard
export const SET_QBOARD_POST = 'set_qboard_post';
export const QBOARD_POST_COUNT = 'qboard_post_count';
export const UPDATE_QBOARD = 'update_qboard';
export const QBOARD_ERROR = 'qboard_error';
export const QBOARD_SUCCESS = 'qboard_success';
export const INSERT_QBOARD = 'insert_qboard';
export const DELETE_QBOARD_POST = 'delete_qboard_post';

//jobs
export const SET_JOB_POST = 'set_job_post';
export const JOB_POST_COUNT = 'job_post_count';
export const UPDATE_JOB = 'update_job';
export const JOB_ERROR = 'job_error';
export const JOB_SUCCESS = 'job_success';
export const INSERT_JOB = 'insert_job';
export const DELETE_JOB_POST = 'delete_job_post';
export const SET_JOB_CATEGORY = 'set_job_category';
export const SET_JOB_SPECIALIZATION = 'set_job_specialization';
export const SET_JOB_APPLY = 'set_job_apply';
export const JOB_APPLY_DETAILS = 'job_apply_details';

//training
export const SET_TRAINING_POST = 'set_training_post';
export const TRAINING_POST_COUNT = 'training_post_count';
export const UPDATE_TRAINING = 'update_training';
export const TRAINING_ERROR = 'training_error';
export const TRAINING_SUCCESS = 'training_success';
export const INSERT_TRAINING = 'insert_training';
export const DELETE_TRAINING_POST = 'delete_training_post';
export const SET_TRAINING_APPLY = 'set_training_apply';
export const TRAINING_APPLY_DETAILS = 'training_apply_details';

//custom user link
export const SET_CUSTOM_USER_LINK = 'set_custom_user_link';
export const CUSTOM_USER_LINK_NOTIFICATION = 'custom_user_link_notification';
export const CUSTOM_USER_LINK_MODAL = 'custom_user_link_modal';
export const INSERT_CUSTOM_USER_LINK = 'insert_custom_user_link';
export const UPDATE_CUSTOM_USER_LINK = 'update_custom_user_link';
export const DELETE_CUSTOM_USER_LINK = 'delete_custom_user_link';

//common
export const SET_CATEGORIES = 'set_categories';
export const SET_CURRENCIES = 'set_currencies';
export const SET_TIME_PERIODS = 'set_time_periods';
export const SET_INDUSTRY = 'set_industry';
export const BROADCASTING_DATA = 'broadcasting_data';
export const FEED_LEFT_MENU_COLLAPSED = 'feed_left_menu_collapsed';

//training details table
export const CREATE_MENUS = 'create_menus';
export const GET_MENUS = 'get_menus';
export const GET_LANGUAGES = 'get_languages';
export const GET_TRAINING_CATEGORY = 'get_training_category';
export const GET_LEVELS = 'get_levels';
export const GET_COURSES = 'get_courses';
export const DELETE_COURSE = 'delete_course';
export const CREATE_COURSE = 'create_course';
export const CHANGE_ORDER = 'change_order';
export const ADD_BATCH = 'add_batch';
export const DELETE_SESSION = 'delete_session';
export const GET_CURRENCIES = 'get_currencies';
export const ADD_SESSION = 'add_session';
export const ADD_ASSOCIATE = 'add_associate';
export const DELETE_ASSOCIATE = 'delete_associate';
export const ADD_FINANCE = 'add_finance';
export const DELETE_FINANCE = 'delete_finance';
export const ADD_FEEDBACK = 'add_feedback';
export const DELETE_FEEDBACK = 'delete_feedback';
export const UPDATE_BATCH = 'update_batch';
export const DELETE_BATCH = 'delete_batch';


//zoom meeting
export const SET_ZOOM_MEETINGS = 'set_zoom_meetings';
export const ZOOM_NOTIFICATION = 'zoom_notification';
export const INSERT_ZOOM_MEETINGS = 'insert_zoom_meetings';
export const UPDATE_ZOOM_MEETINGS = 'update_zoom_meetings';
export const DELETE_ZOOM_MEETINGS = 'delete_zoom_meetings';
export const ZOOM_MEETING_MODAL = 'zoom_meeting_modal';
export const ZOOM_IS_AUTH = 'zoom_is_auth';