import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  REGISTER_SUCCESS,
  REGISTER_ERROR
} from '../actions/types';

const reducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return { ...state, loginSuccess: payload }
    case LOGIN_ERROR:
      return { ...state, loginError: payload }
      case REGISTER_SUCCESS:
        return { ...state, registerSuccess: payload }
      case REGISTER_ERROR:
        return { ...state, registerError: payload }
    default:
      return state;
  }
}

export default reducer;