import React from 'react';

import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const CommonIcons = (props) => {

  let showBgColor = ((props?.collapseHover && props.collapseHover));

  if (props?.backgroundDisable && props.backgroundDisable && showBgColor) {
    showBgColor = (showBgColor) ? 0 : 1;
  }

  return (
    <>
      {(props?.expand && (props.expand === 1)) &&
        <span className={`common-expand-icon ` + (showBgColor ? `${props.activeColor}` : ``)}>
          {(props.from === 'training') ? <FontAwesomeIcon icon={faAngleLeft} /> : <FontAwesomeIcon icon={faAngleRight} />}
        </span>}
      {(props?.collapse && (props.collapse === 1)) &&
        <span className={`common-collapse-icon ` + (showBgColor ? `${props.activeColor}` : ``)}>
          {(props.from === 'training') ? <FontAwesomeIcon icon={faAngleRight} /> : <FontAwesomeIcon icon={faAngleLeft} />}
        </span>
      }
    </>
  );
};

const mapStateToProps = state => ({
  activeColor: state.activetheme.activeColor
});

export default connect(mapStateToProps)(CommonIcons);
