import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import renderHTML from 'react-render-html';

import Trash from '../trash/Trash';

const Awards = (props) => {
  let descriptionIsVisible = 0;
  let awardNameIsVisible = 0;

  if (props.awardData.user_awards_description) {
    descriptionIsVisible = 1;
  }

  if (props.awardData.user_awards_title) {
    awardNameIsVisible = 1;
  }

  if (props.editableStatus.status) {
    descriptionIsVisible = 1;
    awardNameIsVisible = 1;
  }

  return (
    <div className={(!props.editableStatus.status) ? "" : "card mb-4"}>
      {(awardNameIsVisible) ?
        <div className={(!props.editableStatus.status) ? "" : "card-header p-1"}>
          <div className="row">
            {(awardNameIsVisible) ?
              <div className={`col-md-5 ` + ((props.editableStatus.status && !props.awardData.user_awards_show) ? 'hide-opacity' : '')}>
                {(props.editableStatus.status) ?
                  <label className="profile-form-label justify-content-center d-flex card-labels-size"> Award Name </label> : ''}
                {(props.editableStatus.status) ?
                  <input type="text" key={`awardname${props.index}`}
                    className={`form-control py-1 m-2 text-center ` + ((props.editableStatus.status && !props.awardData.user_awards_show) ? 'hide-opacity' : '')}
                    name="user_awards_title"
                    value={
                      (props.awardData.user_awards_title) ? props.awardData.user_awards_title : ""
                    }
                    onBlur={
                      (e) => props.editAward(props.index, props.awardData)
                    }
                    onChange={
                      (e) => props.awardChangeHandler(e, 'text', props.index)
                    }
                  /> : <p className="tab-sub-heading">{(props.awardData.user_awards_title) ? props.awardData.user_awards_title : ''}</p>}

              </div> : <div className="col-md-5"></div>
            }
            <div className="col-md-6"></div>
            <div className="col-md-1" style={{ marginLeft: "-27px" }}>
              {(props.editableStatus.status) ?
                <div className={((props.awardData.user_awards_show === 1) ? 'checkbox-container-off' : 'checkbox-container')}>
                  <input type="checkbox" id={"checkbox-awards-" + props.index} onChange={(e) => props.showAndHideOption(e.currentTarget.checked, props.awardData.user_awards_id, 'award', props.index)} name="awardShowHide" defaultChecked={(props.awardData.user_awards_show === 1) ? false : true} />
                  <label htmlFor={"checkbox-awards-" + props.index}><span className={((props.awardData.user_awards_show === 1) ? 'inner-hide-text-position1' : 'inner-hide-text-position')}>Hide</span></label>
                </div>
                : <div className="checkbox-container"></div>
              }

              <div className="">
                {(props.activeAwards.length > 1 && props.editableStatus.status) ?
                  <div className="award-trash trash">
                    <Trash
                      id={props.awardData.user_awards_id}
                      index={props.index}
                      source='award'
                      active={props.activeAwards} />
                  </div> : ''
                }
              </div>
            </div>
          </div>
        </div> : 'No awards details found'}

      {/* description section */}
      <div>
        {(descriptionIsVisible) ?
          <div className="card-body p-1 desc-resp">
            <div className="row">
              <div className="col-md-12">
                {(props.editableStatus.status) ?
                  <ReactQuill
                    className={(props.editableStatus.status && !props.awardData.user_awards_show) ? 'hide-opacity' : ''}
                    key={`awardsdesc${props.index}`}
                    name="user_awards_description"
                    modules={props.modules}
                    formats={props.formats}
                    value={props.awardData.user_awards_description}
                    onBlur={() => props.editAward(props.index, props.awardData)}
                    onChange={(e) => props.awardChangeHandler(e, 'editor', props.index)}
                  />
                  : <p className="">{(props.awardData.user_awards_description) ? renderHTML(props.awardData.user_awards_description) : ''}</p>}
              </div>
            </div>
          </div> : ''
        }
      </div>
    </div>
  )
}

export default Awards;
