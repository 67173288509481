import { useState } from 'react';
import { connect } from 'react-redux';

import { useDrop } from 'react-dnd';
import update from 'immutability-helper';

import CommonIcons from '../../CommonIcons';
import Card from './Card';
import { ItemTypes } from './ItemTypes';
import useDidMountEffect from '../../DidMount';

const RightMenu = (props) => {
  const shrinkCard = 'shrink-card';
  const showMenuOption = 'show-menu-option';
  const hideMenuOption = 'hide-menu-option';

  const [collapseHover, setCollapseHover] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [oldIndex, setOldIndex] = useState(null);

  useDidMountEffect(() => {
    props.allData.userMenus.sort((a, b) =>
      props.allData.listedMenus.indexOf(a) - props.allData.listedMenus.indexOf(b));
  }, [draggedIndex, oldIndex])
  

  const handleToggler = () => {
    props.allData.setIsExpanded(!props.allData.isExpanded)
    setCollapseHover(false)
    if (isExpanded) {
      setIsExpanded(false)
      return;
    }
    setIsExpanded(true)
  }

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.CARD,
    drop: (item) => changeOrder(item.id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    })
  }));
  
  const changeOrder = (id) => {
    setOldIndex(findCard(id).index);
  }
  

  //  const changeOrder = (id) => {
  //     setOldIndex({
  //       ...findCard(id),index:id
  //     })
  // }

   const moveCard = (id, atIndex) => {
    const { card, index } = findCard(id);

    props.allData.setListedMenus(update(props.allData.listedMenus, {
      $splice: [
        [index, 1],
        [atIndex, 0, card],
      ],
    }));
    const tempDragIndex = findCard(id).index
    setDraggedIndex(tempDragIndex)
  
  }

  // const moveCard = (id, atIndex) => {
  //   const { card, index } = findCard(id);
    

  //   props.allData.setListedMenus(update(props.allData.listedMenus, {
  //     $splice: [
  //       [index, 1],
  //       [atIndex, 0, card],
  //     ],
  //   }));
  //const tempDragIndex = findCard(id).index
  //setDraggedIndex({
  // ...findCard(id),index : tempDragIndex
//})   
  // }


  const findCard = (id) => {
    const card = props.allData.listedMenus.filter((c) => `${c.name}` === id)[0];
    return {
      card,
      index: props.allData.listedMenus.indexOf(card),
    };
  }

  const toggleCard = (menu, index) => {
  //   console.log(menu)
    props.allData.toggleMenus(menu, index)
  }
  // console.log(props.allData.toggleMenus)

  return (
    <div className={`remaining-menu-full ` + ((!props.allData.isExpanded) ? shrinkCard : '')}>
      <div className="row">
        <div className="col-md-1" style={{ position: 'relative' }}>
          <div onClick={handleToggler} onMouseEnter={() => setCollapseHover(true)} onMouseLeave={() => setCollapseHover(false)} className={(props.isExpanded) ? 'arrow-right' : 'expand-arrow-right'}>
            {(isExpanded) ?
              <CommonIcons
                collapse={1}
                collapseHover={collapseHover}
                from={'training'}
              /> :
              <CommonIcons
                expand={1}
                collapseHover={collapseHover}
                from={'training'}
              />
            }
          </div>
        </div>
        <div className='col-sm-9'></div>
      </div>
      <div className={((props.allData.isExpanded) ? showMenuOption : hideMenuOption)}>
        <div className='row' ref={drop}>
          <div className='col-sm-10'>
            {props.allData.listedMenus.map((menu, index) => {
              return <Card key={index + 'k'} id={`${menu.name}`} text={menu.name} moveCard={moveCard} toggleCard={toggleCard} findCard={findCard}  menu={menu} index={index} activeColor={props.activeColor} />
            })}
          </div>
        </div>
      </div>
    </div >
  )
}

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
  darkTheme: state.activetheme.darkTheme,
});

export default connect(mapStateToProps)(RightMenu);