import { connect } from 'react-redux'

import Header from './Header'

const PopupLayout = (props) => {
    
    return (
        <div className={(props.darkTheme) ? 'is-dark-theme' : ''}>
          <div className='layout-header'>
            <Header />  
          </div>
          <div className="container-xxl primary-bg-color popup-layout">
            {props.children}
          </div>
        </div>
    )
}

const mapStateToProps = state => ({
	darkTheme: state.activetheme.darkTheme,
});

export default connect(mapStateToProps)(PopupLayout)