import { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import IntlTelInput from 'react-intl-tel-input';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Icon from 'react-icons-kit';
import { eye } from 'react-icons-kit/feather/eye';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import AppleLoginComponent from './AppleComponent.js';
import GoogleLoginComponent from './GoogleLoginComponent';
import FacebookLoginComponent from './FacebookComponent.js';
import FormLoader from '../FormLoader.js';
import AuthService from '../../services/auth.service';
import CommonService from '../../services/common.service';
import { getUser } from '../../utils/UserDetails'
import { loginUser, setLoginSuccessState } from '../../actions/auth.js';

toast.configure();

const Login = (props) => {

  const history = useHistory();
  const initialValues = {
    email: "",
    phone: "",
    password: "",
    otp: "",
    country_code: "",
    isEmail: true,
    isOtp: false,
    resendSpinner: false,
    isSendOTP: true,
  };

  const signInSchema = Yup.object().shape({
    isEmail: Yup.boolean(),
    isOtp: Yup.boolean(),

    email: Yup.string().when('isEmail', {
      is: true,
      then: Yup.string()
        .required('Enter email or mobile number')
        .matches(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, "Enter valid email"),
      otherwise: Yup.string(),
    }),
    phone: Yup.string().when('isEmail', {
      is: false,
      then: Yup.string()
        .required('Enter Mobile number'),
      otherwise: Yup.string(),
    }),

    password: Yup.string().when('isOtp', {
      is: false,
      then: Yup.string()
        .required("Password is required")
        .min(8, "Password should be 8 chars minimum"),
      otherwise: Yup.string(),
    }),

    otp: Yup.string().when('isOtp', {
      is: true,
      then: Yup.string()
        .required("Otp is required"),
      otherwise: Yup.string(),
    }),

  });

  const [activeRole, setActiveRole] = useState('');
  const [selectRole, setSelectRole] = useState('');
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [counter, setCounter] = useState(0)
  const [timer, setTimer] = useState(0);
  const [isPwdLabel, setPwdLabel] = useState(null);
  const [signInData, setSignInData] = useState({ errMsg: "", isLoading: false });
  const [currentUser, setCurrentUser] = useState(null);
    const [type, setType] = useState('password')
    const [icon, setIcon] = useState(eyeOff)


  useEffect(() => {
    setPwdLabel('');
    setCurrentUser(getUser())
  }, [])

  useEffect(() => {
    if (currentUser) {
      history.push("/");
    }
  }, [currentUser])

  useEffect(() => {
    let showCount;
    let count = counter;
    let seconds = count;
    if (timer) {
      showCount = setInterval(() => {
        seconds -= 1;
        setCounter(seconds)
        if (seconds == 0) {
          setTimer(0)
          clearInterval(showCount);
        }
      }, 1000)
    }

  }, [timer])

  useEffect(() => {

    if (props.loginError) {
      setSignInData({
        errMsg: props.loginError,
      })

      setTimeout(() => {
        setSignInData(prevData => {
          return { ...prevData, errMsg: "" }
        })
      }, 2000);
    }

  }, [props.loginError])

  useEffect(() => {

    if (props.loginSuccess) {
      let resultData = props.loginSuccess;
      let redirectLink = "/home";

      if (props.isFrom) {
        if (resultData.user_role === 'TRAINER') {
          redirectLink = "/admin/trainer"
        } else if (resultData.user_role === 'CLIENT') {
          redirectLink = "/admin/clients"
        } else if (resultData.user_role === 'DEPTADM') {
          redirectLink = "/admin/department"
        } else {
          redirectLink = "/home"
        }
      }
      props.removeLoginSuccess('')
      history.push(redirectLink)
    }

  }, [props.loginSuccess])

  const setRoleData = () => {
    setActiveRole(selectRole);
  }

  const generateOTP = async (values, setFieldValue) => {
    setCounter(120)
    let otpData = {};

    if (values.email) {
      otpData.email = values.email
    }

    if (values.country_code && values.phone) {
      otpData.country_code = values.country_code
      otpData.phone = values.phone
    }

    if (otpData.email || otpData.phone) {
      setFieldValue('resendSpinner', true);
      setFieldValue('isSendOTP', false);

      let resultData = await AuthService.otpGeneration(otpData);
      setFieldValue('resendSpinner', false);

      if (resultData.status === 2000) {
        toast.success('OTP has been send to your ' + (values.email ? 'email' : 'mobile number'));
        setTimer(1);
      } else {
        setSignInData({
          errMsg: resultData.error,
          isLoading: false
        })

        setTimeout(() => {
          setSignInData(prevData => {
            return { ...prevData, errMsg: "" }
          })
        }, 2000);
      }
    }

  }

  const onSignInHandler = (formData, setSubmitting, setFieldValue) => {
    let loginData = {};

    if (formData.email) {
      loginData.email = new Buffer(formData.email).toString('base64');
    }
    if (formData.password) {
      loginData.password = new Buffer(formData.password).toString('base64');
    }

    if (formData.country_code && formData.phone) {
      loginData.country_code = formData.country_code;
      loginData.phone = formData.phone;
    }
    if (formData.otp && formData.otp) {
      loginData.otp = formData.otp;
    }

    props.handleLoginSubmit(loginData, setSubmitting, setFieldValue)

  }

  const formatPhoneNumberOutput = (isValid, newNumber, countryData, fullNumber, isExtension, setFieldValue) => {
    setFieldValue("phone", newNumber);
    setFieldValue("country_code", '+' + countryData.dialCode);
  }

  const formatCountryOutput = (newNumber, countryData, fullNumber, isExtension) => {
    return '+' + countryData.dialCode;
  }

    const handleToggle = () => {
    if(type === 'password'){
      setIcon(eye);
      setType('text');
    }
    else{
      setIcon(eyeOff);
      setType('password');
    }
  }

  return (
    <div className="shadow-container">


      <div className="auth-form login-form">
        {/* <h4>Login</h4> */}
        <h5 className='text-center'>Welcome Back</h5>




        <Formik
          initialValues={initialValues}
          validationSchema={signInSchema}
          validateOnChange={false}
          onSubmit={(formData, { setSubmitting, setFieldValue }) => {
            setSignInData({
              errMsg: "", isLoading: true
            })

            onSignInHandler(formData, setSubmitting, setFieldValue);
          }}
        >
          {formProps => {
            const { values, isSubmitting, setFieldTouched, setFieldValue, errors, touched } = formProps;

            return (
              <Form>
                <div className="form-group my-2">

                  {((!isNaN(values.email) && (values.email.length) > 3) || (values.phone)) ?
                    <IntlTelInput
                      defaultCountry="in"
                      defaultValue={(values.email) ? values.email : ""}
                      fieldId="phone"
                      fieldName="phone"
                      autoFocus={true}
                      separateDialCode={true}
                      inputClassName="form-control form-control-sm"
                      autoPlaceholder={false}
                      placeholder="Mobile number"
                      onPhoneNumberBlur={() => {
                        setFieldTouched("phone", true);
                      }}
                      onPhoneNumberChange={(...args) => {
                        formatPhoneNumberOutput(...args, setFieldValue);
                        setFieldValue("email", (values.phone) ? "" : values.email);
                      }}
                      onSelectFlag={(...args) => {
                        setFieldValue("country_code", formatCountryOutput(...args));
                        setFieldValue("email", (values.phone) ? "" : values.email);
                      }}
                    />
                    :
                    <>
                    <label htmlFor="email">Email</label>
                      <Field type="email" name="email" id="email" className="form-control form-control-lg" placeholder="Email or mobile number" />
                      <span className="error-message mb-0">{(errors.email && touched.email) ? errors.email : null}</span>
                    </>
                  }

                  <Field type="hidden" name="country_code" id="country_code" className="form-control form-control-sm" />
                  <Field type="hidden" name="isEmail" id="isEmail" className="form-control form-control-sm" value={values.isEmail = (isNaN(values.email) || (!values.email && !values.phone)) ? true : false} />
                  <Field type="hidden" name="isOtp" id="isOtp" className="form-control form-control-sm" value={values.isOtp = (isPwdLabel) ? true : false} />
                </div>

                <div className="form-group ">
                  <label htmlFor="password">
                    <span onClick={() => { setPwdLabel(''); setFieldValue('otp', ''); }} className={(isPwdLabel == '') ? 'active' : ''} >Password</span> /
                    <span onClick={() => { setPwdLabel('otp'); setFieldValue('isSendOTP', true); setFieldValue('password', ''); }} className={(isPwdLabel == 'otp') ? 'active' : ''}> Otp</span>
                    
                  </label>
                  
                  {(isPwdLabel) ?
                    <>
                      <div className="input-group input-group-sm">
                        <Field type="text" name="otp" id="otp" className="form-control form-control-lg" placeholder="Click send otp button for otp login" />
                        <span className="input-group-text" id="resend-otp" onClick={() => !(timer) ? generateOTP(values, setFieldValue) : null}>
                          {
                            values.resendSpinner ? (
                              <FormLoader />
                            ) :
                              (timer) ?
                                <>
                                  {(() => {
                                    let timecounter = CommonService.secondsToTime(counter);
                                    return (
                                      <>
                                        {timecounter.m} : {timecounter.s}
                                      </>
                                    )

                                  })()
                                  }
                                </>
                                :
                                <>
                                  {(values.isSendOTP) ? 'Send Otp' : 'Resend Otp'}
                                </>

                          }
                        </span>
                      </div>
                      <span className="error-message">{(errors.otp && touched.otp) ? errors.otp : null}</span>
                    </>
                    :
                    <>
                      <Field type={type} name="password" id="password" className="form-control form-control-lg" placeholder="Password" />
                      <span onClick={handleToggle} className='field-icon'></span>
                      <span className="error-message">{(errors.password && touched.password) ? errors.password : null}</span>                    
                    </>
                  }
                    <div className="forgot-password">
                      <Link to="#" className="mx-1 highlight">Forgot password?</Link>
                    </div>                
  
                </div>

                <span className="error-message mt-2">{signInData.errMsg}</span>

                <button type="submit" className="text-center btn btn-primary btn-block" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <FormLoader />
                  ) : (
                    <span>Login</span>
                  )}
                </button>
              </Form>
            )
          }}
        </Formik>
        <div className="social-btn mb-3">
          {

            (props.masterRoles && props.masterRoles.length) ?
              <>
               <span className="d-block or-text">or</span><br></br>
                <GoogleLoginComponent
                  masterRoles={props.masterRoles}
                  setShowRoleModal={setShowRoleModal}
                  activeRole={activeRole}
                />
               
              </>

              : null
          }

        </div>
          <div className="social-btn mb-3">
              <>             
                <FacebookLoginComponent/>    
              </>
        </div>
        <div className="social-btn mb-3">
              <>             
                <AppleLoginComponent/>    
              </>
        </div>

        {(!props.isFrom) ?
        <div className="d-flex justify-content-center register-content">
          <p>New to tjunction?</p>
          <Link to="#" className="mx-1 highlight" onClick={() => props.setViewLogin(false)}>Sign up</Link>
        </div>
        : null}
      <hr className='mt-0 mb-0'></hr>
<div className='lastcontent'>
   <Link to="#" className="mx-1 highlight" >Log in with your organization</Link>
   <div className='finaltext'>
   <p>Have trouble logging in? <span className='underline'>Learner help Center</span></p>
    <p>This site is protected by reCAPTCHA Enterprise and the Google <span className='underline'>Privacy Policy </span>and <span className='underline'>Terms of Service </span>apply.</p>
   </div>
</div>
     

        <Modal show={showRoleModal} onHide={() => setShowRoleModal(false)} backdrop="static" keyboard={false} className="tj-modal" size="lg">
          <Modal.Header>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              (props.masterRoles && props.masterRoles.length) ?
                <div className="container p-4 text-center">

                  <div className="form-group mb-1">

                    <h6>Kindly yo select your role to continue.</h6>
                    <select name="role_type" onChange={(e) => setSelectRole(e.target.value)}>
                      <option value="">Select role</option>
                      {
                        props.masterRoles.map((roles) => {
                          return (
                            <option value={roles.roles_master_code}>{roles.roles_master_name}</option>
                          )
                        })
                      }
                    </select>

                    <button className="btn btn-primary btn-sm mx-auto mt-4 d-block" onClick={setRoleData}>
                      {activeRole ? (
                        <FormLoader />
                      ) : (
                        <span>Continue</span>
                      )}
                    </button>

                  </div>
                </div>
                : null
            }
          </Modal.Body>
        </Modal>

      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  loginSuccess: state.auth.loginSuccess,
  loginError: state.auth.loginError
})

const mapDispatchToProps = (dispatch) => {
  return {
    handleLoginSubmit: (userInfo, setSubmitting, setFieldValue) => dispatch(loginUser(userInfo, setSubmitting, setFieldValue)),
    removeLoginSuccess: () => dispatch(setLoginSuccessState(''))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
