import Trash from "../trash/Trash";

const MoreSkills = (props) => {
	let skillNameIsVisible = 0;
	let ratingIsVisible = 0;

	if (props.skillData.userskill_name) {
		skillNameIsVisible = 1;
	}

	if (props.skillData.userskill_expertize) {
		ratingIsVisible = 1;
	}

	if (props.editableStatus.status) {
		skillNameIsVisible = 1;
		ratingIsVisible = 1;
	}

	return (
		<>
			{(skillNameIsVisible) ?
				<div className={`col-md-6 row mb-2 ` + ((props.editableStatus.status) ? 'modified-col-width-edit' : 'modified-col-width')}>
					<div className="col-4">
						{(props.editableStatus.status) ?
							<input type="text" key={`mskillname${props.index}`}
								className={
									`form-control py-1 text-center ` + ((props.editableStatus.status && !props.skillData.userskill_show) ? 'hide-opacity' : '')
								}
								name="userskill_name"
								value={
									(props.skillData.userskill_name) ? props.skillData.userskill_name : ""
								}
								onBlur={
									(e) => props.editSkill(props.index, props.skillData)
								}
								onChange={
									(e) => props.skillChangeHandler(e, props.index)
								} /> : <p className="py-1 m-2">{(props.skillData.userskill_name) ? props.skillData.userskill_name : ''}</p >
						}
					</div>
					{(props.editableStatus.status) ?
						<div className="col-sm-1">
							<p className="text-center py-1">{props.skillData.userskill_expertize}</p>
						</div>
						: ''}
					<div className="col-3">
						{(ratingIsVisible) ?
							<div>
								{(props.editableStatus.status) ?
									<input type="range"
										value={(props.skillData.userskill_expertize !== null) ? props.skillData.userskill_expertize : 1}
										min="1"
										max="10"
										step="1"
										onChange={(e) => props.skillChangeHandler(e, props.index)}
										onBlur={() => props.editSkill(props.index, props.skillData)}
										className="slider"
										name="userskill_expertize"
										id={props.index + '-slider-menu'}
									/> : <p className="py-1 m-2">{props.skillData.userskill_expertize}</p>
								}
							</div> : <div className="col-4"></div>
						}
					</div>
					<div className="col-1">
						{(props.activeSkill.length > 1 && props.editableStatus.status) ?
							< div className="more-skill-trash trash">
								<Trash
									id={props.skillData.userskill_id}
									index={props.index}
									source='skills'
									active={props.activeSkill} />
							</div> : ''
						}
					</div>
				</div> : 'No skill found'}
		</>
	)
}

export default MoreSkills;
